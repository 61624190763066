export default {
  title: "קבצים",
  createNew: "הוספה",
  folder: "תיקייה חדשה",
  file: "העלאת קבצים",
  cameraPhoto: "מצלמה",
  rootFolder: "ראשי",
  search: "חיפוש לפי שם...",
  open: "פתיחת קובץ",
  rename: "שינוי שם",
  remove: "מחיקה",
  table: {
    columns: {
      name: "שם",
      dateModified: "תאריך יצירה",
      size: "גודל",
    }
  },
  writeFolderName: "בבקשה לרשום שם תיקיה",
  errorFolderName: "שם תיקיה לא תקים",
  writeNewName: "בבקשה לרשום שם קובץ",
  errorNewName: "שם קובץ לא תקים",
  camera: {
    title: "צילום תמונה",
  }
}
