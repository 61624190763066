import { useServices } from "@/services";
import { useStores } from "@/stores";
import { delay } from "@/utils";
import { ShowPatientType, ShowCalendarsType, ShowType } from "@/models/enums";
import { Roles, type RolesValues } from "@/models/api/identity/Roles";
import { Policies, type PoliciesValues } from "@/models/api/identity/Policies";
import type { UserPermissionsSchema } from '@/models/schemas';

export class IdentityHandler {
  public static async initializeAsync() {
    const services = useServices();
    if (services.apiAuth.isSignedIn) {
      const stores = useStores();
      // load data
      const me = await services.user.getMe();
      const permissions = await services.userPermissions.getMyPermissions();
      const info = await services.databaseInfo.getMyDatabaseInfo();
      const settings = await services.setting.getSettingsAll();
      // set data
      stores.user.setMe(me);
      stores.user.setPermissions(permissions);
      stores.database.setDbName(info?.uniqueName ?? "");
      stores.database.setLicenseName(info?.license ?? "");
      stores.settings.setAll(settings);
      stores.settings.subscribe();
    }
  }

  public static get user() {
    return useStores().user.me;
  }

  public static get roles() {
    return useStores().user.me?.roles ?? [];
  }

  public static get permissions() {
    return useStores().user.permissions;
  }

  public static hasRole(role: RolesValues): boolean {
    return this.roles.includes(role);
  }

  private static checkFunc(roles: RolesValues[], checker: (permissions: UserPermissionsSchema) => boolean) {
    if (roles.find(role => this.hasRole(role))) {
      return true;
    }
    if (this.permissions && checker(this.permissions)) {
      return true;
    }
    return false;
  }

  public static async checkPolicyAsync(policy: PoliciesValues): Promise<boolean> {
    // wait 5 sec
    for (let i = 0; i < 50; i++) {
      if (!useServices().apiAuth.isSignedIn) break;
      if (this.permissions) break;
      await delay(100);
    }
    // check
    return this.checkPolicy(policy);
  }

  public static checkPolicy(policy: PoliciesValues): boolean {
    switch (policy) {
      case Policies.Administration: return this.hasRole(Roles.DoctorAdmin);

      case Policies.Financial: return this.checkFunc([], p => p.financial !== ShowType.None);
      case Policies.FinancialReports: return this.checkFunc([], p => p.financial !== ShowType.None && (p.financial === ShowType.My || p.financialReports));
      case Policies.Incomes: return this.checkFunc([], p => p.financial !== ShowType.None && (p.financial === ShowType.My || p.incomes));
      case Policies.Expenses: return this.checkFunc([], p => p.financial !== ShowType.None && (p.financial === ShowType.My || p.expenses));
      case Policies.Forecast: return this.checkFunc([], p => p.financial !== ShowType.None && (p.financial === ShowType.All && p.forecast));
      case Policies.Deposit: return this.checkFunc([], p => p.financial !== ShowType.None && (p.financial === ShowType.All && p.deposit));
      case Policies.AccountingReport: return this.checkFunc([], p => p.financial !== ShowType.None && (p.financial === ShowType.All && p.accountingReport));

      case Policies.Appointments: return this.checkFunc([], p => p.appointments);
      case Policies.Leads: return this.checkFunc([], p => p.leads);
      case Policies.LaboratoryWorks: return this.checkFunc([], p => p.laboratoryWorks !== ShowType.None);
      case Policies.Tasks: return this.checkFunc([], p => p.tasks !== ShowType.None);
      case Policies.Attendance: return this.checkFunc([], p => p.attendance !== ShowType.None);
      case Policies.Settings: return this.checkFunc([], p => p.settings);

      case Policies.Emails: return this.checkFunc([], p => p.emails);
      case Policies.Recall: return this.checkFunc([], p => p.recall !== ShowType.None);
      case Policies.Chat: return this.checkFunc([], p => p.chat);
      case Policies.Users: return this.checkFunc([], p => p.users);
      case Policies.Patients: return this.checkFunc([], p => p.patients);

      default: return false;
    }
  }
}
