import type { ChannelSchema } from "@/models/schemas/ChannelSchema";
import type { TabulatorParams } from "@/models/interfaces";
import GraphqlService from "../GraphqlService";
import type { SaveChannelInput } from "@/models/api/mutations/ChannelModels";
import type { Result } from "@/models/interfaces/Result";

const fields = `
  id
  createdAt
  updatedAt
  deletedAt

  name
  active
`;

export default class ChannelService {
  async getChannels(params: TabulatorParams) {
    return await GraphqlService.getItems<ChannelSchema>("channels", fields, params);
  }

  async getChannelsAll(fields?: string[]) {
    const { data } = await GraphqlService.queryGql<ChannelSchema[]>({
      method: "channelsAll",
      fields: fields ?? ["id", "name"],
      order: [{ field: "name", value: "ASC" }]
    });
    return data ?? [];
  }

  async getChannel(id: number) {
    return await GraphqlService.getItem<ChannelSchema>("channel", fields, id);
  }

  async saveChannel(input: SaveChannelInput) {
    return await GraphqlService.setItem<ChannelSchema>("saveChannel", "channel", fields, input);
  }

  async sendChannelConnectionManual(id: number, email: string) {
    return await GraphqlService.mutateGql<Result>({
      method: "sendChannelConnectionManual",
      fields: ["success", "message"],
      variables: [
        { field: "id", value: id, valueType: "ID!" },
        { field: "email", value: email, valueType: "String!" },
      ],
    })
  }

  async deleteChannel(id: number) {
    return await GraphqlService.deleteItem("deleteChannel", id);
  }
}
