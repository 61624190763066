export default {
  head: {
    title: "MedForm - Reset Password",
    meta: [
      { name: "description", content: "MedForm reset password" }
    ]
  },
  title: "Reset Password",
  alert: "Enter your Email and instructions will be sent to you!",
  input: {
    email: "Email"
  },
  reset: "Reset",
  userNotFound: "User not found.",
  instructionsSent: "Password reset instructions have been sent to your email!",
  remember: "Remember It?",
  signIn: "Sign In here"
}
