export default {
  head: {
    title: "Channel",
    meta: [{ name: "description", content: "Channel description" }]
  },
  newChannel: "New Channel",
  name: "Name",
  active: "Active",
  connection: {
    api: "API connection",
    zapier: "Zapier connection",
    iframe: "IFrame connection",
    email: "Send connection manual by email",
  },
  email: "Email",
  emailSent: "Email sent to programmer",
  tabs: {
    api: {
      swaggerLink: "Swagger link with instructions",
    },
    zapier: {
      zapLink: "Use this link to create Zap",
    },
    iframe: {
      useLink: "Use this link in iframe on your website",
    }
  }
};
