export default {
  head: {
    title: "העמוד לא מצמא",
    meta: [
      { name: "description", content: "MedForm - העמוד לא מצמא" }
    ]
  },
  sorry: "עמוד לא נמצא",
  back: "חזרה לדשבורד"
}
