import type { DateTimeString } from "@/models/interfaces";
import type { StorageFileType } from "@/models/enums";

export interface StorageFileSchema {
  id: number;
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
  deletedAt?: DateTimeString;

  type: StorageFileType;
  entityId: number;
  patientId: number | null;

  name: string;
  nameOriginal: string | null;
  contentType: string | null;
  size: number;
}

export const storageFileSchemaAllFields = [
  "id",
  "createdAt",
  "updatedAt",
  "deletedAt",
  "type",
  "entityId",
  "patientId",
  "name",
  "nameOriginal",
  "contentType",
  "size"
];