﻿export default {
  head: {
    title: "Laboratory",
    meta: [
      { name: "description", content: "Laboratory" }
    ]
  },

  generalGroup: "General",
  addressGroup: "Address",
  contactsGroup: "Contacts",

  active: "Active",
  title: "Title",
  address: "Address",
  city: "City",
  zip: "Zip",
  country: "Country",
  department: "Industry",
  notes: "Notes",
  contactPerson: "Contact person",
  phone: "Phone",
  fax: "Fax",
  mobile: "Mobile",
  email1: "Email 1",
  email2: "Email 2",
  email3: "Email 3",

  createTitle: "Add a lab",
  updateTitle: "Lab {0}",
}
