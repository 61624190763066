import { defineStore } from 'pinia'

// https://blog.logrocket.com/complex-vue-3-state-management-pinia/
export const useNotificationStore = defineStore({
  id: 'notification',
  state: () => ({
    type: null as string | null,
    message: null as string | null
  }),
  actions: {
    success(message: string) {
      this.type = 'alert-success';
      this.message = message;
    },
    error(message: string) {
      this.type = 'alert-danger';
      this.message = message;
    },
    clear() {
      this.type = null;
      this.message = null;
    }
  }
})
