export default {
  head: {
    title: "Deposit checks",
    meta: [
      { name: "description", content: "Deposit checks description" }
    ]
  },
  example: "example",
  popup: {
    check: "Are you sure you want to mark this deposit as completed?"
  },
  table: {
    columns: {
      chequeDate: "Date",
      client: "Client",
      chequeBankName: "Bank",
      chequeBankBranch: "Branch",
      chequeBankAccount: "Account",
      chequeNumber: "Check number",
      amount: "Amount"
    }
  }
}
