export default {
  head: {
    title: "Advance tax report",
    meta: [
      { name: "description", content: "Advance tax report description" }
    ]
  },
  title: "Advance tax report",
  alertText: "This report allows the calculation of advances to pay during your business cycle, the rate of advances and deductions deducted at source.",
  alertPercentageLink: "To define a percentage of income tax advances",
  alertPaymentsLink: "For advance payments on the Tax Authority's website",
  dateFrom: "From date",
  dateTo: "To date",
  advancePayment: "Advance payment",
  transactions: "Transactions",
  businessTurnover: "Business turnover in NIS",
  advanceTaxPercent: "% income tax advances",
  advanceTaxResult: "Advance according to the % of the business turnover",
  deductions: "Deductions",
  totalWithholdingTax: "Total withholding tax",
  totalAdvancePaid: "Total advances to be paid",
  buttons: {
    report: "Generate report"
  }
}
