<template>
  <div>
    <div class="d-flex justify-content-center align-items-center" :style="`height: ${props.height || 'auto'}`">
      <div class="spinner-border text-success" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  const props = defineProps<{
    height?: string;
  }>();
</script>

<style scoped lang="scss">
  .spinner-border {
    width: 3rem;
    height: 3rem;
  }
</style>
