﻿export default {
  head: {
    title: "Whatsapp settings",
    meta: [
      { name: "description", content: "Whatsapp settings" }
    ]
  },
  sms: {
    head: {
      title: "SMS settings",
      meta: [
        { name: "description", content: "SMS settings" }
      ]
    },
    haveMessages: ["You have {0} SMS messages left in the current package. To expand the package", "click here"],
  },
  cantGetSessionStatus: 'Can not get session status',
  checkingConnectionStatus: "Checking connection status...",
  connected: 'Connected',
  loggingOut: 'Logging out...',
  logout: 'Logout',
  warningLine1: 'The system offers two connection options.',
  warningLine2: 'For demonstration purposes, you can log in by scanning a QR code. The system will then mirror WhatsApp on your phone, allowing you to send similar messages to WhatsApp Web.',
  warningLine3: 'However, note that this option is not completely reliable and may stop working unexpectedly. We cannot guarantee its continuous operation.',
  warningLine4: 'For permanent integration, you will need to purchase a WhatsApp number and use the WhatsApp Business API. If you are interested in this option, please contact our support team to inquire about purchasing the WhatsApp Business API.',
  instructionHeader: 'How to use WhatsApp in software',
  instructionLine1: 'Open WhatsApp on your phone',
  instructionLine2Part1: 'Tap Menu',
  instructionLine2Part2: 'on Android, or Settings',
  instructionLine2Part3: 'on iPhone',
  instructionLine3: 'Tap <strong>Linked devices</strong> and then <strong>Link a device</strong>',
  instructionLine4: 'Point your phone at this screen to capture the QR code',
}
