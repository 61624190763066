import type { UserSchema, UserPermissionsSchema } from '@/models/schemas';
import { defineStore } from 'pinia'

// https://blog.logrocket.com/complex-vue-3-state-management-pinia/
export const useUserStore = defineStore({
  id: 'user',
  state: () => ({
    me: null as UserSchema | null,
    permissions: null as UserPermissionsSchema | null
  }),
  actions: {
    setMe(user: UserSchema | null) {
      this.me = user;
    },
    setPermissions(user: UserPermissionsSchema | null) {
      this.permissions = user;
    },
  }
})
