import { defineStore } from 'pinia'
import { useServices } from '@/services'

// https://blog.logrocket.com/complex-vue-3-state-management-pinia/
export const useDatabaseStore = defineStore({
  id: 'database',
  state: () => ({
    dbName: '',
    licenseName: '',
    password: ''
  }),
  actions: {
    setDbName(name: string) {
      this.dbName = name;
    },
    setLicenseName(name: string) {
      this.licenseName = name;
    },
    setPassword(password: string) {
      this.password = password;
    },
    clearPassword() {
      this.password = '';
    },
  }
})
