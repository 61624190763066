import type { DateTimeString } from "@/models/interfaces";
import type { Gender, FamilyStatus, PatientFamilyType, TreatmentDenture } from "@/models/enums";

export interface PatientSchema {
  id: number;
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
  deletedAt?: DateTimeString;

  active: boolean;
  birthDate?: DateTimeString;
  firstName?: string;
  lastName?: string;
  surName?: string;
  photo?: string;
  gender?: Gender;
  familyStatus?: FamilyStatus;
  workingPlace?: string;
  passportNumber?: string;
  addressStreet?: string;
  addressHouseNumber?: string;
  addressAppartments?: string;
  addressCity?: string;
  addressZip?: string;
  addressCountry?: string;
  phone1?: string;
  phone2?: string;
  phone3?: string;
  fax?: string;
  email1?: string;
  email2?: string;
  doNotSendSms: boolean;
  doNotSendEmails: boolean;
  doNotCall: boolean;
  defaultDoctorId: number;
  pricelistId: number;
  balance: number;
  insuranceCompanyId?: number;
  insurancePolicyNumber?: string;
  insuranceValidationDate?: string;
  insuranceEmployeeNumber?: string;
  insuranceConditions?: string;
  patientType?: PatientFamilyType;
  appointmentNote?: string;
  fileNumber?: string;
  sensitivityToMedications?: string;
  appointmentColor?: string;
  referredBy?: string;
  externalId?: number;
  denture: TreatmentDenture;

  fullName?: string;
  defaultDoctorName?: string;
  defaultDoctorImage?: string;
  lastAppointmentTime?: DateTimeString;
  nextAppointmentTime?: DateTimeString;
  isMainFamilyPatient?: boolean;
}

export const patientSchemaAllFields = `
  id
  createdAt
  updatedAt
  deletedAt

  active
  birthDate
  firstName
  lastName
  surName
  photo
  gender
  familyStatus
  workingPlace
  passportNumber
  addressStreet
  addressHouseNumber
  addressAppartments
  addressCity
  addressZip
  addressCountry
  phone1
  phone2
  phone3
  fax
  email1
  email2
  doNotSendSms
  doNotSendEmails
  doNotCall
  defaultDoctorId
  pricelistId
  balance
  insuranceCompanyId
  insurancePolicyNumber
  insuranceValidationDate
  insuranceEmployeeNumber
  insuranceConditions
  patientType
  appointmentNote
  fileNumber
  sensitivityToMedications
  appointmentColor
  referredBy
  externalId
  denture

  fullName
  defaultDoctorName
  defaultDoctorImage
  lastAppointmentTime
  nextAppointmentTime
  isMainFamilyPatient
`;