export default {
  head: {
    title: "הגדרות עסק",
    meta: [
      { name: "תֵאוּר", content: "הגדרות עסק" }
    ]
  },
  title: "הגדרות עסק",
  actions: {
    submit: "שמירה",
    change: "החלפה"
  },
  validation: {
    imgNotSupported: "קובץ זה אינו נתמך",
    imgAlreadyRemoved: "תמונה לא קיימת",
    pdfAlreadyRemoved: "קובץ לא קיים"
  },
  popups: {
    rmFileQuestion: "האם למחוק?"
  },
  tabs: {
    details: {
      title: "פרטי עסק",
      businessName: "שם העסק",
      businessNumber: "ח.פ. / ת.ז.",
      businessLogo: "לוגו",
      businessSignature: "חתימה וחותמת עבור חתימה אלקטרונית",
      businessType: "סוג עוסק",
      businessTypeOptions: {
        COMPANY: 'חברה בע"מ',
        INDIVIDUAL_BUSINESS: "עוסק מורשה",
        SMALL_BUSINESS: "עוסק פטור"
      }
    },
    address: {
      title: "כתובת",
      address: "כתובת כולל רחוב ומספר",
      city: "עיר",
      zip: "מיקוד",
      mobilePhone: "טלפון נייד",
      landPhone: "טלפון נייח",
      fax: "פקס",
      email: 'דוא"ל',
      site: "אתר"
    },
    bank: {
      title: "בנק",
      helpText: "כאן ממלאים את כל פרטי חשבון הבנק של העסק לביצוע עסקאות וקבלת תשלומים",
      bankBeneficiary: "שם מוטב",
      bankName: "בנק (שם וקוד)",
      bankBranch: "מספר סניף",
      bankAccount: "מספר חשבון",
      bankSwift: "קוד מסלקה SWIFT",
      bankAba: "קוד מסלקה ABA",
      bankIban: "מספר זהב (IBAN)",
      displayBank: "הציגו פרטי חשבון בנק זה במסמכים הנשלחים ללקוחות בעברית"
    },
    accounting: {
      title: "ניהול ספרים",
      taxDeduction: {
        title: "אישורים לצורך ניכוי מס",
        text: "ניתן להפיק את האישורים באופן דיגיטלי כאן",
        link: "לחצו כאן"
      },
      taxDetails: "פרטי עסק לצורכי מיסוי",
      bookkeeping: "קובץ אישור ניהול ספרים",
      withholdingTax: "קובץ אישור ניכוי מס במקור",
      vatType: 'סוג מע"מ',
      vatTypeOptions: {
        NO_VAT: "ללא מע\"מ",
        MONTHLY: "פעם בחודש",
        BI_MONTHLY: "פעם בחודשיים"
      },
      incomeTaxRate: "מקדמות למס הכנסה (באחוזים)",
      nationalInsuranceRate: "מקדמות לביטוח הלאומי (באחוזים)",
      vatDeductionRate: "שיעור ניכוי מס במקור (באחוזים)",
      taxFileNumber: "מספר תיק ניכויים",
      method: "אופן דיווח על הכנסה",
      methodOptions: {
        CASH_BASED: "באמצעות קבלות",
        INVOICE_BASED: "באמצעות חשבוניות"
      },
      recallInclude: "לנוסיף ריקול לדוח תזרים",
      recallPercent: "אחוז ריקול בדוח תזרים",
      emptyFile: "קובץ לא קיים"
    },
    documentContent: {
      title: "תוכן במסמכים",
      helpText: "כאן ניתן לתת אופי ייחודי למסמכים שלך ולהגדיר טקסט קבוע שיצורף אליהם",
      documentEstimate: "טקסט קבוע להצעות מחיר",
      documentProformaInvoice: "טקסט קבוע לחשבונות עסקה",
      documentInvoice: "טקסט קבוע לחשבוניות מס",
      documentInvoiceReceipt: "טקסט קבוע לחשבוניות מס/קבלה",
      documentReceipt: "טקסט קבוע לקבלות",
      documentReceiptRefound: "טקסט קבוע לקבלות זיכוי",
      documentInvoiceRefound: "טקסט קבוע לחשבוניות זיכוי"
    },
    documentNumbers: {
      title: "מספור מסמכים",
      text: "מסמכים חשבונאיים יש להפיק על פי רצף מספרי עקבי. כאן, ניתן לקבוע את הרצף לכל סוג מסמך. המספר שיצוין בשדה יקבע את מספר המסמך הבא שיופק."
    },
    documentSettings: {
      title: "הגדרות מסמכים",
      helpText: "פה ניתן להגדיר כמה נתונים שיקלו עליך להפיק מסמכים",
      vatInServices: 'האם לתמחר סכומים לפני או כולל מע"מ?',
      defaultCentsRounding: "לעגל סכומים",
      emailSubject: {
        legend: "מבנה נושא המייל בשליחת מסמכים",
        labelProformaInvoiceNumber: 'סוג מסמך + מספר - שם העסק (לדוגמא: חשבונית מס 50005 - מדפורם בע"מ)',
        labelProformaInvoice: 'סוג מסמך - שם העסק (לדוגמא: חשבונית מס - מדפורם בע"מ)',
        labelDocumentDescription: 'סוג מסמך + תיאור מסמך - שם העסק (לדוגמא: חשבונית מס עבור שירותי תוכנה  - מדפורם בע"מ)'
      },
      notifications: {
        legend: "איך ברצונך שנעדכן אותך על שליחת המסמך?",
        documentManualCreation: 'שליחת עותק בדוא"ל לאחר הפקה',
        documentAfterApiCreation: 'שליחת עותק בדוא"ל לאחר הפקה ב-API ותשלום בכרטיס אשראי',
        emailOnBounce: "שליחת עדכון רק אם שליחת המסמך נכשלה",
        emailOnError: "שליחת עדכון רק אם שליחת המסמך נכשלה בעסקה עם ממשק צד שלישי (כמו מערכת סליקה למשל)"
      }
    },
    bookkeeping: {
      title: "הנהלת חשבונות",
      emailLegend: 'כתובת דוא"ל הנהלת חשבונות לשליחת הדוחות',
      sendReport: {
        legend: "אילו דוחות ברצונך שנשלח אליך אוטומטית?",
        expenses: "הוצאות",
        incomes: "הכנסות"
      },
      day: {
        legend: "מתי?",
        optionNum2: "ב-2 לחודש",
        optionNum5: "ב-5 לחודש",
        optionNum9: "ב-9 לחודש"
      },
      frequency: {
        legend: "אחת לכמה זמן?",
        labelMonthly: "כל חודש",
        labelBiMonthly: "כל חודשיים"
      },
      sends: {
        documents: "שלחו להנהלת חשבונות עותק מכל מסמך חשבונאי שמופק במערכת"
      }
    },
    calendar: {
      title: "יומן",
      businessWorkDays: "ימי עבודה ברירת מחדל (בהגדרות יומן)",
      businessHoursStart: "משעת עבודה ברירת מחדל (בהגדרות יומן)",
      businessHoursEnd: "עד שעת עבודה ברירת מחדל (בהגדרות יומן)",
      weekStartDay: "יום שבוע הראשון ביומן",
      timeZoneId: "אזור הזמן",
      defaultDoctor: "רופא ברירת מחדל",
      eventColor: "צבע ברירת מחדל של אירוע",
      haveAutoAttendanceLog: "הפעלת דיווח שעות אוטומטי בהתחברות",
      autoLogHoursLimit: "עצירת שעון נוכחות",
      durationValues: "זמן התור (בדקות, ערך אחד בשורה)",
      addAppointmentMinutes: "זמן התור בלחיצה ימנית (בדקות, ערך אחד בשורה)",
      durationIntervalMinutes: "ברירת מחדל זמן התור",
      dayOptions: {
        sunday: "יום א'",
        monday: "יום ב'",
        tuesday: "יום ג'",
        wednesday: "יום ד'",
        thursday: "יום ה'",
        friday: "יום ו'",
        saturday: "יום ש'"
      },
    },
    treatment: {
      title: "טיפולים",
      hourPrice: "עלות שעת טיפול (לצורך חישוב בתוכנית טיפול)",
      fixedNotes: "הערות קבועות בטיפולים (ערך אחד בשורה)",
      laboratoryWorkNotes: "תיאור עבודה (ערך אחד בשורה)",
      medicalRengens: "הגדרות סורקים רפואים"
    }
  }
}
