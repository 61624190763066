import GraphqlService from "../GraphqlService";
import type { TabulatorParams } from "@/models/interfaces";
import { receiptLineSchemaAllFields, type ReceiptLineSchema } from "@/models/schemas";

export default class ReceiptLineService {
  async getReceiptLines(params: TabulatorParams, fields?: string[]) {
    return await GraphqlService.getItems<ReceiptLineSchema>("receiptLines", fields ?? receiptLineSchemaAllFields, params);
  }

  async setReceiptLineCheckAsync(id: number, check: boolean) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "setReceiptLineCheck",
      variables: [
        { field: "id", value: id, valueType: "ID!" },
        { field: "check", value: check, valueType: "Boolean!" }
      ]
    });
    return data ?? false;
  }
}