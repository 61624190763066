export default {
  head: {
    title: "List of expenses",
    meta: [
      { name: "description", content: "List of expenses description" }
    ]
  },
  title: "Expenses",
  popup: {
    confirmDelete: "Delete the expense?"
  },
  filter: {
    search: "Search",
    tooltip: "Search by: supplier name, description, ID",
    dateFrom: "Date from",
    dateTo: "Date to",
    amountFrom: "Amount from",
    amountTo: "Amount to",
    supplier: "Supplier",
    expenseType: "Expense type",
    documentType: "Document type",
    expensePaymentType: "Payment type"
  },
  buttons: {
    add: "Add expense",
    download: "Download",
    edit: "Edit",
    copy: "Add copy",
    downloadFile: "Download"
  },
  documentType: {
    PAYMENT_CONFIRMATION: "Payment confirmation",
    INVOICE: "Invoice",
    INVOICE_RECEIPT: "Invoice receipt",
    CREDIT_INVOICE: "Credit invoice",
    RECEIPT: "Receipt",
    DONATION_RECEIPT: "Donation receipt"
  },
  paymentType: {
    CHECK: "Check",
    BANK_TRANSFER: "Bank transfer",
    CREDIT_CARD: "Credit card",
    PAYPAL: "Paypal",
    CASH: "Cash",
    PAYMENT_APP: "Payment app",
    OTHER: "Other"
  },
  form: {
    create: "Create expense",
    edit: "Edit expense",
    supplier: "Supplier",
    supplierTax: "Tax id",
    documentType: "Document type",
    documentNumber: "Document number",
    documentDate: "Document date",
    reportingDate: "Reporting period",
    amount: "Expense amount (including NDS)",
    vat: "NDS",
    expensePaymentType: "Payment type",
    expenseType: "Expense type",
    patient: "Patient",
    description: "Description",
    notes: "Notes",
    documentUpload: "Document upload",
    removeFile: "Remove file",
    download: "Download",
    submit: "Continue",
    validationMessage: "Supplier is required.",
    docNumExists: "Document number already exist"
  },
  table: {
    columns: {
      documentNumber: "Number",
      documentDate: "Create date",
      reportingDate: "Report month",
      supplierName: "Supplier name",
      patientName: "Patient",
      description: "Description",
      expenseTypeDescription: "Type",
      amount: "Amount",
      vat: "Vat"
    }
  }
}
