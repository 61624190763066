export default {
  head: {
    title: "Message templates",
    meta: [
      { name: "description", content: "Message templates description" }
    ]
  },
  table: {
    columns: {
      id: "ID",
      type: "Template type",
      platform: "Platform",
      systemName: "Template name",
      active: "Active"
    },
  },
  title: "Message templates",
  searchBySystemName: "Search by system name",
  add: "Add message template"
}
