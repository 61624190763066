import type { DateTimeString } from "@/models/interfaces";
import type { SettingType } from "@/models/enums";

export interface SettingSchema {
    id: number;
    createdAt: DateTimeString;
    updatedAt: DateTimeString;
    deletedAt?: DateTimeString;

    key: string;
    settingType: SettingType;
    value: string;
    options: string[];
}

export const settingSchemaAllFields = `
  id
  createdAt
  updatedAt
  deletedAt
  key
  settingType
  value
  options
`;