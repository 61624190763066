export default {
  title: "Send a document",
  emailTitle: "Email title",
  emailAddress: "Enter an email address",
  emailMessage: "Message",
  whatsappPhone: "Phone",
  whatsappMessage: "Message",
  validation: {
    requiredEmail: "Email address is required, type email please and press enter."
  }
}