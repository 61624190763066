import Swal from "sweetalert2";
import { i18n } from "@/locales/i18n";
import type { SweetAlertIcon, SweetAlertInput } from "sweetalert2";

interface PopupAlert {
  title?: string | HTMLElement;
  text?: string;
  html?: string | HTMLElement;
  icon?: SweetAlertIcon;
  confirmButtonText?: string;
}

interface PopupConfirm {
  title?: string | HTMLElement;
  text?: string;
  html?: string | HTMLElement;
  icon?: SweetAlertIcon;
  confirmButtonText?: string;
  cancelButtonText?: string;
}

interface PopupPrompt {
  title?: string | HTMLElement;
  input: SweetAlertInput,
  inputLabel?: string;
  inputPlaceholder?: string,
  inputValue?: string | number | File | FileList;
  inputValidator?: (value: string) => string | null | false | void,
  confirmButtonText?: string;
  cancelButtonText?: string;
}

export class PopupLegacy {
  static async alert(options: PopupAlert) {
    // https://sweetalert2.github.io/#configuration
    const result = await Swal.fire({
      title: options.title,
      html: options.html || ('<i class="mdi mdi-alert-circle-outline me-2"></i>' + options.text),
      icon: options.icon,
      confirmButtonText: options.confirmButtonText ?? i18n.global.t("default.ok"),
      buttonsStyling: false,
      customClass: { confirmButton: "btn btn-primary mx-1" },
      showClass: { popup: "" },
      hideClass: { popup: "" }
      // didOpen: () => Swal.getCloseButton()?.blur(), // https://stackoverflow.com/a/51224201/22548940
    });
    return !!result.value;
  }

  static async confirm(options: PopupConfirm) {
    // https://sweetalert2.github.io/#configuration
    const result = await Swal.fire({
      title: options.title,
      text: options.text,
      html: options.html,
      icon: options.icon,
      showCancelButton: true,
      confirmButtonText: options.confirmButtonText ?? i18n.global.t("default.yes"),
      cancelButtonText: options.cancelButtonText ?? i18n.global.t("default.no"),
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn btn-primary mx-1",
        cancelButton: "btn btn-light mx-1"
      },
      showClass: {
        popup: ""
      },
      hideClass: {
        popup: ""
      }
    });
    return !!result.value;
  }

  static async prompt(options: PopupPrompt) {
    // https://sweetalert2.github.io/#input-types
    const result = await Swal.fire({
      title: options.title,
      input: options.input,
      inputLabel: options.inputLabel,
      inputPlaceholder: options.inputPlaceholder ?? "",
      inputValue: options.inputValue,
      showCancelButton: true,
      confirmButtonText: options.confirmButtonText ?? i18n.global.t("default.ok"),
      cancelButtonText: options.cancelButtonText ?? i18n.global.t("default.cancel"),
      inputValidator: options.inputValidator as any,
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn btn-primary mx-1",
        cancelButton: "btn btn-light mx-1",
        input: "form-control w-auto"
      },
      showClass: {
        popup: ""
      },
      hideClass: {
        popup: ""
      }
    });
    return result.value;
  }
}
