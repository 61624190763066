import type { EmailSettingSchema } from "@/models/schemas/EmailSettingSchema";
import GraphqlService from "../GraphqlService";
import type { EmailMessageViewModel, EmailViewModel, ImapEmailSettingInput, NewEmailViewModel, SmtpEmailSettingInput } from "@/models/api/mutations/EmailModels";
import type { EmailProviderType } from "@/models/enums/EmailProviderType";
import type { Result } from "@/models/interfaces/Result";

const emailSettingsFields = `
  emailProviderType

  imapEmail
  imapPassword
  imapUserName
  imapServer
  imapPort
  imapUseSsl

  smtpPassword
  smtpUserName
  smtpServer
  smtpPort
  smtpUseSsl
`;

const resultFields = `
  success
  exception
`

const newEmailViewModelFields = `
  emails
  pastRecipients
  subject
  body
`

const EmailViewModelFields = `
  messageId
  from
  fromEmailAddress
  subject
  htmlBody
  plainText
  attachments {
    id
    name
    contentBase64
    contentType
  }
`

const emailViewModel = `
  totalRows
  emailMessages {
    id
    sender
    senderEmailAddress
    subject
    date
    isRead
    isSelected
  }
  customFolders{
    id
    name
  }
  customTags{
    id
    name
  }
  isSpecialFoldersAvailable
  isStarredFolderAvailable
  selectFolderRowsCount
`
export default class EmailService {
  async getSettings() {
    return await GraphqlService.queryGql<EmailSettingSchema>({
      method: "emailSetting",
      fields: emailSettingsFields,
    });
  }

  async getEmails(size: number = 15, page: number = 0, folderId: string | null = null) {
    const { data } = await GraphqlService.queryGql<EmailViewModel>({
      method: "emailsAll",
      fields: emailViewModel,
      variables: [
        { field: "size", value: size, valueType: "Int!" },
        { field: "page", value: page, valueType: "Int!" },
        { field: "folderId", value: folderId, valueType: "String" }
      ]
    });
    return data;
  }

  async getEmailMessageView(messageId: string, folderId: string | null = null) {
    const { data } = await GraphqlService.queryGql<EmailMessageViewModel>({
      method: "emailMessage",
      fields: EmailViewModelFields,
      variables: [
        { field: "messageId", value: messageId, valueType: "String!" },
        { field: "folderId", value: folderId, valueType: "String" }
      ]
    });
    return data;
  }

  async getNewEmailViewModel(replyToMessageId: string | null = null, forwardedMessageId: string | null = null, folderId: string | null = null, isReplyToAll: boolean = false) {
    const { data } = await GraphqlService.queryGql<NewEmailViewModel>({
      method: "newEmailViewModel",
      fields: newEmailViewModelFields,
      variables: [
        { field: "replyToMessageId", value: replyToMessageId, valueType: "String" },
        { field: "forwardedMessageId", value: forwardedMessageId, valueType: "String" },
        { field: "folderId", value: folderId, valueType: "String" },
        { field: "isReplyToAll", value: isReplyToAll, valueType: "Boolean!" },
      ]
    });
    return data;
  }

  async setMailSeenStatus(messageId: string, status: boolean, folderId: string | null = null) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "setMailSeenStatus",
      variables: [
        { field: "messageId", value: messageId, valueType: "String!" },
        { field: "status", value: status, valueType: "Boolean!" },
        { field: "folderId", value: folderId, valueType: "String" }
      ]
    });
    return data ?? false;
  }

  async setMailsSeenStatus(messageIds: string[], status: boolean, folderId: string | null = null) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "setMailsSeenStatus",
      variables: [
        { field: "messageIds", value: messageIds, valueType: "[String!]" },
        { field: "status", value: status, valueType: "Boolean!" },
        { field: "folderId", value: folderId, valueType: "String" }
      ]
    });
    return data ?? false;
  }

  async moveMessageToOtherFolder(messageId: string, oldFolderId: string | null, newFolderId: string | null) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "moveMessageToOtherFolder",
      variables: [
        { field: "messageId", value: messageId, valueType: "String!" },
        { field: "oldFolderId", value: oldFolderId, valueType: "String" },
        { field: "newFolderId", value: newFolderId, valueType: "String" },
      ]
    });
    return data ?? false;
  }

  async deleteEmailMessage(messageId: string, folderId: string | null = null) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "deleteEmailMessage",
      variables: [
        { field: "messageId", value: messageId, valueType: "String!" },
        { field: "folderId", value: folderId, valueType: "String" }
      ]
    });
    return data ?? false;
  }

  async deleteEmailMessages(messageIds: string[], folderId: string | null = null) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "deleteEmailMessages",
      variables: [
        { field: "messageIds", value: messageIds, valueType: "[String!]" },
        { field: "folderId", value: folderId, valueType: "String" }
      ]
    });
    return data ?? false;
  }

  async setEmailProviderType(emailProviderType: EmailProviderType) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "setEmailProviderType",
      variables: [
        { field: "emailProviderType", value: emailProviderType, valueType: "EmailProviderType!" },
      ]
    });
    return data ?? false;
  }

  async saveSmtpEmailSetting(input: SmtpEmailSettingInput, testEmailAddress: string): Promise<Result> {
    const { data } = await GraphqlService.mutateGql<Result>({
      method: "saveUserSmtpEmailSetting",
      fields: resultFields,
      variables: [
        { field: "input", value: input, valueType: "SmtpEmailSettingInput!" },
        { field: "emailAddress", value: testEmailAddress, valueType: "String!" },
      ]
    });
    return data ?? { success: false, exception: "Empty result" };
  }

  async saveImapEmailSetting(input: ImapEmailSettingInput): Promise<Result> {
    const { data } = await GraphqlService.mutateGql<Result>({
      method: "saveUserImapEmailSetting",
      fields: resultFields,
      variables: [
        { field: "input", value: input, valueType: "ImapEmailSettingInput!" },
      ]
    });
    return data ?? { success: false, exception: "Empty result" };
  }

  async sendEmail(emails: string[], subject: string, htmlBody: string, fileNames: string[], inReplyTo: string | null = null, forwardMessageId: string | null = null, folderId: string | null = null): Promise<Result> {
    const { data } = await GraphqlService.mutateGql<Result>({
      method: "sendEmail",
      fields: resultFields,
      variables: [
        { field: "emails", value: emails, valueType: "[String]!" },
        { field: "subject", value: subject, valueType: "String!" },
        { field: "htmlBody", value: htmlBody, valueType: "String!" },
        { field: "attachments", value: fileNames, valueType: "[String]" },
        { field: "inReplyTo", value: inReplyTo, valueType: "String" },
        { field: "forwardMessageId", value: forwardMessageId, valueType: "String" },
        { field: "folderId", value: folderId, valueType: "String" },
      ]
    });
    return data ?? { success: false, exception: "Empty result" };
  }

}
