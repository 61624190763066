export default {
  head: {
    title: "MedForm - Change Password",
    meta: [
      { name: "description", content: "MedForm change password" }
    ]
  },
  title: "Change Password",
  desc: "Enter new password",
  input: {
    email: "Email",
    passwordNew: "New Password",
    passwordRepeat: "Repeat password"
  },
  save: "Save",
  error: "An error occurred, please try again.",
  success: "Password successfully changed!"
}
