import type { DateTimeString } from "@/models/interfaces";

export interface LeadSchema {
  id: number;
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
  deletedAt?: DateTimeString;

  leadSourceId?: number;
  followUp: DateTimeString;
  statusId?: number;
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  serviceId?: number;
  channelId?: number;
  otherData?: Record<string, string>;
  url?: string;
  referrer?: string;
  clientIP?: string;
  clientBrowserAgent?: string;
  notValid: boolean;
  archived: boolean;

  fullName?: string;
  statusName?: string;
  serviceName?: string;
  channelName?: string;
}

export const leadSchemaAllFields = `
  id
  createdAt
  updatedAt
  deletedAt

  leadSourceId
  followUp
  statusId
  firstName
  lastName
  phone
  email
  serviceId
  channelId
  otherData
  url
  referrer
  clientIP
  clientBrowserAgent
  notValid
  archived

  fullName
  statusName
  serviceName
  channelName
`;