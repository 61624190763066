import GraphqlService from "../GraphqlService";
import gql from "graphql-tag";
import { useApolloClient } from "@vue/apollo-composable";
import { leadNoteSchemaAllFields, leadSchemaAllFields, type LeadNoteSchema, type LeadSchema } from "@/models/schemas";
import type QueryOffsetPage from "@/models/api/queries/QueryOffsetPage";
import type { TabulatorParams } from "@/models/interfaces";
import type { FilterLeadInput } from "@/models/api/queries/LeadModels";
import type { SaveLeadInput } from "@/models/api/mutations/LeadModels";
import type { SaveLeadNoteInput } from "@/models/api/mutations/LeadNoteModels";

export default class LeadService {
  //#region Lead
  async getLeads(limit: number, page: number, statusId?: number | null) {
    const params: TabulatorParams = {
      size: limit,
      page: page,
      filter: [{ field: "statusId", type: "=", value: statusId }],
      sort: [{ field: "id", dir: "desc" }]
    };
    return await GraphqlService.getItems<LeadSchema>("leads", leadSchemaAllFields, params);
  }

  async getLeadsTabulator(params: TabulatorParams, input?: FilterLeadInput) {
    return await GraphqlService.getItems<LeadSchema>("leads", leadSchemaAllFields, params, {
      variables: [{ field: "input", value: input, valueType: "FilterLeadInput" }]
    });
  }

  async getLead(id: number) {
    return await GraphqlService.getItem<LeadSchema>("lead", leadSchemaAllFields, id);
  }

  async saveLead(input: SaveLeadInput, fields?: string[]) {
    if (!input.id) {
      input.id = 0;
    }
    if (!input.note) {
      input.note = "";
    }
    return await GraphqlService.setItem<LeadSchema>("saveLead", "lead", fields ?? leadSchemaAllFields, input);
  }

  async addLeadAsPatient(id: number) {
    const { data } = await GraphqlService.mutateGql<number>({
      method: "addLeadAsPatient",
      variables: [{ field: "id", value: id, valueType: "ID!" }]
    });
    return data ?? 0;
  }

  async archiveLead(id: number, archived: boolean) {
    return await GraphqlService.deleteItem("archiveLead", id, {
      variables: [{ field: "archived", value: archived, valueType: "Boolean!" }]
    });
  }

  async validateLead(id: number, notValid: boolean) {
    return await GraphqlService.deleteItem("validateLead", id, {
      variables: [{ field: "notValid", value: notValid, valueType: "Boolean!" }]
    });
  }

  async getNumberOfUnprocessedLeads() {
    const { client } = useApolloClient();
    const { data } = await client.query<{ leads: QueryOffsetPage<LeadSchema> }>({
      query: gql`
        query leads {
          leads(where: {
            statusId: {neq:null}
          }) {
            totalCount
          }
        }
      `,
      variables: {
      }
    });
    return data.leads.totalCount;
  }
  //#endregion

  //#region LeadNote
  async getLeadNotes(params: TabulatorParams, leadId?: number) {
    if (leadId) {
      params.filter.push({ field: "leadId", type: "=", value: leadId });
    }
    return await GraphqlService.getItems<LeadNoteSchema>("leadNotes", leadNoteSchemaAllFields, params);
  }

  async getLeadNote(id: number, fields?: string[]) {
    return await GraphqlService.getItem<LeadNoteSchema>("leadNote", fields ?? leadNoteSchemaAllFields, id);
  }

  async saveLeadNote(input: SaveLeadNoteInput, fields?: string[]) {
    return await GraphqlService.setItem<LeadNoteSchema>("saveLeadNote", "leadNote", fields ?? leadNoteSchemaAllFields, input);
  }
  //#endregion
}
