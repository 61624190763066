import type { DateTimeString } from "@/models/interfaces";

export interface LaboratoryWorkNoteSchema {
  id: number;
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
  deletedAt?: DateTimeString;

  laboratoryWorkId: number;
  text: string;
}

export const laboratoryWorkNoteSchemaAllFields = [
  "id",
  "createdAt",
  "updatedAt",
  "deletedAt",
  "laboratoryWorkId",
  "text"
];