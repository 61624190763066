import { defineStore } from 'pinia'

// https://blog.logrocket.com/complex-vue-3-state-management-pinia/
export const useTabsStore = defineStore({
  id: 'tabs',
  state: () => ({
    tabs: {} as Record<string, any[]>
  }),
  actions: {
    setChanges(key: string, index: any) {
      if (key in this.tabs) {
        this.tabs[key].push(index);
      } else {
        this.tabs[key] = [index];
      }
    },
    hasChanges(key: string, index: any) {
      return this.tabs[key]?.some(x => x === index) ?? false;
    },
    clear(key: string) {
      if (key in this.tabs) {
        delete this.tabs[key];
      }
    },
    clearAll() {
      this.tabs = {};
    },
  }
})
