import type { ComponentCustomProperties } from "vue";
import { getCookie, setCookie } from "@/utils";
import { createI18n } from "vue-i18n";
import en from "./en";
import he from "./he";
import primeVueLangs from './primevue-langs';
import moment from "@/plugins/VueMomentPlugin";

const lang = getCookie("lang") ?? import.meta.env.VITE_APP_I18N_LOCALE ?? "he";

export const i18n = createI18n({
  locale: lang,
  allowComposition: true,
  legacy: false,
  globalInjection: true,
  fallbackLocale: "en",
  messages: { en, he },
});

function getDir(lang: string) {
  switch (lang) {
    case "en": return "ltr";
    case "he": return "rtl";
    default: return "";
  }
}

export function setLocale(lang: string, reload?: boolean) {
  if (["en", "he"].includes(lang)) {
    const dir = getDir(lang);

    setCookie("lang", lang, 365);
    setCookie("dir", dir, 365);

    if (reload) {
      location.href = location.href;
    } else {
      i18n.global.locale.value = lang;
      moment.locale(lang);

      document.documentElement.setAttribute("lang", lang);
      document.documentElement.setAttribute("dir", dir);
    }
  }
}

export function setLocaleForPlugins(vue: ComponentCustomProperties, lang: string) {
  // https://formkit.com/essentials/internationalization
  vue.$formkit.setLocale(lang);
  // https://primevue.org/configuration/#locale
  vue.$primevue.config.locale = lang == "he" ? primeVueLangs.he : primeVueLangs.en;
}

setLocale(lang);

// https://vue-i18n.intlify.dev/guide/migration/vue3.html
// https://vue-i18n.intlify.dev/guide/advanced/composition.html
// https://lokalise.com/blog/vue-i18n/
