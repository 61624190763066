import type { DateTimeString } from "@/models/interfaces";

export interface DepositCashSchema {
  id: number;
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
  deletedAt: DateTimeString | null;
  quantity010: number;
  quantity050: number;
  quantity1: number;
  quantity2: number;
  quantity5: number;
  quantity10: number;
  quantity20: number;
  quantity50: number;
  quantity100: number;
  quantity200: number;
  total: number;
}

export const depositCashSchemaAllFields = [
  "id",
  "createdAt",
  "updatedAt",
  "deletedAt",
  "quantity010",
  "quantity050",
  "quantity1",
  "quantity2",
  "quantity5",
  "quantity10",
  "quantity20",
  "quantity50",
  "quantity100",
  "quantity200",
  "total"
];
