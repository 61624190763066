export default {
  title: "הכנסות והוצאות",
  period: "תקופה",
  incomesTitle: "הכנסות",
  expensesTitle: "הוצאות",
  head: {
    title: "מבט על",
    meta: [
      { name: "description", content: "מבט על" }
    ]
  },
  filter: {
    dateFrom: 'מתאריך',
    dateTo: 'עד תאריך',
    vat: 'סכומים כוללים מע"מ'
  }
}
