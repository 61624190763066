export default {
  head: {
    title: "Message template",
    meta: [
      { name: "description", content: "Message template" }
    ]
  },
  table: {
    columns: {
      id: "ID",
      createdAt: "Created",
      name: "Path"
    }
  },
  validation: {
    required: "This field must be filled in to continue"
  },
  createTitle: "Create message template",
  updateTitle: "Update message template {0}",
  systemName: "System name",
  subject: "Subject",
  attachments: "Attachments",
  platform: "Platform",
  type: "Type",
  text: "Text",
  active: "Active",
  nameUsed: "Message template name is already in use",
  saveBeforeUpload: "Please save message template before uploading attachments",
  addAttachment: "Upload attachment",
  attachmentSaved: "Attachment saved",
  attachmentRemoved: "Attachment removed"
}
