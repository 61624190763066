<template>
  <div class="p-formkit">
    <ColorPicker
      ref="colorPicker"
      v-model="context._value"
      :disabled="attrs._disabled ?? context.disabled ?? false"
      :readonly="attrs._readonly ?? context.readonly ?? false"
      :style="attrs.style"
      :class="styleClass"
      :tabindex="attrs.tabindex"
      :aria-label="attrs.ariaLabel"
      :aria-labelledby="attrs.ariaLabelledby"
      :default-color="attrs.defaultColor ?? 'ff0000'"
      :inline="attrs.inline ?? false"
      :format="attrs.format"
      :pt="attrs.pt"
      :pt-options="attrs.ptOptions"
      :unstyled="attrs.unstyled ?? false"
      :base-z-index="10000"
      @change="handleChange" />
  </div>
</template>

<script setup lang="ts">
  import ColorPicker from "primevue/colorpicker";
  import { ref, computed, onMounted, onUpdated, nextTick } from "vue";
  import { HtmlHelper } from "@/utils";

  const props = defineProps<{
    context: any;
  }>();

  const context = props.context;
  const attrs = computed(() => context?.attrs);

  function handleChange(e: any) {
    context?.node.input("#" + props.context?._value);
  }

  const colorPicker = ref /*<InstanceType<typeof ColorPicker>>*/();

  function setClassInput() {
    if (attrs.value && attrs.value["class-input"]) {
      nextTick(() => {
        const input = HtmlHelper.findFirst<HTMLInputElement>("input", colorPicker.value.$el);
        const classes = attrs.value["class-input"].split(" ") as string[];
        classes.forEach((item) => input?.classList.add(item));
      });
    }
  }
  onMounted(() => setClassInput());
  onUpdated(() => setClassInput());

  const styleClass = computed(() => (context?.state.validationVisible && !context?.state.valid ? `${attrs.value?.class} p-invalid` : attrs.value?.class));
</script>

<style lang="scss">
  .p-colorpicker {
    .p-colorpicker-preview {
      min-height: 36px;
      min-width: 36px;
    }
  }
</style>
