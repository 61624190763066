import GraphqlService from "../GraphqlService";
import { expenseSchemaAllFields, expenseTypeSchemaAllFields, type ExpenseSchema, type ExpenseTypeSchema } from "@/models/schemas";
import type { TabulatorParams } from "@/models/interfaces";
import type { SaveExpenseTypeInput, SaveExpenseInput } from "@/models/api/mutations/ExpensesModels";
import type { FilterExpenseInput } from "@/models/api/queries/ExpensesModels";

export default class ExpenseService {
  //#endregion Expenses
  async getExpense(expenseId: number) {
    return await GraphqlService.getItem<ExpenseSchema>("expense", expenseSchemaAllFields, expenseId);
  }

  async getExpenses(params: TabulatorParams, input?: FilterExpenseInput, fields?: string[]) {
    return await GraphqlService.getItems<ExpenseSchema>("expenses", fields ?? expenseSchemaAllFields, params, {
      variables: [{ field: "input", value: input, valueType: "FilterExpenseInput" }]
    });
  }

  async deleteExpense(expenseId: number) {
    return await GraphqlService.deleteItem("deleteExpense", expenseId);
  }

  async deleteExpenseFile(expenseId: number) {
    return await GraphqlService.deleteItem("deleteExpenseFile", expenseId);
  }

  async saveExpense(input: SaveExpenseInput) {
    const { data } = await GraphqlService.mutateGql<number>({
      method: "saveExpense",
      variables: [{ field: "input", value: input, valueType: "SaveExpenseInput" }],
    });
    return data ?? 0;
  }

  async checkExpenseDocumentNumber(expenseId: number, supplierId: number | null, supplierName: string | null, documentNumber: string) {
    const { data } = await GraphqlService.queryGql<boolean>({
      method: "checkExpenseDocumentNumber",
      variables: [
        { field: "id", value: expenseId, valueType: "ID!" },
        { field: "supplierId", value: supplierId, valueType: "ID" },
        { field: "supplierName", value: supplierName, valueType: "String" },
        { field: "documentNumber", value: documentNumber, valueType: "String" }
      ]
    });
    return data ?? false;
  }

  async checkExpenseTypeIsDeletable(expenseId: number) {
    const { data } = await GraphqlService.queryGql<boolean>({
      method: "checkExpenseTypeIsDeletable",
      variables: [{ field: "id", value: expenseId, valueType: "ID!" }]
    });
    return data ?? false;
  }
  //#endregion

  //#endregion ExpenseTypes
  async saveExpenseType(input: SaveExpenseTypeInput) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "saveExpenseType",
      variables: [{ field: "input", value: input, valueType: "SaveExpenseTypeInput" }],
    });
    return data ?? false;
  }

  async deleteExpenseType(expenseTypeId: number) {
    return await GraphqlService.deleteItem("deleteExpenseType", expenseTypeId);
  }

  async getExpenseType(expenseTypeId: number) {
    return await GraphqlService.getItem<ExpenseTypeSchema>("expenseType", expenseTypeSchemaAllFields, expenseTypeId);
  }

  async getExpenseTypes(params: TabulatorParams, fields?: string[]) {
    return await GraphqlService.getItems<ExpenseTypeSchema>("expenseTypes", fields ?? expenseTypeSchemaAllFields, params);
  }

  async getExpenseTypesAll(fields?: string[]) {
    const { data } = await GraphqlService.queryGql<ExpenseTypeSchema[]>({
      method: "expenseTypesAll",
      fields: fields ?? expenseTypeSchemaAllFields,
      order: [
        { field: "description", value: "ASC" },
        { field: "id", value: "ASC" }
      ]
    });
    return data ?? [];
  }
  //#endregion
}
