// https://bobbyhadz.com/blog/typescript-create-type-from-object-keys

export const Roles = {
  DoctorAdmin: "doctoradmin",
  Doctor: "doctor",
  Secretary: "secretary",
  // Affiliates: "affiliates",
  // Patient: "patient",
  // Laboratory: "laboratory",
  // Accountant: "accountant",
  // Insurance: "insurance",
} as const;

type RolesKeys = keyof typeof Roles;

export type RolesValues = (typeof Roles)[RolesKeys];
