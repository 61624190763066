export default {
  head: {
    title: "Users",
    meta: [
      { name: "description", content: "Users description" }
    ]
  },
  table: {
    columns: {
      id: "ID",
      fullName: "Full name",
      email: "Email",
      phoneNumber: "Phone",
      roles: "Role",
      active: "Active",
    },
  },
  title: "Users",
  create: "Create user",
  reallyRemove: "Do you really want to remove user {0}?",
  saved: "User saved!"
}
