// https://blog.logrocket.com/complex-vue-3-state-management-pinia/
import { useDatabaseStore } from "./pinia/database";
import { useLayoutStore } from "./pinia/layout";
import { useNotificationStore } from "./pinia/notification";
import { useSettingsStore } from "./pinia/settings";
import { useMessagesStore } from "./pinia/messages";
import { useTabsStore } from "./pinia/tabs";
import { useTaskStore } from "./pinia/task";
import { useLaboratoryWorkStore } from "./pinia/laboratoryWork";
import { useUserStore } from "./pinia/user";
import { useNotificationsStore } from "./pinia/notifications";
import { usePopupStore } from "./pinia/popup";

class StoreContainer {
  private _database: any;
  public get database(): ReturnType<typeof useDatabaseStore> {
    return this._database ??= useDatabaseStore();
  }

  private _layout: any;
  public get layout(): ReturnType<typeof useLayoutStore> {
    return this._layout ??= useLayoutStore();
  }

  private _messages: any;
  public get messages(): ReturnType<typeof useMessagesStore> {
    return this._messages ??= useMessagesStore();
  }

  private _notification: any;
  public get notification(): ReturnType<typeof useNotificationStore> {
    return this._notification ??= useNotificationStore();
  }

  private _notifications: any;
  public get notifications(): ReturnType<typeof useNotificationsStore> {
    return this._notifications ??= useNotificationsStore();
  }

  private _settings: any;
  public get settings(): ReturnType<typeof useSettingsStore> {
    return this._settings ??= useSettingsStore();
  }

  private _tabs: any;
  public get tabs(): ReturnType<typeof useTabsStore> {
    return this._tabs ??= useTabsStore();
  }

  private _task: any;
  public get task(): ReturnType<typeof useTaskStore> {
    return this._task ??= useTaskStore();
  }

  private _laboratoryWork: any;
  public get laboratoryWork(): ReturnType<typeof useLaboratoryWorkStore> {
    return this._laboratoryWork ??= useLaboratoryWorkStore();
  }

  private _user: any;
  public get user(): ReturnType<typeof useUserStore> {
    return this._user ??= useUserStore();
  }

  private _popup: any;
  public get popup(): ReturnType<typeof usePopupStore> {
    return this._popup ??= usePopupStore();
  }
}

let _container: StoreContainer;
export const useStores = () => _container ??= new StoreContainer();
