export class LocalStore {
  public static get<T>(key: string, defaultValue?: T) {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) as T : (defaultValue ?? null);
  }

  public static has(key: string) {
    return localStorage.getItem(key) !== null;
  }

  public static set<T>(key: string, value: T) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  public static remove(key: string) {
    localStorage.removeItem(key);
  }
}
