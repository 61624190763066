export default {
  head: {
    title: "Document templates",
    meta: [
      { name: "description", content: "Document templates description" }
    ]
  },
  table: {
    columns: {
      id: "ID",
      title: "Title",
      active: "Active"
    },
  },
  title: "Document templates",
  add: "Add document template",
  searchByTitle: "Search by title"
}
