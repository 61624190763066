﻿export default {
  head: {
    title: "מעבדה",
    meta: [
      { name: "description", content: "מעבדה" }
    ]
  },

  generalGroup: "כללי",
  addressGroup: "כתובת",
  contactsGroup: "פרטי קשר",

  active: "פעיל",
  title: "שם חברה",
  address: "כתובת ורחוב",
  city: "עיר",
  zip: "מיקוד",
  country: "מדינה",
  department: "תעשיה",
  notes: "הערות",
  contactPerson: "איש קשר",
  phone: "טלפון",
  fax: "פקס",
  mobile: "נייד",
  email1: "דוא\"ל 1",
  email2: "דוא\"ל 2",
  email3: "דוא\"ל 3",

  createTitle: "הוספת מעבדה",
  updateTitle: "מעבדה {0}",
}
