import type { DateTimeString } from "@/models/interfaces";

export interface ServiceSchema {
  id: number;
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
  deletedAt?: DateTimeString;

  categoryId: number;
  categoryName: string;
  categoryOrder: number;

  name: string;
  code?: string;
  description?: string;
  appointmentMinutes: number;
  materialPrice: number;

  recallMessageAutomatically: boolean;
  recallDays: number;
  recallTemplate?: string;
  recallMessageSubject?: string;
  recallMessageSendWithSms: boolean;
  recallMessageSendWithWhatsapp: boolean;
  recallMessageSendWithEmail: boolean;

  allowWebpageBooking: boolean;
  active: boolean;

  notesInReminder?: string;
  notesInFollowUp?: string;

  sendFollowUpMessage: boolean;
}

export const serviceSchemaAllFields = `
  id
  createdAt
  updatedAt
  deletedAt

  categoryId
  categoryName
  categoryOrder

  name
  code
  description
  appointmentMinutes
  materialPrice

  recallMessageAutomatically
  recallDays
  recallTemplate
  recallMessageSubject
  recallMessageSendWithSms
  recallMessageSendWithWhatsapp
  recallMessageSendWithEmail

  allowWebpageBooking
  active

  notesInReminder
  notesInFollowUp

  sendFollowUpMessage
`;