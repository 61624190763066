export default {
  head: {
    title: "דוח הוצאות",
    meta: [
      { name: "description", content: "דוח הוצאות" }
    ]
  },
  title: "דוח הוצאות",
  dateFrom: "מתאריך",
  dateTo: "עד תאריך",
  submit: "הפקת דוח",
  email: {
    label: "שליחה למייל",
    help: "הדוח ישלח למייל שתגדירו כאן (לא חובה)",
    copyText: "להעתקת כתובות המייל מהגדרות הדיוור > הנהלת חשבונות",
    copyLink: "לחצו כאו"
  }
}
