export default {
  docs: "Related documents",
  view: "View",
  empty: "This document has no related documents",
  table: {
    columns: {
      financialNumber: "Number",
      documentDate: "Date",
      incomeType: "Type",
      payAmount: "Amount"
    }
  }
}