import GraphqlService from "../GraphqlService";
import { type HistorySchema, historySchemaAllFields } from "@/models/schemas";
import type { TabulatorParams } from "@/models/interfaces";

export default class HistoryService {
  async getTreatmentPlanHistory(patientId: number, treatmentPlanId: number | null, params: TabulatorParams) {
    return await GraphqlService.getItems<HistorySchema>("treatmentPlanHistory", historySchemaAllFields, params, {
      variables: [
        { field: "patientId", value: patientId, valueType: "ID!" },
        { field: "treatmentPlanId", value: treatmentPlanId, valueType: "ID" },
      ],
    });
  }
}
