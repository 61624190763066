export default {
  actions: {
    edit: "עריכה",
    archive: "העברה לארכיון",
    unarchive: "העבר לפעילים",
  },
  head: {
    title: "משימות",
    meta: [
      { name: "description", content: "משימות" }
    ]
  },
  table: {
    columns: {
      id: "#",
      createdAt: "נוצרה ב",
      followUp: "תאריך מעקב",
      title: "משימה",
      patientName: "מטופל",
      assigneeName: "רופא מטפל",
      status: "סטטוס",
    }
  },
  searchPlaceholder: "חיפוש",
  filter: {
    status: "סטטוס",
    all: "הכל",
    new: "חדש",
    closed: "ארכיון",
    created: "נוצרה ב",
    followUp: "תאריך מעקב",
  },
  status: {
    NEW: "חדש",
    CLOSED: "נסגרה ב",
  },
  download: "הורדת קובץ",
  newTask: "משימה חדשה"
}
