import type { Result } from "@/models/interfaces/Result";
import { HttpService } from "../HttpService";

export default class GoogleAuthService {
    public static async signOut(): Promise<boolean> {
        const result = await HttpService.get<Result<object>, string>("google/auth/logout/full", {
            useAuth: true
        });

        if (!result) {
            throw new Error("No response from google calendar api.");
        }

        if (!result.success || result.message) {
            // eslint-disable-next-line no-console
            console.error(result?.message);
        }

        return result?.success ?? false;
    }

    public static signInCalendar(): Promise<void> {
        return this.signIn("calendar");
    }

    public static signInMail(): Promise<void> {
        return this.signIn("mail");
    }

    public static haveAccessToCalendar(): Promise<boolean> {
        return this.haveAccess("calendar");
    }

    public static haveAccessToMail(): Promise<boolean> {
        return this.haveAccess("mail");
    }

    private static async haveAccess(path: string): Promise<boolean> {
        const result = await HttpService.get<Result<boolean>, string>(`google/auth/access/${path}`, {
            useAuth: true
        });

        return result?.data ?? false;
    }

    private static async signIn(path: string): Promise<void> {
        const currentUrl = window.location.href.split('?')[0];
        const encodedParam = encodeURI(`redirectUrl=${currentUrl}`);
        const redirectUrl = await HttpService.get<string, string>(`google/auth/login/${path}?${encodedParam}`, {
            useAuth: true
        });

        window.location.assign(redirectUrl as string);
    }
}