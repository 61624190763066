export default {
  head: {
    title: "רשימת המתנה",
    meta: [
      { name: "description", content: "רשימת המתנה" }
    ]
  },
  filter: {
    time: {
      title: "זמן",
      all: "הכל",
      today: "מתאימים להיום",
      tomorrow: "מתאים למחר",
      afterTomorrow: "מתאים למחרותיים",
      suitable: "מתאימים ל {0}",
    }
  },
  table: {
    columns: {
      id: "#",
      patientName: "שם מלא",
      doctorNames: "רופאים",
      days1: "מועדים א'",
      days2: "מועדים ב'",
      appointmentStartTime: "תור הבא",
      serviceName: "שירות",
      appointmentDuration: "משך תור"
    }
  },
  view: {
    doctors: "רופאים",
    appointment: "תור",
    patient: "מטופל",
    fromDate: "מתאריך",
    toDate: "עד תאריך",
    days1: "מועד א'",
    days2: "מועד ב'",
    fromHour: "משעה",
    toHour: "עד שעה",
  },
  add: "הוספה לרשימה",
  addWaitlist: "הוספה ליומן",
  viewPatient: "צפיה במטופל",
  edit: {
    title: "עריכת רשימת המתנה",
    titleNew: "הוספה לרשימת המתנה",
  }
}
