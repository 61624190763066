export default {
  head: {
    title: "Wait list",
    meta: [
      { name: "description", content: "Wait list" }
    ]
  },
  filter: {
    time: {
      title: "Time",
      all: "All",
      today: "Suitable for today",
      tomorrow: "Suitable for tomorrow",
      afterTomorrow: "Suitable for day after tomorrow",
      suitable: "Suitable for {0}",
    }
  },
  table: {
    columns: {
      id: "ID",
      patientName: "Full name",
      doctorNames: "Doctors",
      days1: "Days 1",
      days2: "Days 2",
      appointmentStartTime: "Appointment",
      serviceName: "Service",
      appointmentDuration: "Duration"
    }
  },
  view: {
    doctors: "Doctors",
    appointment: "Appointment",
    patient: "Patient",
    fromDate: "From date",
    toDate: "To date",
    days1: "Days 1",
    days2: "Days 2",
    fromHour: "From hour",
    toHour: "To hour",
  },
  add: "Add to list",
  addWaitlist: "Add to calendar today",
  viewPatient: "View patient",
  edit: {
    title: "Edit wait list",
    titleNew: "Add wait list",
  }
}
