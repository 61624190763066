export default {
  head: {
    title: "Accounting report",
    meta: [
      { name: "description", content: "Accounting report" }
    ]
  },
  title: "Accounting report",
  actions: {
    download: "Download",
    addPayment: "Add payment",
    viewHistory: "Payment history"
  },
  myPayments: {
    title: "My payments",
    table: {
      columns: {
        createdAt: "Date",
        amount: "Amount",
        transactionDetails: "Transaction details"
      }
    }
  },
  myTreatments: {
    title: "My open treatments",
    table: {
      columns: {
        treatmentDate: "Treatment date",
        treatmentDescription: "Treatment description",
        patientName: "Client",
        incomeId: "Receipt",
        amount: "Amount"
      }
    }
  },
  paymentsToDoctors: {
    title: "Payments to doctors",
    summary: "Total",
    table: {
      columns: {
        doctorName: "Doctor",
        amountOwed: "Amount"
      }
    }
  },
  openTreatments: {
    title: "Open treatments",
    table: {
      columns: {
        treatmentDate: "Treatment date",
        treatmentDescription: "Treatment description",
        doctorName: "Doctor",
        patientName: "Client",
        incomeId: "Document",
        amount: "Amount"
      }
    }
  },
  paymentsHistoryPopup: {
    titlePart: "payments made to the doctor",
    removePaymentWarn: "Delete the payment?",
    table: {
      columns: {
        createdAt: "Date",
        amount: "Amount",
        transactionDetails: "Transaction details"
      }
    }
  },
  addPaymentPopup: {
    titlePart: "adding payment to the doctor",
    amountLabel: "Amount",
    detailsLabel: "Transaction details",
    table: {
      columns: {
        treatmentDate: "Treatment date",
        incomeId: "Document",
        calculatedAmountForDoctor: "Amount"
      }
    }
  }
}
