// https://github.com/primefaces/primelocale/tree/main
// https://primevue.org/configuration/#locale
export default {
  "en": {
    "startsWith": "Starts with",
    "contains": "Contains",
    "notContains": "Not contains",
    "endsWith": "Ends with",
    "equals": "Equals",
    "notEquals": "Not equals",
    "noFilter": "No Filter",
    "filter": "Filter",
    "lt": "Less than",
    "lte": "Less than or equal to",
    "gt": "Greater than",
    "gte": "Greater than or equal to",
    "dateIs": "Date is",
    "dateIsNot": "Date is not",
    "dateBefore": "Date is before",
    "dateAfter": "Date is after",
    "custom": "Custom",
    "clear": "Clear",
    "apply": "Apply",
    "matchAll": "Match All",
    "matchAny": "Match Any",
    "addRule": "Add Rule",
    "removeRule": "Remove Rule",
    "accept": "Yes",
    "reject": "No",
    "choose": "Choose",
    "upload": "Upload",
    "cancel": "Cancel",
    "completed": "Completed",
    "pending": "Pending",
    "fileSizeTypes": ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    "dayNames": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    "dayNamesShort": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    "dayNamesMin": ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
    "monthNames": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    "monthNamesShort": ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    "chooseYear": "Choose Year",
    "chooseMonth": "Choose Month",
    "chooseDate": "Choose Date",
    "prevDecade": "Previous Decade",
    "nextDecade": "Next Decade",
    "prevYear": "Previous Year",
    "nextYear": "Next Year",
    "prevMonth": "Previous Month",
    "nextMonth": "Next Month",
    "prevHour": "Previous Hour",
    "nextHour": "Next Hour",
    "prevMinute": "Previous Minute",
    "nextMinute": "Next Minute",
    "prevSecond": "Previous Second",
    "nextSecond": "Next Second",
    "am": "AM",
    "pm": "PM",
    "today": "Today",
    "now": "Now",
    "weekHeader": "Wk",
    "firstDayOfWeek": 0,
    "showMonthAfterYear": false,
    "dateFormat": "mm/dd/yy",
    "weak": "Weak",
    "medium": "Medium",
    "strong": "Strong",
    "passwordPrompt": "Enter a password",
    "emptyFilterMessage": "No results found",
    "searchMessage": "{0} results are available",
    "selectionMessage": "{0} items selected",
    "emptySelectionMessage": "No selected item",
    "emptySearchMessage": "No results found",
    "emptyMessage": "No available options",
    "aria": {
      "trueLabel": "True",
      "falseLabel": "False",
      "nullLabel": "Not Selected",
      "star": "1 star",
      "stars": "{star} stars",
      "selectAll": "All items selected",
      "unselectAll": "All items unselected",
      "close": "Close",
      "previous": "Previous",
      "next": "Next",
      "navigation": "Navigation",
      "scrollTop": "Scroll Top",
      "moveTop": "Move Top",
      "moveUp": "Move Up",
      "moveDown": "Move Down",
      "moveBottom": "Move Bottom",
      "moveToTarget": "Move to Target",
      "moveToSource": "Move to Source",
      "moveAllToTarget": "Move All to Target",
      "moveAllToSource": "Move All to Source",
      "pageLabel": "Page {page}",
      "firstPageLabel": "First Page",
      "lastPageLabel": "Last Page",
      "nextPageLabel": "Next Page",
      "previousPageLabel": "Previous Page",
      "rowsPerPageLabel": "Rows per page",
      "jumpToPageDropdownLabel": "Jump to Page Dropdown",
      "jumpToPageInputLabel": "Jump to Page Input",
      "selectRow": "Row Selected",
      "unselectRow": "Row Unselected",
      "expandRow": "Row Expanded",
      "collapseRow": "Row Collapsed",
      "showFilterMenu": "Show Filter Menu",
      "hideFilterMenu": "Hide Filter Menu",
      "filterOperator": "Filter Operator",
      "filterConstraint": "Filter Constraint",
      "editRow": "Edit Row",
      "saveEdit": "Save Edit",
      "cancelEdit": "Cancel Edit",
      "listView": "List View",
      "gridView": "Grid View",
      "slide": "Slide",
      "slideNumber": "{slideNumber}",
      "zoomImage": "Zoom Image",
      "zoomIn": "Zoom In",
      "zoomOut": "Zoom Out",
      "rotateRight": "Rotate Right",
      "rotateLeft": "Rotate Left"
    }
  },
  "he": {
    "startsWith": "מתחיל ב",
    "contains": "מכיל",
    "notContains": "אינו מכיל",
    "endsWith": "מסתיים ב",
    "equals": "שווה",
    "notEquals": "אינו שווה",
    "noFilter": "ללא מסנן",
    "filter": "סנן",
    "lt": "פחות מ",
    "lte": "פחות או שווה ל",
    "gt": "יותר מ",
    "gte": "יותר או שווה ל",
    "dateIs": "התאריך הוא",
    "dateIsNot": "התאריך אינו",
    "dateBefore": "התאריך לפני",
    "dateAfter": "התאריך אחרי",
    "custom": "מותאם",
    "clear": "נקה",
    "apply": "החל",
    "matchAll": "התאמה לכל החוקים",
    "matchAny": "התאמה לכל חוק",
    "addRule": "הוסף חוק",
    "removeRule": "הסר חוק",
    "accept": "כן",
    "reject": "לא",
    "choose": "בחר",
    "upload": "העלה",
    "cancel": "בטל",
    "completed": "הושלם",
    "pending": "ממתין",
    "fileSizeTypes": ["בתים", "ק\"ב", "מ\"ב", "ג\"ב", "ט\"ב", "פ\"ב", "א\"ב", "ז\"ב", "י\"ב"],
    "dayNames": ["ראשון", "שני", "שלישי", "רביעי", "חמישי", "שישי", "שבת"],
    "dayNamesShort": ["א", "ב", "ג", "ד", "ה", "ו", "ש"],
    "dayNamesMin": ["א", "ב", "ג", "ד", "ה", "ו", "ש"],
    "monthNames": ["ינואר", "פברואר", "מרץ", "אפריל", "מאי", "יוני", "יולי", "אוגוסט", "ספטמבר", "אוקטובר", "נובמבר", "דצמבר"],
    "monthNamesShort": ["ינו", "פבר", "מרץ", "אפר", "מאי", "יונ", "יול", "אוג", "ספט", "אוק", "נוב", "דצמ"],
    "chooseYear": "בחר שנה",
    "chooseMonth": "בחר חודש",
    "chooseDate": "בחר תאריך",
    "prevDecade": "עשור קודם",
    "nextDecade": "עשור הבא",
    "prevYear": "שנה קודמת",
    "nextYear": "שנה הבאה",
    "prevMonth": "חודש קודם",
    "nextMonth": "חודש הבא",
    "prevHour": "שעה קודמת",
    "nextHour": "שעה הבאה",
    "prevMinute": "דקה קודמת",
    "nextMinute": "דקה הבאה",
    "prevSecond": "שנייה קודמת",
    "nextSecond": "שנייה הבאה",
    "am": "בבוקר",
    "pm": "בערב",
    "today": "היום",
    "now": "עכשיו",
    "weekHeader": "שבוע",
    "firstDayOfWeek": 0,
    "showMonthAfterYear": false,
    "dateFormat": "dd/mm/yy",
    "weak": "חלש",
    "medium": "בינוני",
    "strong": "חזק",
    "passwordPrompt": "הזן סיסמה",
    "emptyFilterMessage": "לא נמצאו תוצאות",
    "searchMessage": "ישנם {0} תוצאות זמינות",
    "selectionMessage": "{0} פריטים נבחרו",
    "emptySelectionMessage": "לא נבחרו פריטים",
    "emptySearchMessage": "לא נמצאו תוצאות",
    "emptyMessage": "אין אפשרויות זמינות",
    "aria": {
      "trueLabel": "נכון",
      "falseLabel": "לא נבחר",
      "nullLabel": "לא נבחר",
      "star": "כוכב 1",
      "stars": "{star} כוכבים",
      "selectAll": "נבחרו כל הפריטים",
      "unselectAll": "הוסרו כל הפריטים",
      "close": "סגור",
      "previous": "קודם",
      "next": "הבא",
      "navigation": "ניווט",
      "scrollTop": "גלול למעלה",
      "moveTop": "העבר לראש",
      "moveUp": "העבר למעלה",
      "moveDown": "העבר למטה",
      "moveBottom": "העבר לתחתית",
      "moveToTarget": "העבר ליעד",
      "moveToSource": "העבר למקור",
      "moveAllToTarget": "העבר הכל ליעד",
      "moveAllToSource": "העבר הכל למקור",
      "pageLabel": "עמוד {page}",
      "firstPageLabel": "עמוד ראשון",
      "lastPageLabel": "עמוד אחרון",
      "nextPageLabel": "עמוד הבא",
      "previousPageLabel": "עמוד קודם",
      "rowsPerPageLabel": "שורות לעמוד",
      "jumpToPageDropdownLabel": "קפיצה לעמוד",
      "jumpToPageInputLabel": "קפיצה לעמוד",
      "selectRow": "שורה נבחרה",
      "unselectRow": "שורה לא נבחרה",
      "expandRow": "הרחב שורה",
      "collapseRow": "כווץ שורה",
      "showFilterMenu": "הצג תפריט מסנן",
      "hideFilterMenu": "הסתר תפריט מסנן",
      "filterOperator": "סנן מפעיל",
      "filterConstraint": "סנן הגבלה",
      "editRow": "ערוך שורה",
      "saveEdit": "שמור עריכה",
      "cancelEdit": "בטל עריכה",
      "listView": "תצוגת רשימה",
      "gridView": "תצוגת רשת",
      "slide": "החלק",
      "slideNumber": "החלק {slideNumber}",
      "zoomImage": "הגדל תמונה",
      "zoomIn": "הגדל",
      "zoomOut": "הקטן",
      "rotateRight": "סובב ימינה",
      "rotateLeft": "סובב שמאלה"
    }
  }
}
