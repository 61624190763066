export default {
  head: {
    title: "Document template",
    meta: [
      { name: "description", content: "Document template" }
    ]
  },

  createTitle: "Create document template",
  updateTitle: "Update document template {0}",

  title: "Title",
  text: "Text",
  active: "Document template is active",

  nameUsed: "Document template name is already in use",
  updateAndPreview: "Update and preview",
}
