export default {
  head: {
    title: "Leads",
    meta: [{ name: "description", content: "Leads description" }]
  },
  actions: {
    edit: "Edit",
    send: "Send",
    sendEmail: "Send email",
    sendMessage: "Send message",
    show: "Show",
    addPatient: "Add patient",
    markValid: "Mark valid",
    markInvalid: "Mark invalid",
    archive: "Archive",
    unarchive: "Unarchive"
  },
  table: {
    columns: {
      id: "ID",
      archived: "Active",
      notValid: "Valid",
      followUp: "Follow up",
      fullName: "Full Name",
      phone: "Phone",
      email: "Email",
      statusName: "Status Name",
      serviceName: "Service Name",
      channelName: "Channel"
    }
  },
  filter: {
    status: "Status",
    all: "All",
    new: "New",
    closed: "Closed",
    created: "Created",
    followUp: "Follow up",
    channel: "Channel",
    archived: "Archived",
    valid: "Valid"
  },
  filterArchived: {
    all: "All",
    active: "Active",
    archive: "Archive",
    // notArchived: "Not Archived",
    // archived: "Archived"
  },
  filterValid: {
    all: "All",
    notValid: "Not Valid",
    valid: "Valid"
  },
  status: {
    NEW: "New",
    CLOSED: "Closed"
  },
  title: "Leads",
  channels: "Channels management",
  searchPlaceholder: "Search by name, phone, email, ID",
  download: "Download",
  newLead: "New Lead"
}
