export default {
  head: {
    title: "גיבוי ושחזור",
    meta: [
      { name: "description", content: "גיבוי ושחזור" }
    ]
  },
  title: "גיבוי ושחזור",
  alertMessage: "תיקיית גיבוי: c://Medforms/Backups", // todo: folder path
  table: {
    columns: {
      id: "#",
      createdAt: "תאריך יצירה",
      fileName: "שם קובץ",
    }
  },
  actions: {
    backup: "גובוי",
    restore: "שחזור",
  },
  popups: {
    restore: {
      title: "שחזור",
      alert: "שחזור יכול לקחת מעל 10 דקות. בבקשה לא לסגור עמוד זה.",
      startRestore: "תחילת שחזור",
    },
    backup: {
      title: "גובוי",
      alert: "גיבוי יכול לקחת מעל 10 דקות. בבקשה לא לסגור עמוד זה.",
      startBackup: "תחילת גיבוי",
    }
  }
}