export default {
  head: {
    title: "מחירון",
    meta: [
      { name: "description", content: "מחירון" }
    ]
  },
  table: {
    columns: {
      serviceName: "תבנית טיפול"
    },
  },
  groups: "קבוצות מחירונים",
  info: "יש להקיש על המחיר כדי לערוך אותו",
  updateByType: "עדכון מחירים",
  update: {
    title: "עדכון מחירים",
    pricelistType: "קבוצת מחירונים",
    percentChange: "אחוז שינוי",
    changePrices: "עדכון מחירים",
    allPricelists: "כל המחירונים",
    info: 'למשל, כדי להעלות מחירים ב,10%- יש לבחור בפעולה "העלאת מחירים" ובאחוז השינוי להזין "10"',
    priceOperation: "פעולה",
    priceOperationType: [
      "לא נבחר",
      "העלאת מחירים",
      "הורדת מחירים"
    ]
  }
}
