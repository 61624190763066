export default {
  head: {
    title: "תזרים",
    meta: [
      { name: "description", content: "תזרים" }
    ]
  },
  title: "תזרים",
  when: "מתי",
  formatMonthDay: "{0} לחודש",
  form: {
    editExpense: "עריכת הוצאה",
    addExpense: "הוספת הוצאה",
    editIncome: "עריכת הכנסה",
    addIncome: "הוספת הכנסה",
    frequency: "סוג",
    amount: "סכום",
    oneTimeDate: "תאריך",
    weekDay: "יום בשבוע",
    monthDay: "יום בחודש",
    evenOrOddBiWeekly: "פעם בשבועיים",
    evenOrOddBiMonthly: "פעם בחודש",
    description: "תיאור"
  },
  buttons: {
    edit: "עריכה",
    newIncome: "הוספת הכנסה",
    newExpense: "הוספת הוצאה"
  },
  filters: {
    balance: "עדכון יתרה",
    display: {
      label: "תצוגה",
      options: {
        _1_Month: "חודש",
        _3_Month: "3 חודשים",
        _6_Month: "6 חודשים",
      }
    }
  },
  incomes: {
    title: "הכנסות",
    table: {
      columns: {
        frequency: "תדירות",
        description: "תיאור",
        amount: "סכום"
      }
    }
  },
  expenses: {
    title: "הוצאות",
    table: {
      columns: {
        frequency: "תדירות",
        description: "תיאור",
        amount: "סכום"
      }
    }
  },
  balances: {
    title: "יתרות",
    table: {
      columns: {
        date: "תאריך",
        description: "תיאור",
        amount: "סכום",
        balance: "יתרה"
      }
    }
  }
}
