import type { DateTimeString } from "@/models/interfaces";

export interface LeadStatusSchema {
  id: number;
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
  deletedAt?: DateTimeString;

  name: string;
  color: string;
}

export const leadStatusSchemaAllFields = `
  id
  createdAt
  updatedAt
  deletedAt
  name
  color
`;
