import GraphqlService from "@/services/GraphqlService";
import moment from "@/plugins/VueMomentPlugin";
import { AutoMapper } from "@/utils";
import { defineStore } from "pinia";
import { ref } from "vue";
import { useServices } from "@/services";
import { StorageFileType } from "@/models/enums";
import type { TaskSchema } from "@/models/schemas";

// https://blog.logrocket.com/complex-vue-3-state-management-pinia/

const subscription = ref<any>(null);

export const useTaskStore = defineStore({
  id: 'task',
  state: () => ({
    currentTasks: [] as TaskSchema[],
    currentTasksCount: 0 as number,
    listOfTasksForNotification: [] as TaskSchema[],
    listOfAlreadyNotifiedTasks: [] as TaskSchema[],
    loadedAt: moment("1900-01-01"),
    firstLoadedAt: moment(),
  }),
  actions: {
    clearCurrentTasks() {
      this.currentTasks = [] as TaskSchema[];
      this.currentTasksCount = 0;
      this.listOfTasksForNotification = [] as TaskSchema[];
      this.listOfAlreadyNotifiedTasks = [] as TaskSchema[];
      this.loadedAt = moment("1900-01-01");
      this.firstLoadedAt = moment();
    },
    removeCurrentTask(id: number) {
      this.currentTasks = this.currentTasks.filter((task: TaskSchema) => task.id !== id);
      this.currentTasksCount = this.currentTasksCount - 1;
      this.listOfTasksForNotification = this.listOfTasksForNotification.filter((task: TaskSchema) => task.id !== id);
    },
    removeNotification(id: number) {
      const task = this.listOfTasksForNotification.find((task) => task.id === id);
      if (task) {
        this.listOfAlreadyNotifiedTasks.push(task);
      }
      this.listOfTasksForNotification = this.listOfTasksForNotification.filter((task: TaskSchema) => task.id !== id);
    },
    async subscribe() {
      let taskStore = this;
      if (!subscription.value) {
        subscription.value = await GraphqlService.subscribeSchema("TaskSchema", {
          onResult(data) {
            taskStore.fetchCurrentTasks(true);
          },
          onError(error) {
            subscription.value = null;
            setTimeout(() => taskStore.subscribe(), 3000);
          }
        });
      }
    },
    async fetchCurrentTasks(force: boolean = false) {
      if (!force && moment().diff(this.loadedAt, 'seconds') < 60) {
        return;
      }

      try {
        let { tasks } = await useServices().task.getCurrentTasks();
        let taskItems = [] as TaskSchema[];
        tasks.items.forEach((task: TaskSchema, index: number) => {
          let newTask = {} as TaskSchema;
          AutoMapper.map(task, newTask, true);
          newTask.patientPhoto = useServices().storage.getLink(StorageFileType.PatientPhoto, task.patientPhoto);
          taskItems.push(newTask);
        });
        this.currentTasks = taskItems;
        this.currentTasksCount = tasks.totalCount;
        this.loadedAt = moment();
      } catch (error) {
        console.log(error);
      }
    },
    async prepareTasksForNotification() {
      let currentTime = moment();

      this.currentTasks.every((task: TaskSchema) => {
        let taskFollowUpTime = moment(task.followUp);
        if (taskFollowUpTime.isBefore(this.firstLoadedAt)) {
          // we use sorted list of tasks, so we can stop iteration
          return false;
        }

        if (taskFollowUpTime.isBefore(currentTime)) {
          if (!this.listOfTasksForNotification.some(item => item.id === task.id && item.followUp === task.followUp) && !this.listOfAlreadyNotifiedTasks.some(item => item.id === task.id && item.followUp === task.followUp)) {
            this.listOfTasksForNotification.push(task);
          }
        }
        return true;
      });
    },
  }
})
