import GraphqlService from "../GraphqlService";
import { laboratorySchemaAllFields, type LaboratorySchema } from "@/models/schemas";
import type { TabulatorParams } from "@/models/interfaces";
import type { SaveLaboratoryInput } from "@/models/api/mutations/LaboratoryModels";

export default class LaboratoryService {
  async getLaboratoriesAll(fields?: string[]) {
    const { data } = await GraphqlService.queryGql<LaboratorySchema[]>({
      method: "laboratoriesAll",
      fields: fields ?? laboratorySchemaAllFields,
      order: [
        { field: "title", value: "ASC" },
        { field: "id", value: "ASC" }
      ]
    });
    return data ?? [];
  }

  async getLaboratories(params: TabulatorParams) {
    return await GraphqlService.getItems<LaboratorySchema>("laboratories", laboratorySchemaAllFields, params);
  }

  async getLaboratory(id: number) {
    return await GraphqlService.getItem<LaboratorySchema>("laboratory", laboratorySchemaAllFields, id);
  }

  async saveLaboratory(input: SaveLaboratoryInput) {
    return await GraphqlService.setItem<LaboratorySchema>("saveLaboratory", "laboratory", laboratorySchemaAllFields, input);
  }

  async deleteLaboratory(id: number) {
    return await GraphqlService.deleteItem("deleteLaboratory", id);
  }
}