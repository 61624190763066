export default {
  title: "תוכניות טיפול",
  editTitle: "צפיה",
  createTitle: "תוכנית טיפול חדשה",
  popup: {
    deleteMessage: "כניסה עם רופא:"
  },
  form: {
    title: "כותרת",
    doctor: "רופא",
    status: "סטטוס",
    submit: "המשך"
  },
  statuses: {
    estimate: "הצעת מחיר",
    active: "פעיל",
    finished: "הסתיים"
  },
  table: {
    columns: {
      createdAt: "תאריך יצירה",
      doctorName: "רופא",
      subTotal: "מחיר",
      discountValue: "הנחה",
      total: "סיכום",
      paid: "שולם",
      toPay: "יתרה לתשלום",
      status: "סטטוס",
      description: "תיאור"
    }
  }
}
