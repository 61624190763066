export default {
  title: "Treatments",
  search: {
    placeholder: "Search",
    tooltip: "Search by: description, service name, service code"
  },
  status: {
    yes: "Yes",
    no: "No",
    partial: "Partial"
  },
  table: {
    columns: {
      createdAt: "Date",
      planId: "Plan",
      startTime: "Date",
      doctorName: "Doctor",
      serviceName: "Service",
      serviceCode: "Service Code",
      description: "Description",
      teeths: "Teeths",
      areas: "Area",
      quantity: "Quantity",
      price: "Price",
      discountValue: "Discount",
      total: "Total",
      paidStatus: "Payed",
      statusTitle: "Status"
    }
  }
}
