import GraphqlService from "../GraphqlService";

export default class ScannersService {
    async startApplicationByLocalPath(localPath: string, patientId: number, isFileNumber: boolean, deleteFirst0InPassportNumber: boolean): Promise<boolean> {
        const { data } = await GraphqlService.mutateGql<boolean>({
            method: "startApplicationByLocalPath",
            variables: [
                { field: "localPath", value: localPath, valueType: "String!" },
                { field: "patientId", value: patientId, valueType: "Int!" },
                { field: "isFileNumber", value: isFileNumber, valueType: "Boolean!" },
                { field: "deleteFirst0InPassportNumber", value: deleteFirst0InPassportNumber, valueType: "Boolean!" },
            ],
        });
        return data ?? false;
    }
}