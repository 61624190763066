import type { Result } from "@/models/interfaces/Result";
import { HttpService } from "../HttpService";
import type { GoogleCalendar } from "@/models/google/GoogleCalendar";

export default class GoogleCalendarApiService {
    public static async GetCalendars(): Promise<GoogleCalendar[]> {
        const encodedParam = encodeURI(`disableRedirect=true`);
        const result = await HttpService.get<Result<GoogleCalendar[]>>(`google/calendars?${encodedParam}`, { useAuth: true });

        if (!result?.success) {
            // eslint-disable-next-line no-console
            console.error(result?.message);
            return [];
        }

        return result?.data ?? [];
    }
}
