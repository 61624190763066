export default {
  head: {
    title: "Backup and restore",
    meta: [
      { name: "description", content: "Backup and restore" }
    ]
  },
  title: "Backup and restore",
  alertMessage: "Backup folder: c://Medforms/Backups", // todo: folder path
  table: {
    columns: {
      id: "#",
      createdAt: "Create date",
      fileName: "File name"
    }
  },
  actions: {
    backup: "Backup",
    restore: "Restore",
  },
  popups: {
    restore: {
      title: "Restore",
      alert: "Restore can take 10 minutes or more. Please do not exit the page after you start the restore process",
      startRestore: "Start restore",
    },
    backup: {
      title: "Backup",
      alert: "Backup can take 10 minutes or more. Please do not exit the page after you start the backup process",
      startBackup: "Start backup",
    }
  }
}