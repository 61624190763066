export default {
  head: {
    title: "Services",
    meta: [
      { name: "description", content: "Services" }
    ]
  },
  table: {
    columns: {
      id: "ID",
      code: "Service code",
      name: "Service name",
      description: "Description",
      categoryName: "Service category",
      materialPrice: "Price",
      active: "Active"
    },
  },
  filter: {
    category: "Filter by category",
    code: "Service code",
    name: "Search by name"
  },
  title: "Services",
  reallyRemove: "Do you really want to remove service {0}?",
  addService: "Add service",
  saved: "Service saved!",
  categories: "Category management",
  suppliers: "Suppliers management",
  edit: "Edit"
}
