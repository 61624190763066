import GraphqlService from "../GraphqlService";
import type { PrescriptionTemplateSchema } from "@/models/schemas";
import type { TabulatorParams } from "@/models/interfaces";
import type { SavePrescriptionTemplateInput } from "@/models/api/mutations/PrescriptionTemplateModels";

const fields = `
  id
  systemName
  drugName
  submission
  dose
  timesADay
  numberOfDays
  note
  instruction
  active
`;

export default class PrescriptionTemplateService {
  async getPrescriptionTemplates(params: TabulatorParams, drugName?: string) {
    return await GraphqlService.getItems<PrescriptionTemplateSchema>("prescriptionTemplates", fields, params,
      { variables: [{ field: "drugName", value: drugName, valueType: "String" }] });
  }

  async deletePrescriptionTemplate(id: number) {
    return await GraphqlService.deleteItem("deletePrescriptionTemplate", id);
  }

  async getPrescriptionTemplate(id: number) {
    return await GraphqlService.getItem<PrescriptionTemplateSchema>("prescriptionTemplate", fields, id);
  }

  async savePrescriptionTemplate(input: SavePrescriptionTemplateInput) {
    return await GraphqlService.setItem<PrescriptionTemplateSchema>("savePrescriptionTemplate", "prescriptionTemplate", fields, input);
  }
}
