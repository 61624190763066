import GraphqlService from "../GraphqlService";
import type { UserSchema } from "@/models/schemas";
import type { TabulatorParams } from "@/models/interfaces";
import type { SaveUserInput } from "@/models/api/mutations/UserModels";

const fields = `
  id
  createdAt
  updatedAt
  deletedAt

  firstName
  lastName
  userName
  email
  phoneNumber
  timezone
  autoLogHours

  active
  saveFilters

  roles

  fullName
  userImage

  rfidTag
  doctorPassport
`;

export default class UserService {
  async getMe() {
    const { data } = await GraphqlService.queryGql<UserSchema>({ method: "me", fields: fields })
    return data;
  }

  async getUsers(params: TabulatorParams) {
    return await GraphqlService.getItems<UserSchema>("users", fields, params);
  }

  async getUsersAll() {
    const { data } = await GraphqlService.queryGql<UserSchema[]>({
      method: "usersAll",
      fields: ["id", "fullName", "userImage"],
      order: [{ field: "fullName", value: "ASC" }]
    });
    return data ?? [];
  }

  async getUsersWithAttendancePermission() {
    const { data } = await GraphqlService.queryGql<UserSchema[]>({
      method: "usersWithAttendancePermission",
      fields: fields,
      order: [{ field: "fullName", value: "ASC" }]
    });
    return data;
  }

  async getUser(id: number) {
    return await GraphqlService.getItem<UserSchema>("user", fields, id);
  }

  async saveUser(input: SaveUserInput) {
    return await GraphqlService.setItem<UserSchema>("saveUser", "user", fields, input);
  }

  async updateUserPassword(id: number, password: string) {
    return await GraphqlService.deleteItem("updateUserPassword", id, {
      variables: [{ field: "password", valueType: "String!", value: password }]
    });
  }

  async deleteUser(id: number) {
    return await GraphqlService.deleteItem("deleteUser", id);
  }
}
