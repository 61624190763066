export default {
  head: {
    title: "דוא\"ל",
    meta: [
      { name: "תיאור", content: "דוא\"ל" }
    ]
  },
  settings: {
    head: "הגדרות דוא\"ל",
    email: "דוא\"ל",
    userName: "שם משתמש",
    password: "סיסמה",
    server: "שרת",
    port: "פורט",
    useSSL: "להשתמש ב SSL",
    connectAndSave: "לשמור",
    checkSmtp: "בדיקת SMTP",
    checkImap: "בדיקת IMAP",
    sendTest: "לשמור ולבדוק חיבור",
    gmail: "Gmail",
    outlook: "Outlook",
    custom: "אחר",
    imap: "IMAP (נכנס)",
    smtp: "SMTP (יוצא)",
    connect: "חיבור חשבון",
    submitedConnection: "חשבון {0} חובר בהצלחה.",
    disconnect: "ניתוק חשבון",
    imapSuccess: "IMAP חובר בהצלחה",
    imapError: "חיבור IMAP לא נצליח",
    smptpError: "חיבור SMTP לא נצליח",
    smtpSuccess: "SMTP חובר בהצלחה",
    smtpTestSubject: "בדיקת SMTP"
  },
  send: {
    head: 'דוא"ל',
    send: "שליחה"
  },
  list: {
    empty: "אין מיילים להצגה",
    settingTooltip: "הגדרות",
    markAsRead: "סמן כנקרא",
    markAsUnread: "סמן כלא נקרא",
    showing: "מציג",
    of: "מתוך"
  },
  "compose": 'מייל חדש',
  "inbox": 'דואר נכנס',
  "starred": 'מסומנים בכוכבית',
  "draft": 'טיוטה',
  "sent_mail": 'נשלח',
  "trash": 'סל מחזור',
  "labels": 'תגיות',
  "download": 'הורדה',
  "reply": 'השב',
  "reply_all": 'השב לכולם',
  "forward": 'העבר',
  "enter_message": 'הקלד הודעה',
  "search": 'חפש לפי שם',
  "today": 'היום',
  "chat_title": "צ'ט (סמס ו whatsapp)",
  "clear_chat": "נקה שיחה",
  "muted": "השתק שיחה",
  emailSent: "האימייל נשלח בהצלחה"
}