import type { DateTimeString } from "@/models/interfaces";
import type { PaymentTerms } from "@/models/enums";

export interface SupplierSchema {
  id: number;
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
  deletedAt?: DateTimeString;

  active: boolean;
  title?: string;
  address?: string;
  city?: string;
  zip?: string;
  country?: string;
  department?: string;
  notes?: string;
  contactPerson?: string;
  phone?: string;
  fax?: string;
  mobile?: string;
  email1?: string;
  email2?: string;
  email3?: string;

  paymentTerms: PaymentTerms;
  taxId: string;
  supplierKey: string;
  bankName: string;
  bankBranch: string;
  bankAccount: string;
}

export const supplierSchemaAllFields = [
  "id",
  "createdAt",
  "updatedAt",
  "deletedAt",
  "active",
  "title",
  "address",
  "city",
  "zip",
  "country",
  "department",
  "notes",
  "contactPerson",
  "phone",
  "fax",
  "mobile",
  "email1",
  "email2",
  "email3",
  "paymentTerms",
  "taxId",
  "supplierKey",
  "bankName",
  "bankBranch",
  "bankAccount"
];
