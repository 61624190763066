export default {
  head: {
    title: "רשימת הוצאות",
    meta: [
      { name: "description", content: "List of expenses description" }
    ]
  },
  title: "הוצאות",
  popup: {
    confirmDelete: "האם למחוק את ההוצאה?"
  },
  filter: {
    search: "חיפוש",
    tooltip: "חיפוש לפי שם ספק, תיאור, מספר מסמך",
    dateFrom: "מתאריך",
    dateTo: "עד תאריך",
    amountFrom: "מסכום",
    amountTo: "עד סכום",
    supplier: "ספק",
    expenseType: "סוג הוצאה",
    documentType: "סוג מסמך",
    expensePaymentType: "סוג תשלום"
  },
  buttons: {
    add: "קליטת הוצאה",
    download: "הורדת Excel",
    edit: "עריכה",
    copy: "העתקת הוצאה",
    downloadFile: "הורדת מסמך"
  },
  documentType: {
    PAYMENT_CONFIRMATION: "אישור תשלום",
    INVOICE: "חשבונית מס",
    INVOICE_RECEIPT: "חשבונית מס קבלה",
    CREDIT_INVOICE: "חשבונית זיכוי",
    RECEIPT: "קבלה",
    DONATION_RECEIPT: "קבלה על תרומה"
  },
  paymentType: {
    CHECK: "צ'ק",
    BANK_TRANSFER: "העברה בנקאית",
    CREDIT_CARD: "כרטיס אשראי",
    PAYPAL: "פייפל",
    CASH: "מזומן",
    PAYMENT_APP: "תשלום דרך אפליקציה",
    OTHER: "אחר"
  },
  form: {
    create: "קליטת הוצאה",
    edit: "עריכת הוצאה",
    supplier: "ספק",
    supplierTax: "ח.פ.",
    documentType: "סוג מסמך",
    documentNumber: "מספר מסמך",
    documentDate: "תאריך המסמך",
    reportingDate: "תקופת הדוח",
    amount: "סכום הוצאה כולל מע\"מ",
    vat: "מע\"מ",
    expensePaymentType: "סוג תשלום",
    expenseType: "סוג הוצאה",
    patient: "מטופל",
    description: "תיאור",
    notes: "הערות",
    documentUpload: "העלאת מסמכים",
    removeFile: "מחיקת מסמך",
    download: "הורדת מסמך",
    submit: "לשמור",
    validationMessage: "ספק חובה",
    docNumExists: "קיים מסמך עם מספר זה לספק"
  },
  table: {
    columns: {
      documentNumber: "מספר",
      documentDate: "תאריך מסמך",
      reportingDate: "חודש",
      supplierName: "ספק",
      patientName: "מטופל",
      description: "תיאור",
      expenseTypeDescription: "סוג הוצאה",
      amount: "סכום",
      vat: "מע\"מ"
    }
  }
}
