export default {
  title: "לשלוח מסמך",
  emailTitle: "כותרת",
  emailAddress: "הכניסו כתובת מייל",
  emailMessage: "הודעה",
  whatsappPhone: "טלפון",
  whatsappMessage: "הודעה",
  validation: {
    requiredEmail: 'דוא"ל חובה.'
  }
}