export default {
  head: {
    title: "קבוצות מחירונים",
    meta: [
      { name: "description", content: "קבוצות מחירונים" }
    ]
  },
  table: {
    columns: {
      id: "ID",
      title: "כותרת",
      active: "פעיל",
      displayOrder: "סדר תצוגה"
    },
  },
  actions: {
    edit: "עריכה",
    add: "הוספת קבוצת מחירונים"
  }
}