import { ref } from "vue";
import { i18n } from "@/locales/i18n";
import { getValidationMessages } from "@formkit/validation";
import type QueryOffsetPage from "@/models/api/queries/QueryOffsetPage";
import type { TabulatorParams } from "@/models/interfaces";
import type { FormKitNode } from '@formkit/core';

export class HtmlHelper {
  public static findFirst<T = HTMLElement>(selector: string, parent: HTMLElement = document.documentElement) {
    return parent.querySelector(selector) as T | null;
  }
  public static findAll<T = HTMLElement>(selector: string, parent: HTMLElement = document.documentElement) {
    return Array.from(parent.querySelectorAll(selector)) as T[];
  }

  public static show(element?: HTMLElement | null) {
    element?.classList.remove('d-none');
  }
  public static hide(element?: HTMLElement | null) {
    element?.classList.add('d-none');
  }

  // https://stackoverflow.com/a/75365320/22548940
  public static on(element: HTMLElement, event: keyof HTMLElementEventMap, handler: (event: any) => any) {
    element.addEventListener(event, handler);
  }
  public static off(element: HTMLElement, event: keyof HTMLElementEventMap, handler: (event: any) => any) {
    element.removeEventListener(event, handler);
  }

  // https://stackoverflow.com/a/55497721/22548940
  public static closest<T = HTMLElement>(element: HTMLElement, selector: string) {
    // https://developer.mozilla.org/en-US/docs/Web/API/Element/closest
    return element.closest(selector) as T | null;
  }

  // https://stackoverflow.com/a/494348/22548940
  public static createElement<T = HTMLElement>(html: string) {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.firstElementChild as T;
  }
  public static createElements<T = HTMLElement>(html: string) {
    const div = document.createElement('div');
    div.innerHTML = html;
    return Array.from(div.children) as T[]; // https://stackoverflow.com/a/7935719/22548940
  }

  // https://dev.to/mandrewcito/vue-js-draggable-div-3mee
  public static draggable(selector: string | HTMLElement, handle?: string) {
    const el = typeof selector === "string" ? document.querySelector(selector) as HTMLElement : selector;

    // fix position on size changes
    const observer = new ResizeObserver((entries) => {
      const rect = el.getBoundingClientRect();
      if (rect.x < 0) el.style.left = "0";
      if (rect.y < 0) el.style.top = "0";
    });
    observer.observe(el);

    // add event to handle
    (handle ? el.querySelector(handle) : el)?.addEventListener("mousedown", mouseDown);

    function mouseDown(event: any) {
      event.preventDefault();
      // add events
      document.addEventListener("mousemove", elementDrag);
      document.addEventListener("mouseup", closeDragElement);
    }
    function elementDrag(event: MouseEvent) {
      event.preventDefault();

      // calculate position
      const position = {
        left: parseInt(el.style.left || "0") + event.movementX,
        top: parseInt(el.style.top || "0") + event.movementY,
      }

      // fix position
      const rect = el.getBoundingClientRect();
      if (rect.x < 0 && event.movementX < 0) position.left -= event.movementX;
      if (rect.y < 0 && event.movementY < 0) position.top -= event.movementY;

      // set position:
      el.style.left = position.left + "px";
      el.style.top = position.top + "px";
    }
    function closeDragElement() {
      document.removeEventListener("mouseup", closeDragElement);
      document.removeEventListener("mousemove", elementDrag);
    }

    function dispose() {
      closeDragElement();
      // https://stackoverflow.com/a/71542987/22548940
      if (el) {
        observer.unobserve(el);
      } else {
        observer.disconnect();
      }
    }

    return {
      el,
      observer,
      dispose
    };
  }

  public static getInnerText(innerHtml: string) {
    const div = document.createElement("div");
    div.innerHTML = innerHtml;
    return div.innerText;
  }
}

export class ArrayHelper {
  public static copy<T>(array: T[]) {
    return array.slice();
  }

  public static remove<T>(array: T[], predicate: (value: T, index: number) => boolean) {
    const index = array.findIndex(predicate);
    if (index >= 0) array.splice(index, 1);
  }

  public static replace<T>(array: T[], predicate: (value: T, index: number) => boolean, value: T) {
    const index = array.findIndex(predicate);
    if (index >= 0) array[index] = value;
    else array.push(value);
  }

  public static sort<T>(array: T[], selector: (value: T) => any, order: "asc" | "desc" = "asc") {
    // The reference to the original array, now sorted
    return array.sort((a, b) => {
      const valueA = selector(a);
      const valueB = selector(b);
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort#sorting_array_of_objects
      if (valueA < valueB) return order === "asc" ? -1 : 1;
      if (valueA > valueB) return order === "asc" ? 1 : -1;
      return 0;
    })
  }

  // https://stackoverflow.com/a/38327540/22548940
  public static groupBy<T, TKey>(array: T[], keyGetter: (value: T) => TKey) {
    const map = new Map<TKey, T[]>();
    array.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return Array.from(map).map(g => ({ key: g[0], items: g[1] }));
  };

  // https://habr.com/ru/articles/435084
  public static async forEachAsync<T>(array: T[], callback: (value: T, index: number, array: T[]) => Promise<void>) {
    for (let i = 0; i < array.length; i++) {
      const item = array[i];
      await callback(item, i, array);
    }
  }

  public static sum<T>(array: T[], selector?: (value: T) => number, initialValue?: number) {
    // https://stackoverflow.com/a/16751601/22548940
    return array.reduce((accumulator, valueA) => accumulator + (selector ? selector(valueA) : valueA as number), initialValue ?? 0);
  }

  public static count<T>(array: T[], predicate: (value: T, index: number) => boolean) {
    let count = 0;
    array.forEach((value, index) => { if (predicate(value, index)) { count++; } });
    return count;
  }

  public static toQueryOffsetPage<T>(array: T[], params: TabulatorParams) {
    const from = (params.page - 1) * params.size;
    const to = from + params.size;
    const items = array.slice(from, to);
    const page: QueryOffsetPage<T> = {
      totalCount: array.length,
      items: items,
      pageInfo: {
        hasNextPage: array.length > to,
        hasPreviousPage: from > 0,
      }
    };
    return page;
  }
}

export class FormKitHelper {
  // https://formkit.com/essentials/validation#extracting-messages
  public static getErrors(node?: FormKitNode) {
    if (!node) return [];
    const validations = getValidationMessages(node);
    let messages: string[] = [];
    validations.forEach((inputMessages) => {
      messages = messages.concat(inputMessages.map((message) => message.value?.toString() ?? ""));
    });
    return messages;
  }
}

export class DataHelper {
  public static get colors() {
    return [
      "#F64047",
      "#FF7027",
      "#FB9C29",
      "#F0B12C",
      "#FFF000",
      "#85CB32",
      "#02C565",
      "#00B984",
      "#00B9A7",
      "#00B7D3",
      "#00A7E6",
      "#0085F2",
      "#556AED",
      "#A559F3",
      "#DA4AEB",
      "#F14698",
      "#FB3B5F"
    ]
  }

  public static enumToOptions<T extends object>(translateName: string, value: T, exclude: string[]) {
    return Object.values(value)
      .filter((x) => !exclude.includes(x))
      .map((x) => ({ label: i18n.global.t(`${translateName}.${x}`), value: x }))
  }
}

export class ReactiveHelper {
  public static isMobile = ref(window.innerWidth <= 1199);
  public static isTablet = ref(window.innerWidth <= 1199 && window.innerWidth > 768);
}

window.addEventListener("resize", () => {
  ReactiveHelper.isMobile.value = window.innerWidth <= 1199;
  ReactiveHelper.isTablet.value = window.innerWidth <= 1199 && window.innerWidth > 768;
});
