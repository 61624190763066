import type { RoleSchema } from "@/models/schemas";
import type { TabulatorParams } from "@/models/interfaces";
import GraphqlService from "../GraphqlService";

const fields = `
  id
  createdAt
  updatedAt
  deletedAt

  name
`;

export default class RoleService {
  async getRoles() {
    const offsetPage = await GraphqlService.getItems<RoleSchema>("roles", fields, {
      size: 100,
      page: 1,
      filter: [],
      sort: [{ field: "id", dir: "asc" }]
    });
    return offsetPage.items;
  }

  async getRole(id: number) {
    return await GraphqlService.getItem<RoleSchema>("role", fields, id);
  }
}
