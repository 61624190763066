export default {
  head: {
    title: "קטגוריות",
    meta: [
      { name: "description", content: "קטגוריות" }
    ]
  },
  table: {
    columns: {
      id: "ID",
      title: "כותרת",
      displayOrder: "סדר הצגה"
    },
  },
  actions: {
    add: "הוספת קטגוריה"
  }
}
