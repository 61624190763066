export default {
  createUser: "Create user",
  updateUser: "Update user: {0}",

  tabChanged: "Some data not saved. Are you shure want to change the tab?",
  tabs: {
    general: "General",
    password: "Password",
    permissions: "Permissions",
    doctorInfo: "Doctor info",
    calendar: "Calendar",
    closedDates: "Closed dates",
    externalCalendars: "External calendars",
    services: "Services",
  },

  roles: {
    doctoradmin: "Main doctor",
    doctor: "Doctor",
    secretary: "Secretary"
  },

  general: {
    firstName: "First name",
    lastName: "Last name",
    userName: "Username",
    email: "Email",
    phoneNumber: "Phone",
    active: "User is active",
    saveFilters: "Save filters",
    password: "Password",
    passwordRepeat: "Repeat password",
    roles: "Role",
    rfidTag: "RFIDTag",
    doctorPassport: "Doctor passport",
    doctorPassportValidation: "Doctor passport must include 9 digits",
    timezone: "Timezone"
  },

  password: {
    message: "Leave empty to keep current password"
  },

  permissions: {
    title: "Permissions",
    roleName: "Role",
    showPatient: "Show patient",
    showPatientOptions: [
      "Allow to see and edit all patients",
      "Allow to see all patients and edit my patients",
      "Allow to see my patients and edit",
      "Allow to see my patients only",
    ],
    showCalendars: "Show calendars",
    showCalendarsOptions: [
      "All",
      "Only my",
      "Choose",
    ],
    showOptions: [
      "Not display",
      "All",
      "Only my",
    ],

    financial: "Financial",
    financialReports: "Financial - Reports",
    incomes: "Financial - Incomes",
    expenses: "Financial - Expenses",
    forecast: "Financial - Forecast",
    deposit: "Financial - Deposit",
    accountingReport: "Financial - Accounting report",

    appointments: "Appointments",
    leads: "Leads",
    laboratoryWorks: "Laboratory works",
    tasks: "Tasks",
    attendance: "Attendance",
    settings: "Settings",

    emails: "Emails",
    recall: "Recall",
    chat: "Chat",
    users: "Users",
    patients: "Patients",
  },

  doctor: {
    display: "Display",
    displayName: "Display name",
    licenseNumber: "License number",
    jobDescription: "Job description",
    profession: "Profession",
    defaultOpeningScreen: "Default opening screen",
    defaultScreenOptions: ["Dashboard", "Patients"],
    doctorImage: "Doctor image",
    signature: "Signature (click to upload)",

    financial: "Financial",
    percentFromTreatments: "Getting percent from treatments",
    percentFromTreatmentsValue: "Percent from treatments",

    calendar: "Calendar",
    haveCalendar: "Doctor have calendar",
    maxDaysToSetupTheEvent: "Max days to setup the event",
    minHoursToSetupEvent: "Min hours to setup event",

    calendarColors: "Calendar colors",
    appointmentItemColor: "Appointment item color",
    appointmentItemFontColor: "Appointment item font color",
    inactiveWorkHoursColor: "Inactive work hours color",
    inactiveWorkHoursFontColor: "Inactive work hours font color",
    tmpCustomerAppointmentColor: "Customer appointment color",
    tmpCustomerAppointmentFontColor: "Customer appointment font color",

    calendarHaveColors: "Calendar have colors",
    calendarGridMinutes: "Calendar grid minutes",
    calendarDayStart: "Calendar day start",
    calendarDayEnd: "Calendar day end",
    calendarAddAppointmentMinutes: "Calendar menu - add appointment minutes",

    error: "Failed to save doctor.",
    saved: "Doctor saved!",
    upload: "Click to upload image"
  },

  calendar: {
    title: "Calendar open hours",
    share: "Share the link to calendar",
    week: {
      sun: "SUN",
      mun: "MUN",
      tue: "TUE",
      wed: "WED",
      thu: "THU",
      fri: "FRI",
      sat: "SAT"
    },
    wrong: "Wrong time",
    error: "Failed to save hours",
    saved: "Saved!",
    reload: "Reload data"
  },

  dates: {
    title: "Calendar closed dates",
    table: {
      columns: {
        fromDate: "From date",
        toDate: "To date",
      },
    },
    create: "Create",
    reallyRemove: "Do you really want to remove dates?",
    error: "Failed to save dates.",
    saved: "Saved!"
  },

  external: {
    title: "Connect Google or Office365 calendar",
    gmail: "Google",
    office365: "Office 365",
    connect: "Connect"
  },

  services: {
    title: "Services",
    table: {
      columns: {
        id: "ID",
        code: "Service code",
        name: "Service name",
        categoryName: "Service category",
        materialPrice: "Price",
        active: "Active"
      }
    }
  }
}
