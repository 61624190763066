export default {
  head: {
    title: "קטגוריית שירות",
    meta: [
      { name: "description", content: "קטגוריית שירות" }
    ]
  },

  title: "שם קטגוריה",
  displayOrder: "הצג סדר",

  createTitle: "עדכן את קטגוריית תבנית טיפול",
  updateTitle: "עדכון קטגוריית תבנית הטיפול",

  nameUsed: "שם קטגוריה כבר בשימוש",
}
