import GraphqlService from "../GraphqlService";
import gql from "graphql-tag";
import moment from "@/plugins/VueMomentPlugin";
import type QueryOffsetPage from "@/models/api/queries/QueryOffsetPage";
import type { FilterTaskInput } from "@/models/api/queries/TaskModels";
import type { SaveTaskInput } from "@/models/api/mutations/TaskModels";
import type { SaveTaskNoteInput } from "@/models/api/mutations/TaskNoteModels";
import type { TabulatorParams } from "@/models/interfaces";
import type { TaskNoteSchema, TaskSchema } from "@/models/schemas";
import type { TaskStatus } from "@/models/enums";
import { useApolloClient } from "@vue/apollo-composable";

const fieldsTask = `
  id
  createdAt
  updatedAt
  deletedAt

  assigneeId
  assigneeName
  assigneeFirstName
  assigneeLastName
  assigneePhoto

  patientId
  patientName
  patientFirstName
  patientLastName
  patientPhoto

  title
  followUp
  priority
  status

  defaultDoctorId
`;

const fieldsTaskNote = `
  id
  createdAt
  updatedAt
  deletedAt
  taskId
  text
`;

export default class TaskService {
  //#region Task
  async getTasks(fields: string[], size: number, page: number, userIds: number[], status?: TaskStatus) {
    const params: TabulatorParams = {
      size: size,
      page: page,
      filter: [{ field: "status", type: "=", value: status }],
      sort: [{ field: "id", dir: "desc" }]
    };
    if (userIds.length) {
      params.filter.push({ field: "defaultDoctorId", type: "in", value: userIds, valueType: "[Int!]" });
    }
    return await GraphqlService.getItems<TaskSchema>("tasks", fields, params);
  }

  async getTasksTabulator(params: TabulatorParams, input?: FilterTaskInput, fields?: string[]) {
    return await GraphqlService.getItems<TaskSchema>("tasks", fields ?? fieldsTask, params, {
      variables: [{ field: "input", value: input, valueType: "FilterTaskInput" }]
    });
  }

  async getTask(id: number, fields?: string[]) {
    return await GraphqlService.getItem<TaskSchema>("task", fields ?? fieldsTask, id);
  }

  async getNumberOfUnclosedTasks(userIds: number[]) {
    const userIdStr = userIds.length ? `defaultDoctorId: {in:${JSON.stringify(userIds)}}` : '';

    const { client } = useApolloClient();
    const { data } = await client.query<{ tasks: QueryOffsetPage<TaskSchema> }>({
      query: gql`
        query numberOfUnclosedTasks {
          tasks(where: {
            ${userIdStr}
            status: {neq:CLOSED}
          }) {
            totalCount
          }
        }
      `,
      variables: {}
    });
    return data.tasks.totalCount;
  }

  async getCurrentTasks() {
    const { client } = useApolloClient();
    let today = moment().format('YYYY-MM-DDT23:59:59');
    const { data } = await client.query<{ tasks: QueryOffsetPage<TaskSchema> }>({
      query: gql`
        query currentTasks {
          tasks(
            take: 100
            where: {
              status: {neq:CLOSED}
              followUp: {lte:"${today}"}
            }
            order: { followUp: DESC }
          )
          {
            totalCount
            items {
              id
              followUp
              title
              status
              patientId
              patientName
              patientFirstName
              patientLastName
              patientPhoto
              patientPhone1
              patientPhone2
              patientPhone3
              __typename
            }
          }
        }
      `,
      variables: {}
    });

    return data;
  }

  async saveTask(input: SaveTaskInput, fields: string[]) {
    if (!input.id) {
      input.id = 0;
    }
    if (!input.note) {
      input.note = '';
    }
    return await GraphqlService.setItem<TaskSchema>("saveTask", "task", fields, input);
  }

  async openTask(id: number) {
    return await GraphqlService.deleteItem("openTask", id);
  }

  async closeTask(id: number) {
    return await GraphqlService.deleteItem("closeTask", id);
  }
  //#endregion

  //#region TaskNote
  async getTaskNotes(params: TabulatorParams, taskId?: number) {
    if (taskId) {
      params.filter.push({ field: "taskId", type: "=", value: taskId });
    }
    return await GraphqlService.getItems<TaskNoteSchema>("taskNotes", fieldsTaskNote, params);
  }

  async getTaskNote(id: number, fields?: string[]) {
    return await GraphqlService.getItem<TaskNoteSchema>("taskNote", fields ?? fieldsTaskNote, id);
  }

  async saveTaskNote(input: SaveTaskNoteInput, fields: string[]) {
    return await GraphqlService.setItem<TaskNoteSchema>("saveTaskNote", "taskNote", fields, input);
  }
  //#endregion
}
