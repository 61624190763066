import type { ForecastType, Frequency } from "@/models/enums";
import type { DateTimeString } from "@/models/interfaces";

export interface ForecastBalance {
  forecastId: number;
  forecastType: ForecastType;
  frequency: Frequency;
  date: DateTimeString;
  description: string;
  amount: number;
  balance: number;
  readonly groupingDate: number;
}

export const forecastBalanceAllFields = [
  "forecastId",
  "forecastType",
  "frequency",
  "date",
  "description",
  "amount",
  "balance",
  "groupingDate"
];
