export default {
  title: "עבודות מעבדה",
  table: {
    columns: {
      id: "#",
      createdAt: "נוצרה ב",
      followUp: "תאריך מעקב",
      title: "כותרת",
      patientName: "מטופל",
      laboratoryName: "מעבדה",
      status: "סטטוס",
    }
  }
}
