export default {
  docs: "מסמכים קשורים",
  view: "צפיה",
  empty: "למסמך זה אין מסמכים קשורים",
  table: {
    columns: {
      financialNumber: "מס' המסמך",
      documentDate: "תאריך",
      incomeType: "סוג המסמך",
      payAmount: "סכום"
    }
  }
}