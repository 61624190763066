import type { App, Plugin } from "vue";

export const VitePlugin: Plugin = {
  install(app: App) {
    // https://stackoverflow.com/a/77970818/22548940
    // https://vite.dev/guide/build.html#load-error-handling
    // https://www.vidbeo.com/blog/reload-nuxt-spa-on-missing-chunk-error
    function preloadErrorHandler(event: Event) {
      const attempts = 3;
      const attempt = parseInt(sessionStorage.getItem("preloadErrorAttempt") ?? "0");
      if (attempt < attempts) {
        // try 3 times
        console.log("Reloading page to load updated modules...");
        sessionStorage.setItem("preloadErrorAttempt", (attempt + 1).toString());
        window.location.reload();
      } else {
        // more than 3 times - with interval
        console.log("Reloading page to load updated modules after 3 seconds...");
        setTimeout(() => {
          sessionStorage.setItem("preloadErrorAttempt", (attempt + 1).toString());
          window.location.reload();
        }, 3000);
      }
    }

    window.addEventListener('vite:preloadError', preloadErrorHandler);
  }
}
