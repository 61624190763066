export default {
  head: {
    title: "Vat report",
    meta: [
      { name: "description", content: "Vat report description" }
    ]
  },
  title: "Vat report",
  alertText: "This report allows the calculation of vat to be paid based on your business cycle and the expenses you created in the system.",
  alertLink: "To pay vat on the tax Authority's website",
  dateFrom: "From date",
  dateTo: "To date",
  vatPayment: "Vat payment",
  transactions: "Transactions",
  debitTransactions: "Debit transactions (without vat)",
  exemptTransactions: "Exempt or zero rated transactions",
  vatTransactions: "Vat transactions",
  inputs: "Inputs",
  knownExpensesInput: "Known expenses (without vat)",
  taxInput: "Input tax on equipment and fixed assets",
  otherTaxInput: "Other input tax",
  totalVatPaid: "Total vat to be paid",
  buttons: {
    report: "Generate report"
  }
}
