export default {
  head: {
    title: "ריקול",
    meta: [
      { name: "description", content: "ריקול" }
    ]
  },
  popup: {
    title: "שליחת ריקול",
    linkMessage: "לינק לקביעת תור יוצג בתחתית ההודעה",
    pasteAppointmentText: 'לחצו על הלחצן הימני בעכבר על השעה הרצויה ואז לחצו "הדבק" כדי להוסיף את הטיפול ביומן.',
    addAppointmentText: "לחצו על הלחצן השמאלי בעכבר, על השעה הרצויה כדי לקבוע תור",
    successMessage: "הודעות ריקול נשלחו בהצלחה",
  },
  actions: {
    send: "שליחת הודעה",
    view: "צפייה בתורים",
    copy: "העתקה"
  },
  table: {
    columns: {
      recallDate: "תאריך ריקול",
      fullName: "שם מלא",
      phone: "טלפון",
      email: "דוא״ל",
      appointmentTime: "תור",
      serviceName: "שירות אחרון",
      lastServiceDate: "תאריך שירות אחרון",
      newAppointmentDate: "תאריך תור עתידי",
      history: "הודעות שנשלחו",
      send: "לשלוח הודעה",
      view: "לצפות בתורים",
      copy: "עותק",
      archive: "ארכיון",
    }
  },
  filter: {
    recallDate: "תאריך ריקול",
    lastserviceDate: "תאריך השירות האחרון",
    all: "הכל",
    doctors: "רופאים",
    services: "שירותים",
    allServices: "כל השירותים",
    hasAppointment: "קיים תור עתידי",
    pricelists: "מחירון",
    allPricelists: "כל המחירונים",
    priceFrom: "ממחיר שירות אחרון",
    priceTo: "עד מחיר שירות אחרון",
    archived: "ארכיון",
    messageSent: "סטטוס שליחת הודעה",
    archivedOptions: {
      all: "הכל",
      archived: "ארכיון",
      notArchived: "פעיל",
    },
    messageSentOptions: {
      all: "הכל",
      messageSent: "הודעה נשלחה",
      messageNotSent: "הודעה לא נשלחה",
    }
  }
}
