export default {
  head: {
    title: "שליחת תזכורות",
    meta: [
      { name: "description", content: "שליחת תזכורות" }
    ]
  },
  message: "הודעה",
  date: "תאריך",
  searchPlaceholder: "חפש לפי שם",
  filter: {
    appointmentTime: "תור",
    period: "תקופה",
    status: "סטטוס",
    statuses: {
      all: "הכל",
      accepted: "אישרו",
      notAccepted: "לא אישרו",
    }
  },
  buttons: {
    send: "שליחת תזכורת",
    approve: "אישור הגעה",
    message: "הודעות",
    cancel: "ביטול תור",
    view: "צפיה בתור"
  },
  popup: {
    title: "שליחת תזכורת",
    popupSuccessText: "תזכורות נשלחו לכל המטופלים הרלוונטיים. יתכן שיידרשו מספר דקות עד שיוכלו לראות אותן.",
    sentToSelected: "שליחה ל {0} המטופלים הנבחרים",
    sendToAll: "שליחה לכל {0} המטופלים",
    templateTypeDefaultReminder: "שליחת תזכורת ברירת מחדל",
    templateTypeDefaultRecall: "שליחת הודעת ריקול בתבנית ברירת מחדל",
    templateTypeCustomTemplateReminder: "בחירת תבנית מהרשימה",
    templateTypeCustomTemplateRecall: "בחירת תבנית קיימת",
    templateTypeCustomMessage: "שליחת הודעה מותאמת אישית",
    templateType: "סוג תבנית",
    message: "הודעה",
    messageValidation: "הודעה הינה חובה.",
    subject: "כותרת",
    subjectValidation: "כותרת הינה חובה.",
    reminderLinkType: "לינק",
    reminderLinkTypeNone: "לא לשלוח קישור",
    reminderLinkTypeApproval: "שליחת קישור לאישור הגעה",
    reminderLinkTypeChangeApparent: "שליחת קישור לשינוי/עדכון תור",
    submit: "שליחה"
  },
  table: {
    columns: {
      fullName: "שם מלא",
      defaultDoctorName: "רופא",
      actions: "פעולות",
      appointmentTime: "תור",
      serviceName: "שירות",
      approved: "אישרו",
      history: "היסטוריה"
    }
  },
  view: {
    doctors: "רופא",
    appointment: "תור",
    allDoctors: "כל הרופאים",
  }
}
