export default {
  title: "טפסים",
  table: {
    columns: {
      id: "#",
      createdAt: "תאריך יצירה",
      status: "סטטוס",
      doctorName: "רופא",
      templateTitle: "סוג",
      description: "הערות",
    }
  },
  edit: {
    title: "טופס",
    templateId: "סוג טופס",
    description: "תוכן",
    saveAndSend: "לשמור ולשלוח"
  },
  popup: {
    title: "שליחת טופס למטופל",
    subject: "כותרת",
    message: "הודעה",
    linkMessage: "קישור לטופס יצורף אוטומטית בתחתית ההודעה.",
    notification: "הודעה עם טופס נשלחה",
    validation: {
      subject: "כותרת הינה חובה.",
      message: "הודעה הינה חובה.",
    }
  }
}
