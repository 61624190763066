export default {
  head: {
    title: "Recalls",
    meta: [
      { name: "description", content: "Recalls" }
    ]
  },
  popup: {
    title: "Send recall",
    linkMessage: "A link to schedule appointment will be displayed at the end of the message",
    pasteAppointmentText: "Please, click right mouse button to call context menu and paste appointment",
    addAppointmentText: "Please, click left mouse button to add appointment",
    successMessage: "Message were sent successfully",
  },
  actions: {
    send: "Send",
    view: "View",
    copy: "Copy"
  },
  table: {
    columns: {
      recallDate: "Recall date",
      fullName: "Full name",
      phone: "Phone",
      email: "Email",
      appointmentTime: "Appointment",
      serviceName: "Service",
      lastServiceDate: "Last service",
      newAppointmentDate: "Appointment",
      history: "Sent message",
      send: "Send",
      view: "View",
      copy: "Copy",
      archive: "Archive",
    }
  },
  filter: {
    recallDate: "Recall date",
    lastserviceDate: "Last service date",
    all: "All",
    doctors: "Doctors",
    services: "Services",
    allServices: "All services",
    hasAppointment: "Has appointment",
    pricelists: "Pricelist",
    allPricelists: "All Pricelists",
    priceFrom: "Price from",
    priceTo: "Price to",
    archived: "Archived",
    messageSent: "Message sent",
    archivedOptions: {
      all: "All",
      notArchived: "Not archived",
      archived: "Archived",
    },
    messageSentOptions: {
      all: "All",
      messageSent: "Message sent",
      messageNotSent: "Message not sent",
    }
  }
}
