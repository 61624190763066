export default {
  title: "משימות פתוחות",
  table: {
    columns: {
      id: "#",
      createdAt: "תאריך יצירה",
      followUp: "תעריך מעקב",
      assigneeName: "משוייך ל",
      title: "משימה",
    }
  }
}
