﻿export default {
  head: {
    title: "מעבדות",
    meta: [
      { name: "description", content: "מעבדות" }
    ]
  },
  title: "מעבדות",
  table: {
    columns: {
      id: "#",
      active: "פעיל",
      title: "שם חברה",
      address: "כתובת ורחוב",
      city: "עיר",
      zip: "מיקוד",
      country: "מדינה",
      department: "מחלקה",
      notes: "הערות",
      contactPerson: "איש קשר",
      phone: "טלפון",
      fax: "פקס",
      mobile: "נייד",
      email1: "דוא\"ל 1",
      email2: "דוא\"ל 2",
      email3: "דוא\"ל 3",
    },
  },
  add: "הוספת מעבדה",
  actions: {
    edit: "עריכה"
  }
}
