export default {
  head: {
    title: "הפקדת צ'קים",
    meta: [
      { name: "description", content: "הפקדת צ'קים" }
    ]
  },
  example: "example",
  popup: {
    check: "האם לסמן צ'ק זה כמופקד?"
  },
  table: {
    columns: {
      chequeDate: "תאריך צ'ק",
      client: "מטופל",
      chequeBankName: "בנק",
      chequeBankBranch: "סניף",
      chequeBankAccount: "חשבון",
      chequeNumber: "מספר צ'ק",
      amount: "סכום"
    }
  }
}
