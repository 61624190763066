export default {
  title: "Family",
  message: ["This user is assignee to the family of", "To disconnect,", "click here"],
  table: {
    columns: {
      id: "ID",
      createdAt: "Create date",
      type: "Type",
      familyFirstName: "First name",
      familyLastName: "Last name",
      note: "Note",
    }
  },
  edit: {
    title: "Family member",
    type: "Type",
    patient: "Patient",
    note: "Note",
    types: {
      NONE: "None",
      FATHER: "Father",
      MOTHER: "Mother",
      BROTHER_OR_SISTER: "Brother/Sister",
      PARTNER: "Partner",
      CHILD: "Child",
      GRANDFATHER: "Grandfather",
      GRANDMOTHER: "Grandmother",
      OTHER: "Other"
    },
  }
}
