import { MessageProvider, MessageTemplateType } from "@/models/enums";

export interface MessageTemplateSchema {
  id: number;
  type: MessageTemplateType;
  platform: MessageProvider;
  systemName: string;
  subject: string | null;
  text: string | null;
  active: boolean;
  attachmentIds: number[];
}

export const messageTemplateSchemaAllFields = `
  id
  type
  platform
  systemName
  subject
  text
  active
  attachmentIds
`;