export default {
  title: "Treatment plans",
  editTitle: "Treatment plan edit",
  createTitle: "Treatment plan create",
  popup: {
    deleteMessage: "Entry with doctor:"
  },
  form: {
    title: "Title",
    doctor: "Doctor",
    status: "Status",
    submit: "Continue"
  },
  statuses: {
    estimate: "Estimate",
    active: "Active",
    finished: "Finished"
  },
  table: {
    columns: {
      createdAt: "Create date",
      doctorName: "Doctor",
      subTotal: "SubTotal",
      discountValue: "Discount",
      total: "Total",
      paid: "Payed",
      toPay: "To pay",
      status: "Status",
      description: "Description"
    }
  }
}
