import { defineStore } from "pinia";
import type { PopupOptions } from "@/models/interfaces";

export const usePopupStore = defineStore({
  id: "popup",
  state: () => ({
    options: null as PopupOptions | null,
    isVisible: false
  }),
  actions: {
    showPopup(options: PopupOptions) {
      this.options = options;
      this.isVisible = true;
    },
    hidePopup() {
      this.isVisible = false;
    },
  },
})