import type { DateTimeString } from "@/models/interfaces";
import type { LaboratoryWorkStatus } from "@/models/enums";

export interface LaboratoryWorkSchema {
  id: number;
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
  deletedAt?: DateTimeString;

  followUp: DateTimeString;
  pickupDate?: DateTimeString;
  deliveryDate?: DateTimeString;

  status: LaboratoryWorkStatus;
  title: string;
  techniciansName: string;
  fileNumber: number;

  teethQuantity: number;
  teethData: string;
  teethType: string;

  doctorId: number;

  laboratoryId: number;
  laboratoryName: string;
  laboratoryPhone: string;

  patientId: number;
  patientName: string;
  patientFirstName: string;
  patientLastName: string;
  patientPhoto: string;
  patientPhone1: string;
  patientPhone2: string;
  patientPhone3: string;

  __typename: string;
}

export const laboratoryWorkSchemaAllFields = [
  "id",
  "createdAt",
  "updatedAt",
  "deletedAt",

  "followUp",
  "pickupDate",
  "deliveryDate",

  "status",
  "title",
  "techniciansName",
  "fileNumber",

  "teethQuantity",
  "teethData",
  "teethType",

  "doctorId",

  "laboratoryId",
  "laboratoryName",
  "laboratoryPhone",

  "patientId",
  "patientName",
  "patientFirstName",
  "patientLastName",
  "patientPhoto",
  "patientPhone1",
  "patientPhone2",
  "patientPhone3",

  // , __typename
];
