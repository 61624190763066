export default {
  payUntil: "לתשלום עד",
  documentDate: "תאריך המסמך",
  createdDate: "תאריך הפקת מסמך",
  createdBy: "מפיק המסמך",
  draft: "טיוטה",
  customer: "פרטי הלקוח",
  total: "סכום כולל",
  dealerNumber: "מספר עוסק",
  phone: "טל’",
  receiptDetails: "פירוט תקבולים",
  receiptTotal: "סך התקבולים",
  description: "תיאור המסמך",
  buttons: {
    header: "פעולות",
    addRelatedDoc: "הפקת מסמך חדש מתוך מסמך זה",
    close: "סימון המסמך כמטופל",
    reopen: "פתיחה מחודשת של המסמך",
    download: "הורדת המסמך",
    copy: "שכפול",
    linkedDocs: "מסמכים מקושרים",
    cancel: "ביטול המסמך",
    delete: "מחיקת טיוטה",
    edit: "עריכת טיוטה"
  },
  invoiceLineTable: {
    quantity: "כמות",
    detail: "פירוט שירותים",
    amount: "מחיר",
    total: "סהייכ",
    discount: "הנחה",
    vat: 'מע"מ 17.00%',
    payment: "סהייכ לתשלום",
    rounding: "עיגול סכום"
  },
  receiptLineTables: {
    bankTransfer: {
      date: "מועד תשלום",
      bankName: "בנק",
      bankBranch: "סניף",
      bankAccount: "חשבון",
      amount: "סכום"
    },
    cheque: {
      date: "מועד תשלום",
      bankName: "בנק",
      bankBranch: "סניף",
      bankAccount: "חשבון",
      chequeNumber: "מספר צ'ק",
      amount: "סכום"
    },
    creditCard: {
      date: "מועד תשלום",
      number4Digits: "4 ספרות אחרונות",
      brand: "סוג כרטיס",
      dealType: "סוג עסקה",
      payNumber: "מספר תשלומים",
      amount: "סכום"
    },
    cash: {
      date: "מועד תשלום",
      amount: "סכום"
    },
    wht: {
      date: "מועד תשלום",
      amount: "סכום"
    }
  }
}