export default class ConfigService {
  public static apiUrl = new URL(import.meta.env.VITE_API_URL as string || `${location.origin}/api/`);
  public static appVersion = import.meta.env.VITE_APP_VERSION as string || "1.0.0.0";
  public static publicUrl = new URL(import.meta.env.VITE_PUBLIC_URL);

  public static api(path?: string, query?: string) {
    return `${this.apiUrl.href}${path?.replace(/\/$/, '') ?? ""}${query ?? ""}`
  }

  public static apiWs(path?: string, query?: string) {
    return Object.assign(new URL(this.apiUrl), <URL>{
      protocol: this.apiUrl.protocol === "http:" ? "ws" : "wss",
      pathname: `${this.apiUrl.pathname}${path?.replace(/\/$/, '') ?? ""}`,
      search: query ?? ""
    }).toString();
  }

  public static get graphql() {
    return `${this.apiUrl.href}graphql`;
  }

  public static get graphqlWss() {
    return Object.assign(new URL(this.apiUrl), {
      protocol: this.apiUrl.protocol === "http:" ? "ws" : "wss",
      pathname: `${this.apiUrl.pathname}graphql`
    }).toString();
  }
}
