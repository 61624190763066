import type { DateTimeString } from "@/models/interfaces";
import type { Classification } from "@/models/enums";

export interface ExpenseTypeSchema {
  id: number;
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
  deletedAt: DateTimeString | null;
  classification: Classification;
  description: string;
  allowedForNds: number;
  allowedForExpenses: number;
  externalSortCode: string;
  externalAccountKey: string;
}

export const expenseTypeSchemaAllFields = [
  "id",
  "createdAt",
  "updatedAt",
  "deletedAt",
  "classification",
  "description",
  "allowedForNds",
  "allowedForExpenses",
  "externalSortCode",
  "externalAccountKey"
];
