export default {
  head: {
    title: "Channels",
    meta: [{ name: "description", content: "Channels description" }]
  },
  table: {
    columns: {
      id: "ID",
      name: "Name",
      active: "Active",
    }
  },
  actions: {
    download: "Download",
    newChannel: "New channel",
    edit: "Edit"
  }
}
