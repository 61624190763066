import GraphqlService from "../GraphqlService";
import { forecastSchemaAllFields, type ForecastSchema } from "@/models/schemas";
import { forecastBalanceAllFields, type ForecastBalance } from "@/models/api/queries/ForecastModels";
import type { TabulatorParams } from "@/models/interfaces";
import type { ForecastBalanceSetting, SaveForecastInput } from "@/models/api/mutations/ForecastModels";

export default class ForecastService {
  async getForecastBalances(params: TabulatorParams, months: number, balance: number) {
    return await GraphqlService.getItems<ForecastBalance>("forecastBalances", forecastBalanceAllFields, params, {
      variables: [
        { field: "months", value: months, valueType: "Int!" },
        { field: "balance", value: balance, valueType: "Decimal!" }
      ]
    });
  }

  async getForecastBalanceSetting() {
    const { data } = await GraphqlService.queryGql<ForecastBalanceSetting>({ method: "forecastBalanceSetting", fields: "balance date" });
    return data;
  }

  async getForecastExpenses(params: TabulatorParams) {
    return await GraphqlService.getItems<ForecastSchema>("forecastExpenses", forecastSchemaAllFields, params);
  }

  async getForecastIncomes(params: TabulatorParams) {
    return await GraphqlService.getItems<ForecastSchema>("forecastIncomes", forecastSchemaAllFields, params);
  }

  async getForecast(id: number) {
    return await GraphqlService.getItem<ForecastSchema>("forecast", forecastSchemaAllFields, id);
  }

  async deleteForecast(id: number) {
    return await GraphqlService.deleteItem("deleteForecast", id);
  }

  async saveForecast(input: SaveForecastInput) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "saveForecast",
      variables: [{ field: "input", value: input, valueType: "SaveForecastInput" }],
    });
    return data ?? false;
  }

  async saveForecastBalance(balance: number) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "saveForecastBalance",
      variables: [{ field: "balance", value: balance, valueType: "Decimal!" }]
    });
    return data ?? false;
  }
}
