import type { DateTimeString } from "@/models/interfaces";

export interface ServiceCategorySchema {
  id: number;
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
  deletedAt?: DateTimeString;

  title: string;
  displayOrder: number;
}

export const serviceCategorySchemaAllFields = `
  id
  createdAt
  updatedAt
  deletedAt

  title
  displayOrder
`;