export default {
  head: {
    title: "Email",
    meta: [
      { name: "description", content: "Email" }
    ]
  },
  settings: {
    head: "Email settings",
    email: "Email",
    userName: "User name",
    password: "Password",
    server: "Server",
    port: "Port",
    useSSL: "Use SSL connect",
    connectAndSave: "Connect and save",
    checkSmtp: "Check SMTP",
    checkImap: "Check IMAP",
    sendTest: "Send test and save",
    gmail: "Gmail",
    outlook: "Outlook",
    custom: "Custom",
    imap: "IMAP (incoming)",
    smtp: "SMTP (outgoing)",
    connect: "Connect",
    submitedConnection: "{0} account successfully connected.",
    disconnect: "Disconnect",
    imapSuccess: "Successful IMAP connection",
    imapError: "Something went wrong with IMAP connection",
    smptpError: "Something went wrong with SMTP connection",
    smtpSuccess: "Successful SMTP connection",
    smtpTestSubject: "Testing SMTP"
  },
  send: {
    head: "Email send",
    send: "Send",
  },
  list: {
    empty: "No emails to display",
    settingTooltip: "Settings",
    markAsRead: "Mark as read",
    markAsUnread: "Mark as unread",
    showing: "Showing",
    of: "of"
  },
  "compose": 'Compose',
  "inbox": 'Inbox',
  "starred": 'Starred',
  "draft": 'Draft',
  "sent_mail": 'Sent Mail',
  "trash": 'Trash',
  "labels": 'Labels',
  "download": 'Download',
  "reply": 'Reply',
  "reply_all": 'Reply All',
  "forward": 'Forward',
  "enter_message": 'Enter Message...',
  "search": 'Search...',
  "today": 'Today',
  "chat_title": "Chat",
  "clear_chat": "Clear chat",
  "muted": "Muted",
  emailSent: "Email was sent successfully"
}