import "@formkit/addons/css/floatingLabels";
import { plugin, defaultConfig, FormKitMessages, createInput, type DefaultConfigOptions } from "@formkit/vue";
import { en, he } from "@formkit/i18n"
import { i18n } from "@/locales/i18n";
import { createFloatingLabelsPlugin, createAutoHeightTextareaPlugin } from "@formkit/addons";
import { TreatmentDenture } from "@/models/enums";
import type { App, Plugin } from "vue";
import type { FormKitInputs } from "@formkit/inputs"
import type { TimeModel } from "@vuepic/vue-datepicker";
import type { Option, OptionImg } from "@/models/interfaces";

// import { primeInputs } from "@sfxcode/formkit-primevue";
import CkHtmlEditor from "@/components/formkit/CkHtmlEditor.vue";
import CkMessageEditor from "@/components/formkit/CkMessageEditor.vue";
import PrimeAutoComplete from "@/components/formkit/PrimeAutoComplete.vue";
import PrimeAutoComplete2 from "@/components/formkit/PrimeAutoComplete2.vue";
import PrimeAutoComplete2Multi from "@/components/formkit/PrimeAutoComplete2Multi.vue";
import PrimeColorPicker from "@/components/formkit/PrimeColorPicker.vue";
import PrimeDatepicker from "@/components/formkit/PrimeDatepicker.vue";
import PrimeMultiSelect from "@/components/formkit/PrimeMultiSelect.vue";
import PrimePhoneMask from "@/components/formkit/PrimePhoneMask.vue";
import PrimeSignaturePad from "@/components/formkit/PrimeSignaturePad.vue";
import PrimeTreatmentAreas from "@/components/formkit/PrimeTreatmentAreas.vue";
import PrimeTreatmentTeeth from "@/components/formkit/PrimeTreatmentTeeth.vue";

const config: DefaultConfigOptions = {
  locales: { en, he },
  locale: i18n.global.locale.value,
  plugins: [
    // https://formkit.com/plugins/floating-labels
    createFloatingLabelsPlugin(),
    // https://formkit.com/plugins/auto-height-textarea
    createAutoHeightTextareaPlugin(),
  ],
  // https://github.com/sfxcode/formkit-primevue
  // inputs: primeInputs as any
  // https://formkit.com/guides/create-a-custom-input https://github.com/sfxcode/formkit-primevue/blob/main/src/definitions/index.ts
  inputs: {
    ckHtmlEditor: createInput(CkHtmlEditor, { props: [] }),
    ckMessageEditor: createInput(CkMessageEditor, { props: [] }),
    primeAutoComplete: createInput(PrimeAutoComplete, { props: [] }),
    primeAutoComplete2: createInput(PrimeAutoComplete2, { props: ['pt', 'ptOptions'] }),
    primeAutoComplete2Multi: createInput(PrimeAutoComplete2Multi, { props: ['pt', 'ptOptions'] }),
    primeColorPicker: createInput(PrimeColorPicker, { props: [] }),
    primeDatepicker: createInput(PrimeDatepicker, { props: [] }),
    primeMultiSelect: createInput(PrimeMultiSelect, { props: [] }),
    primePhoneMask: createInput(PrimePhoneMask, { props: [] }),
    primeSignaturePad: createInput(PrimeSignaturePad, { props: [] }),
    primeTreatmentAreas: createInput(PrimeTreatmentAreas, { props: [] }),
    primeTreatmentTeeth: createInput(PrimeTreatmentTeeth, { props: [] }),
  }
}

export const FormKitPlugin: Plugin = {
  install(app: App) {
    // https://formkit.com/getting-started/installation
    app.use(plugin, defaultConfig(config))
    // https://formkit.com/inputs/form#validation-and-error-summary
    app.component("FormKitMessages", FormKitMessages); // https://learnvue.co/articles/vue-3-global-component
  }
}

// https://formkit.com/essentials/custom-inputs#typescript-support
declare module '@formkit/inputs' {
  interface FormKitInputProps<Props extends FormKitInputs<Props>> {
    ckHtmlEditor: {
      type: 'ckHtmlEditor';
      value?: any;
    };
    ckMessageEditor: {
      type: 'ckMessageEditor';
      value?: any;
    };
    primeAutoComplete: {
      type: 'primeAutoComplete';
      value?: any;
      options: OptionImg[]
    };
    primeAutoComplete2: {
      type: 'primeAutoComplete2';
      value: Option;
      options: Option[]
    };
    primeAutoComplete2Multi: {
      type: 'primeAutoComplete2Multi';
      value?: Option[];
      options: Option[]
    };
    primeColorPicker: {
      type: 'primeColorPicker';
      value?: any;
    };
    primeDatepicker: {
      type: 'primeDatepicker';
      value?: any;
      mode?: "date" | "datetime" | "range" | "month" | "time" | "week";
      autoApply?: boolean;
      clearable?: boolean;
      classInput?: string;
      placeholder?: string;
      readonly?: boolean;
      disabled?: boolean;
      debug?: boolean;
      minDate?: Date;
      maxDate?: Date;
      minTime?: TimeModel;
      maxTime?: TimeModel;
      weekStart?: number | string;
    };
    primeMultiSelect: {
      type: 'primeMultiSelect';
      value?: any[] | null;
      options: Option[]
    };
    primePhoneMask: {
      type: 'primePhoneMask';
      value?: any;
    };
    primeSignaturePad: {
      type: 'primeSignaturePad';
      value?: any;
    };
    primeTreatmentAreas: {
      type: 'primeTreatmentAreas';
      value: string | null;
    };
    primeTreatmentTeeth: {
      type: 'primeTreatmentTeeth';
      value: string | null;
      denture?: TreatmentDenture;
    };
  }
}
