export default {
  title: "Files",
  createNew: "Create New",
  folder: "Folder",
  file: "File",
  cameraPhoto: "Photo from camera",
  rootFolder: "Root folder",
  search: "Search...",
  open: "Open",
  rename: "Rename",
  remove: "Remove",
  table: {
    columns: {
      name: "Name",
      dateModified: "Date modified",
      size: "Size",
    }
  },
  writeFolderName: "Please write folder name",
  errorFolderName: "Invalid folder name",
  writeNewName: "Please write new name",
  errorNewName: "Invalid new name",
  camera: {
    title: "Take the picture",
  }
}
