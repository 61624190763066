export default {
  head: {
    title: "404 Error",
    meta: [
      { name: "description", content: "MedForm - 404 Error" }
    ]
  },
  sorry: "Sorry, page not found",
  back: "Back to Dashboard"
}
