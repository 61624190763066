import type { AttendanceViewModel, SaveAttendanceInput } from "@/models/api/mutations/AttendanceModel";
import GraphqlService from "../GraphqlService";
import type { AttendanceSchema } from "@/models/schemas/AttendanceSchema";

const fields = `
  id
  createdAt
  updatedAt
  deletedAt

  userId
  startDateTime
  duration
  type
  notes
`;

const attendanceViewModelFields = `
    todayAttendanceDurationSum
    closestAttendanceDateTo
`;

export default class AttendanceService {
    async getUserAttendances(userId: number, fromDate: Date, toDate: Date) {
        const { data } = await GraphqlService.queryGql<AttendanceSchema[]>({
            method: "userAttendance",
            fields: fields,
            variables: [
                { field: "userId", value: userId, valueType: "Int!" },
                { field: "fromDate", value: fromDate, valueType: "DateTimeType!" },
                { field: "toDate", value: toDate, valueType: "DateTimeType!" }
            ],
        });
        return data;
    }

    async saveAttendance(input: SaveAttendanceInput, skipSubscription: boolean = false) {
        return await GraphqlService.setItem<AttendanceSchema>("saveAttendance", "attendance", fields, input, { variables: [{ field: 'skipSubscription', valueType: "Boolean", value: skipSubscription }] });
    }

    async deleteAttendance(id: number) {
        return await GraphqlService.deleteItem("deleteAttendance", id);
    }

    async updateUserAutoLogHours(id: number, autoLogHours: boolean) {
        return await GraphqlService.mutateGql<boolean>({
            method: "updateUserAutoLogHours",
            variables: [
                { field: "id", value: id, valueType: "ID!" },
                { field: "autoLogHours", value: autoLogHours, valueType: "Boolean!" },
            ]
        });
    }

    async getAttendanceViewModel(userId: number) {
        const { data } = await GraphqlService.queryGql<AttendanceViewModel>({
            method: "attendanceViewModel",
            fields: attendanceViewModelFields,
            variables: [
                { field: "userId", value: userId, valueType: "Int!" }
            ],
        });
        return data;
    }
}