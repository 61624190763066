export class FileInput {
  public static readAsDataURLAsync(file: File | Blob) {
    // https://developer.mozilla.org/ru/docs/Web/API/FileReader/readAsDataURL
    // https://stackoverflow.com/a/54559514/22548940
    return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = function () {
        resolve(reader.result);
      };
      reader.onerror = function (event) {
        reject(event);
      };
      reader.readAsDataURL(file);
    })
  }

  public static openFileDialog(accept?: string | null, multiple?: boolean, capture?: string) {
    return new Promise<FileList | File[]>((resolve, reject) => {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = accept ?? "";
      input.multiple = multiple ?? false;
      if (capture) input.capture = capture;
      input.style.display = "none";
      input.onchange = (e: any) => {
        resolve(e.target.files);
        document.body.removeChild(input);
      };
      // https://stackoverflow.com/a/76926836/22548940
      input.addEventListener("cancel", (e) => {
        document.body.removeChild(input);
        resolve([]);
      });
      document.body.appendChild(input);
      input.click();
    })
  }
}
