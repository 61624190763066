import GraphqlService from "../GraphqlService";
import { depositCashSchemaAllFields, type DepositCashSchema } from "@/models/schemas";
import type { TabulatorParams } from "@/models/interfaces";
import type { CreateDepositCashInput } from "@/models/api/mutations/DepositCashModels";

export default class DepositCashService {
  async getDepositCashes(params: TabulatorParams, fields?: string[]) {
    return await GraphqlService.getItems<DepositCashSchema>("depositCashes", fields ?? depositCashSchemaAllFields, params);
  }

  async deleteDepositCash(depositCashId: number) {
    return await GraphqlService.deleteItem("deleteDepositCash", depositCashId);
  }

  async createDepositCash(input: CreateDepositCashInput) {
    const { data } = await GraphqlService.mutateGql<number>({
      method: "createDepositCash",
      variables: [{ field: "input", value: input, valueType: "CreateDepositCashInput" }],
    });
    return data ?? 0;
  }
}