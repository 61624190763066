// https://bobbyhadz.com/blog/typescript-create-type-from-object-keys

export const Policies = {
  Administration: "Administration",

  Financial: "Financial",
  FinancialReports: "FinancialReports",
  Incomes: "Incomes",
  Expenses: "Expenses",
  Forecast: "Forecast",
  Deposit: "Deposit",
  AccountingReport: "AccountingReport",

  Appointments: "Appointments",
  Leads: "Leads",
  LaboratoryWorks: "LaboratoryWorks",
  Tasks: "Tasks",
  Attendance: "Attendance",
  Settings: "Settings",

  Emails: "Emails",
  Recall: "Recall",
  Chat: "Chat",
  Users: "Users",
  Patients: "Patients",
} as const;

type PoliciesKeys = keyof typeof Policies;

export type PoliciesValues = (typeof Policies)[PoliciesKeys];
