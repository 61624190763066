import GraphqlService from "../GraphqlService";
import { useStores } from "@/stores";
import { BusinessType, SettingType } from "@/models/enums";
import { settingSchemaAllFields as fields, type SettingSchema } from "@/models/schemas";
import type { Filter } from "@/models/interfaces";
import type { BusinessDetailsModel } from "@/models/api/queries/SettingModels";
import type {
  BusinessAddressModel, BusinessBankModel, BusinessAccountingModel, DocumentContentModel,
  DocumentNumbersViewModel, DocumentSettingsModel, BookkeepingModel, CalendarModel,
  BusinessTreatmentModel, TimeZone
} from "@/models/api/common/SettingModels";
import type {
  SaveBusinessAddressInput, SaveBusinessBankInput, SaveBusinessAccountingInput, SaveDocumentContentInput,
  SaveDocumentNumbersInput, SaveDocumentSettingsInput, SaveBookkeepingInput, SaveCalendarInput,
  SaveBusinessTreatmentInput
} from "@/models/api/mutations/SettingModels";

export default class SettingService {
  //#region common
  parseSetting<T>(setting: { settingType: SettingType, value: string }) {
    switch (setting.settingType) {
      case SettingType.String: return setting.value as T;
      case SettingType.Int: return parseInt(setting.value) as T;
      case SettingType.Data: return setting.value as T; // ???
      case SettingType.DateTime: return new Date(setting.value) as T;
      case SettingType.Decimal: return parseFloat(setting.value) as T;
      case SettingType.Enum: return setting.value as T;
      case SettingType.Bool: return (setting.value.toLowerCase() === "true") as T;
      case SettingType.Json: return JSON.parse(setting.value) as T;
      case SettingType.Editor: return setting.value as T; // ???
      default: return null;
    }
  }

  async getSetting<T>(key: string) {
    try {
      const { data: setting } = await GraphqlService.queryGql<SettingSchema>({
        method: "setting",
        fields: fields,
        variables: [{ field: "key", value: key, valueType: "String!" }]
      });
      if (setting) {
        return this.parseSetting<T>(setting);
      }
    } catch (error: any) {
      console.error(error);
    }
    return undefined;
  }

  async getSettingsAll(filter?: Filter[]) {
    const { data } = await GraphqlService.queryGql<SettingSchema[]>({ method: "settingsAll", fields: fields, filter: filter });
    return data ?? [];
  }

  getVatMultiplier(vatPercent?: number) {
    vatPercent ??= useStores().settings.getValue<number>("AccountingVatPercent", 17);
    const vatMultiplier = (1 + (vatPercent / 100));
    return vatMultiplier;
  }

  currencyFormat(amount?: number | null) {
    const currencyFormat = useStores().settings.getValue<{ Positive: string, Negative: string, Locale: string, Digits: number } | null>("DocumentSettingsCurrencyFormat", null);
    if (typeof amount !== "number") amount = 0;
    if (!currencyFormat) return amount.toFixed(2);
    if (amount < 0) return currencyFormat.Negative.replace('{0}', (-amount).toLocaleString(currencyFormat.Locale, { minimumFractionDigits: currencyFormat.Digits, maximumFractionDigits: currencyFormat.Digits }));
    return currencyFormat.Positive.replace('{0}', Math.abs(amount).toLocaleString(currencyFormat.Locale, { minimumFractionDigits: currencyFormat.Digits, maximumFractionDigits: currencyFormat.Digits }));
  }

  async removeBusinessImage(key: string) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "removeBusinessImage", variables: [{ field: "key", value: key, valueType: "String!" }]
    });
    return data ?? false;
  }

  async removeBusinessPdf(key: string) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "removeBusinessPdf", variables: [{ field: "key", value: key, valueType: "String!" }]
    });
    return data ?? false;
  }
  //#endregion

  //#region business details
  async saveBusinessDetails(name: string, number: string, type: BusinessType) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "saveBusinessDetails",
      variables: [
        { field: "name", value: name, valueType: "String!" },
        { field: "number", value: number, valueType: "String!" },
        { field: "type", value: type, valueType: "BusinessType!" },
      ]
    });
    return data ?? false;
  }

  async getBusinessDetailsModel() {
    const detailsFields = `
      logoDeleted
      signatureDeleted
      logoName
      signatureName
      name
      number
      type
    `;
    const { data } = await GraphqlService.queryGql<BusinessDetailsModel>({ method: "businessDetailsModel", fields: detailsFields });
    return data;
  }
  //#endregion

  //#region business address
  async saveBusinessAddress(input: SaveBusinessAddressInput) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "saveBusinessAddress",
      variables: [{ field: "input", value: input, valueType: "SaveBusinessAddressInput!" }]
    });
    return data ?? false;
  }

  async getBusinessAddressModel() {
    const addressFields = `
      businessAddress
      businessCity
      businessZip
      businessMobilePhone
      businessLandPhone
      businessFax
      businessEmail
      businessSite
    `;
    const { data } = await GraphqlService.queryGql<BusinessAddressModel>({ method: "businessAddressModel", fields: addressFields });
    return data;
  }
  //#endregion

  //#region business bank
  async saveBusinessBank(input: SaveBusinessBankInput) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "saveBusinessBank",
      variables: [{ field: "input", value: input, valueType: "SaveBusinessBankInput!" }]
    });
    return data ?? false;
  }

  async getBusinessBankModel() {
    const bankFields = `
      businessBankBeneficiary
      businessBankName
      businessBankBranch
      businessBankAccount
      businessBankSwift
      businessBankAba
      businessBankIban
      businessBankDisplayOnInvoice
    `;
    const { data } = await GraphqlService.queryGql<BusinessBankModel>({ method: "businessBankModel", fields: bankFields });
    return data;
  }
  //#endregion

  //#region business accounting
  async saveBusinessAccounting(input: SaveBusinessAccountingInput) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "saveBusinessAccounting",
      variables: [{ field: "input", value: input, valueType: "SaveBusinessAccountingInput!" }]
    });
    return data ?? false;
  }

  async getBusinessAccountingModel() {
    const accountingFields = `
      bookkeepingDeleted
      withholdingTaxDeleted
      bookkeepingDocument
      withholdingTaxDocument
      vatType
      incomeTaxRate
      nationalInsuranceRate
      vatDeductionRate
      taxFileNumber
      method
      recallInclude
      recallPercent
    `;
    const { data } = await GraphqlService.queryGql<BusinessAccountingModel>({ method: "businessAccountingModel", fields: accountingFields });
    return data;
  }
  //#endregion

  //#region business document content
  async saveDocumentContent(input: SaveDocumentContentInput) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "saveDocumentContent",
      variables: [{ field: "input", value: input, valueType: "SaveDocumentContentInput!" }]
    });
    return data ?? false;
  }

  async getDocumentContentModel() {
    const contentFields = `
      documentEstimate
      documentProformaInvoice
      documentInvoice
      documentInvoiceReceipt
      documentReceipt
      documentReceiptRefound
      documentInvoiceRefound
    `;
    const { data } = await GraphqlService.queryGql<DocumentContentModel>({ method: "documentContentModel", fields: contentFields });
    return data;
  }
  //#endregion

  //#region business document numbers
  async saveDocumentNumbers(input: SaveDocumentNumbersInput) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "saveDocumentNumbers",
      variables: [{ field: "input", value: input, valueType: "SaveDocumentNumbersInput!" }]
    });
    return data ?? false;
  }

  async getDocumentNumbersViewModel() {
    const numbersFields = `
      isEstimateReadonly
      isProformaInvoiceReadonly
      isInvoiceReadonly
      isInvoiceReceiptReadonly
      isReceiptReadonly
      isInvoiceRefoundReadonly
      isReceiptRefoundReadonly

      data {
        numberEstimate
        numberProformaInvoice
        numberInvoice
        numberInvoiceReceipt
        numberReceipt
        numberInvoiceRefound
        numberReceiptRefound
      }
    `;
    const { data } = await GraphqlService.queryGql<DocumentNumbersViewModel>({ method: "documentNumbersViewModel", fields: numbersFields });
    return data;
  }
  //#endregion

  //#region business document settings
  async saveDocumentSettings(input: SaveDocumentSettingsInput) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "saveDocumentSettings",
      variables: [{ field: "input", value: input, valueType: "SaveDocumentSettingsInput!" }]
    });
    return data ?? false;
  }

  async getDocumentSettingsModel() {
    const settingsFields = `
      vatInServices
      defaultCentsRounding
      emailSubject
      documentManualCreation
      documentAfterApiCreation
      emailOnBounce
      emailOnError
    `;
    const { data } = await GraphqlService.queryGql<DocumentSettingsModel>({ method: "documentSettingsModel", fields: settingsFields });
    return data;
  }
  //#endregion

  //#region business bookkeeping
  async saveBookkeeping(input: SaveBookkeepingInput) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "saveBookkeeping",
      variables: [{ field: "input", value: input, valueType: "SaveBookkeepingInput!" }]
    });
    return data ?? false;
  }

  async getBookkeepingModel() {
    const bookkeepingFields = `
      sendIncomes
      sendExpenses
      day
      frequency
      emails
      sendDocuments
    `;
    const { data } = await GraphqlService.queryGql<BookkeepingModel>({ method: "bookkeepingModel", fields: bookkeepingFields });
    return data;
  }
  //#endregion

  //#region business calendar
  async saveCalendar(input: SaveCalendarInput) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "saveCalendar",
      variables: [{ field: "input", value: input, valueType: "SaveCalendarInput!" }]
    });
    return data ?? false;
  }

  async getCalendarModel() {
    const calendarFields = `
      businessWorkDays
      durationValues
      addAppointmentMinutes
      businessHoursStart
      businessHoursEnd
      timeZoneId
      eventColor
      weekStartDay
      durationIntervalMinutes
      defaultDoctor
      haveAutoAttendanceLog
      autoLogHoursLimit
    `;
    const { data } = await GraphqlService.queryGql<CalendarModel>({ method: "calendarModel", fields: calendarFields });
    return data;
  }

  async getSystemTimeZones() {
    const { data } = await GraphqlService.queryGql<TimeZone[]>({ method: "systemTimeZones", fields: "id" });
    return data ?? [];
  }
  //#endregion

  //#region business treatment
  async saveBusinessTreatment(input: SaveBusinessTreatmentInput) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "saveBusinessTreatment",
      variables: [{ field: "input", value: input, valueType: "SaveBusinessTreatmentInput!" }]
    });
    return data ?? false;
  }

  async getBusinessTreatmentModel() {
    const treatmentFields = `
      hourPrice
      fixedNotes
      laboratoryWorkNotes
      medicalRengensJson
    `;
    const { data } = await GraphqlService.queryGql<BusinessTreatmentModel>({ method: "businessTreatmentModel", fields: treatmentFields });
    return data;
  }
  //#endregion
}
