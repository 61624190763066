import GraphqlService from "../GraphqlService";
import { storageFileSchemaAllFields as defaultFields, type StorageFileSchema, type StorageTempFileSchema } from "@/models/schemas";
import type { FilterAttachmentInput } from "@/models/api/queries/AttachmentModels";
import type { StorageFileType } from "@/models/enums";
import type { TabulatorParams } from "@/models/interfaces";

export default class AttachmentService {
  async getAttachments(fields: string[] | null, size: number, page: number, entityId: number, type: StorageFileType) {
    const params: TabulatorParams = {
      size: size,
      page: page,
      filter: [{ field: "entityId", type: "=", value: entityId }, { field: "type", type: "=", value: type }],
      sort: [{ field: "id", dir: "desc" }]
    };
    return await GraphqlService.getItems<StorageFileSchema>("attachments", fields ?? defaultFields, params);
  }

  async getEmailAttachments(fields: string[] | null, names: string[], size: number, page: number) {
    const params: TabulatorParams = {
      size: size,
      page: page,
      filter: [],
      sort: [{ field: "id", dir: "desc" }]
    };
    return await GraphqlService.getItems<StorageTempFileSchema>("emailAttachments", fields ?? defaultFields, params, {
      variables: [{ field: "names", value: names, valueType: "[String]" }]
    });
  }

  async getAttachmentsTabulator(params: TabulatorParams, input?: FilterAttachmentInput, fields?: string[]) {
    return await GraphqlService.getItems<StorageFileSchema>("attachments", fields ?? defaultFields, params, {
      variables: [{ field: "input", value: input, valueType: "FilterAttachmentInput" }]
    });
  }

  async getAttachment(id: number, fields?: string[]) {
    return await GraphqlService.getItem<StorageFileSchema>("attachments", fields ?? defaultFields, id);
  }

  async deleteAttachment(id: number) {
    return await GraphqlService.deleteItem("deleteAttachment", id);
  }

  async deleteEmailAttachment(id: number) {
    return await GraphqlService.deleteItem("deleteEmailAttachment", id);
  }
}
