import type { DateTimeString } from "@/models/interfaces";
import type { PaymentTerms } from "@/models/enums";

export interface LaboratorySchema {
    id: number;
    createdAt: DateTimeString;
    updatedAt: DateTimeString;
    deletedAt?: DateTimeString;

    active: boolean;
    title?: string;
    address?: string;
    city?: string;
    zip?: string;
    country?: string;
    department?: string;
    notes?: string;
    contactPerson?: string;
    phone?: string;
    fax?: string;
    mobile?: string;
    email1?: string;
    email2?: string;
    email3?: string;

    paymentTerms: PaymentTerms;
    taxId: string;
    laboratoryKey: string;
    bankName: string;
    bankBranch: string;
    bankAccount: string;
}

export const laboratorySchemaAllFields = [
    "id",
    "createdAt",
    "updatedAt",
    "deletedAt",
    "active",
    "title",
    "address",
    "city",
    "zip",
    "country",
    "department",
    "notes",
    "contactPerson",
    "phone",
    "fax",
    "mobile",
    "email1",
    "email2",
    "email3",
    "paymentTerms",
    "taxId",
    "laboratoryKey",
    "bankName",
    "bankBranch",
    "bankAccount"
];