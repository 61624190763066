export default {
  head: {
    title: "תבניות הפניות",
    meta: [
      { name: "description", content: "תיאור תבניות הפניות" }
    ]
  },
  table: {
    columns: {
      id: "ID",
      title: "כותרת",
      active: "פעיל"
    },
  },
  title: "תבניות הפניה",
  add: "הוספת תבנית מסמך",
  searchByTitle: "חפש לפי כותרת"
}
