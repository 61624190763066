export default {
  title: "Documents",
  table: {
    columns: {
      id: "ID",
      createdAt: "Create date",
      doctorName: "Doctor",
      title: "Title",
    }
  },
  edit: {
    titleNew: "Document",
    title: "Document #{0}",
    doctor: "Doctor",
    template: "Template",
    subject: "Subject",
    text: "Text"
  }
}
