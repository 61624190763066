import type { FilterRecallInput } from "@/models/api/queries/RecallModels";
import type { TabulatorParams } from "@/models/interfaces/TabulatorParams";
import GraphqlService from "../GraphqlService";
import type { RecallSchema } from "@/models/schemas/RecallSchema";
import type { MessageProvider } from "@/models/enums";

const fieldsRecals = `
  id
  createdAt

  fullName
  phone
  email
  photo
  serviceName

  userId
  serviceId
  patientId

  lastServiceDate
  lastAppointmentNote
  recallDate
  newAppointmentId
  newAppointmentDate

  hasUnreadChatMessage
  lastUnreadChatMessage

  recallMessages {
    time
    messageProvider
    data
  }
`;

export default class RecallService {
  async getRecalls(params: TabulatorParams, input?: FilterRecallInput) {
    return await GraphqlService.getItems<RecallSchema>("recalls", fieldsRecals, params, {
      variables: [{ field: "input", value: input, valueType: "FilterRecallInput" }]
    });
  }

  async getPatientLastRecall(patientId: number) {
    return await GraphqlService.getItem<RecallSchema>("patientLastRecall", fieldsRecals, patientId);
  }

  async sendRecalls(recallIds: number[], provider: MessageProvider, messageTemplateId: number | null, messageText: string, subject: string, input?: FilterRecallInput): Promise<boolean> {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "sendRecalls",
      variables: [
        { field: "recallIds", value: recallIds, valueType: "[Int!]" },
        { field: "provider", value: provider, valueType: "MessageProvider!" },
        { field: "text", value: messageText, valueType: "String!" },
        { field: "subject", value: subject, valueType: "String!" },
        { field: "messageTemplateId", value: messageTemplateId, valueType: "Int" },
        { field: "input", value: input, valueType: "FilterRecallInput" },
      ]
    });
    return data ?? false;
  }

  async sendRecall(lastAppointmentId: number, platform: MessageProvider, destination: string, messageText: string, subject?: string, attachmentIds?: number[]): Promise<boolean> {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "sendRecall",
      variables: [
        { field: "lastAppointmentId", value: lastAppointmentId, valueType: "Int!" },
        { field: "provider", value: platform, valueType: "MessageProvider!" },
        { field: "destination", value: destination, valueType: "String" },
        { field: "text", value: messageText, valueType: "String" },
        { field: "subject", value: subject, valueType: "String" },
        { field: "attachmentIds", value: attachmentIds || [], valueType: "[Int!]" },
      ]
    });
    return data ?? false;
  }
}
