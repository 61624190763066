export default {
  head: {
    title: "Service Category",
    meta: [
      { name: "description", content: "Service Category" }
    ]
  },

  title: "Title",
  displayOrder: "Display order",

  createTitle: "Create service category",
  updateTitle: "Update service category",

  nameUsed: "Category name is already in use.",
}
