<template>
  <div class="p-formkit">
    <AutoComplete
      ref="autoComplete"
      :id="context.id"
      v-model="context._value"
      option-label="label"
      v-bind="context.attrs"
      :disabled="!!context.disabled"
      :class="styleClass"
      input-class="form-control"
      :tabindex="context.attrs.tabindex"
      :aria-label="context.attrs.ariaLabel"
      :aria-labelledby="context.attrs.ariaLabelledby"
      :suggestions="suggestions"
      :dropdown="true"
      :multiple="false"
      :pt="context.pt"
      :pt-options="context.ptOptions"
      @complete="search"
      @change="handleInput"
      @blur="handleBlur"
      @show="visible = true"
      @hide="visible = false">
      <template #empty>
        <span>{{ context._value?.label || t("default.notFound") }}</span>
      </template>
    </AutoComplete>
  </div>
</template>

<!-- https://github.com/sfxcode/formkit-primevue/blob/main/src/components/PrimeAutoComplete.vue -->

<script setup lang="ts">
  import AutoComplete from "primevue/autocomplete";
  import { type PropType, ref, computed, watch, onMounted } from "vue";
  import type { FormKitFrameworkContext } from "@formkit/core";
  import type { AutoCompleteCompleteEvent, AutoCompleteProps } from "primevue/autocomplete";
  import { useI18n } from "vue-i18n";
  import { HtmlHelper } from "@/utils";

  export interface FormKitPrimeAutoCompleteProps {
    pt?: AutoCompleteProps["pt"];
    ptOptions?: AutoCompleteProps["ptOptions"];
  }

  const { t } = useI18n();
  const props = defineProps({
    context: {
      type: Object as PropType<FormKitFrameworkContext & FormKitPrimeAutoCompleteProps>,
      required: true
    }
  });

  const suggestions = ref<any>([]);
  const options = computed<{ label: string; value: any }[] | null>(() => props.context.attrs.options);
  const visible = ref(false);
  const autoComplete = ref<AutoComplete>();
  const autoCompleteElement = computed<HTMLDivElement>(() => (autoComplete.value as any).$el);

  watch(options, (options) => {
    const option = props.context._value;
    if (options?.length && option.value) {
      option.label = options.find((x) => x.value == option.value)?.label || option.value;
    }
  });

  function search(event: AutoCompleteCompleteEvent) {
    if (event.query?.length) {
      suggestions.value = options.value?.filter((option: any) => option.label?.includes(event.query));
    } else {
      suggestions.value = options.value?.filter(Boolean);
    }
  }

  function handleInput(_: any) {
    const value = props.context._value;
    if (typeof value === "string") {
      props.context.node.input({ label: value, value: null });
    } else {
      props.context.node.input(value);
    }
  }

  function handleBlur(event: any) {
    props.context.handlers.blur(event);
  }

  const styleClass = computed(() =>
    props.context.state.validationVisible && !props.context.state.valid ? `${props.context.attrs?.class} p-invalid` : props.context.attrs?.class
  );

  onMounted(() => {
    // show on click by input
    HtmlHelper.findAll<HTMLInputElement>("input.p-autocomplete-input", autoCompleteElement.value).forEach((input) => {
      input.addEventListener("click", () => {
        if (!visible.value) {
          HtmlHelper.findFirst<HTMLButtonElement>("button.p-autocomplete-dropdown", autoCompleteElement.value)?.click();
        }
      });
    });
  });
</script>
