﻿export default {
  head: {
    title: "Laboratories",
    meta: [
      { name: "description", content: "Laboratories" }
    ]
  },
  title: "Laboratories",
  table: {
    columns: {
      id: "#",
      active: "Active",
      title: "Title",
      address: "Address",
      city: "City",
      zip: "Zip",
      country: "Country",
      department: "Department",
      notes: "Notes",
      contactPerson: "Contact person",
      phone: "Phone",
      fax: "Fax",
      mobile: "Mobile",
      email1: "Email 1",
      email2: "Email 2",
      email3: "Email 3",
    },
  },
  add: "Adding a lab",
  actions: {
    edit: "Edit"
  }
}
