export default {
  head: {
    title: "Income report",
    meta: [
      { name: "description", content: "Income report" }
    ]
  },
  title: "Income report",
  dateFrom: "From date",
  dateTo: "To date",
  patientLabel: "Type the client name or leave blank for all clients.",
  incomeTypes: {
    label: "Types of documents",
    invoice: "Invoice",
    invoiceReceipt: "Invoice receipt",
    receipt: "Receipt",
    invoiceRefound: "Invoice refound",
    receiptRefound: "Receipt refound"
  },
  files: {
    label: "Report file type",
    uniform: "Tax report",
    forms: "Form 2.6, 5.4",
  },
  email: {
    label: "Send to email",
    help: "The report will be sent to the email you specify here (optional)",
    copyText: "To copy the email addresses from the mailing settings > accounting",
    copyLink: "Click here"
  },
  buttons: {
    report: "Generate report"
  },
  validation: {
    selectIncomeType: "You need select at least one type of income",
    selectReportFile: "Please select report file type"
  }
}
