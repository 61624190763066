import { DiscountType, VatType, VatBeforeOrInclude } from "@/models/enums";
import IncomeService from "@/services/IncomeService";

export class FinancialUtil {
  /** Corrects invoice (Vat Normal/Included) 1 = 0.85 + 0.15 instead of 1 = 0.85 + 0.14 */
  public static amountVatAdditionBeforeRounding = 0.0005;

  public static round(value: number, decimals = 2) {
    // https://www.youtube.com/watch?v=kHoWenD1CaY&ab_channel=Samovartutorials
    const factor = Math.pow(10, decimals);
    value += Math.sign(value) * Number.EPSILON;
    return Math.round(value * factor) / factor;
  }
  public static formatPercent(percent?: number | null) {
    if (typeof percent !== "number") return "0.00%";
    return `${percent.toLocaleString("he", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%`;
  }

  public static getDiscountValidation(price: number, discountType: DiscountType) {
    switch (discountType) {
      case DiscountType.Percent:
        return "required|between:0,100";
      case DiscountType.Amount:
      case DiscountType.FixPrice:
        return `required|between:0,${price}`;
    }
    return "required";
  }

  public static getDiscountDisplay(price: number, discountType: DiscountType, discountValue: number) {
    switch (discountType) {
      case DiscountType.Percent:
        return discountValue.toFixed(2) + "%";
      case DiscountType.Amount:
        return discountValue.toFixed(2);
      case DiscountType.FixPrice:
        return (price - discountValue).toFixed(2);
    }
    return 0;
  }

  public static calculateDiscountAmount(price: number, discountType: DiscountType, discountValue: number) {
    switch (discountType) {
      default:
      case DiscountType.None:
        return 0;
      case DiscountType.Percent:
        return this.round(price * (discountValue / 100));
      case DiscountType.Amount:
        return discountValue;
      case DiscountType.FixPrice:
        return price - discountValue;
    }
  }

  public static calculateDiscountPrice(price: number, discountType: DiscountType, discountValue: number) {
    switch (discountType) {
      default:
      case DiscountType.None:
        return price;
      case DiscountType.Percent:
        return price - this.round(price * (discountValue / 100));
      case DiscountType.Amount:
        return price - discountValue;
      case DiscountType.FixPrice:
        return discountValue;
    }
  }

  public static calculateVat(servicePrice: number, vatPercent: number, vatType: VatType, vatBeforeOrInclude: VatBeforeOrInclude, rounding: boolean) {
    const result = {
      input: 0,
      subTotal: 0,
      rounding: 0,
      vat: 0,
      total: 0
    };
    if (vatType == VatType.Normal) {
      result.vat = FinancialUtil.round(servicePrice * (vatPercent / 100) + FinancialUtil.amountVatAdditionBeforeRounding);
      if (vatBeforeOrInclude == VatBeforeOrInclude.IncludeVat) {
        result.input = FinancialUtil.round(servicePrice + result.vat);
        result.subTotal = servicePrice;
      } else {
        result.input = servicePrice;
        result.subTotal = servicePrice;
      }
    } else {
      result.input = servicePrice;
      result.subTotal = servicePrice;
      result.vat = 0;
    }
    result.total = FinancialUtil.round(result.subTotal + result.vat);
    if (rounding) {
      const input = {
        vatType: vatType,
        amountRounding: 0,
        rounding: rounding,
        amountInvoiceBeforeVat: result.subTotal,
        amountDiscount: 0,
        amountInvoice: result.total,
        amountVat: result.vat
      };
      IncomeService.calculateAmountRounding(input, vatPercent);

      result.rounding = input.amountRounding;
      result.vat = input.amountVat;
      result.total = input.amountInvoice;
    }
    return result;
  }
}
