export default {
  head: {
    title: "דוח מקדמות",
    meta: [
      { name: "description", content: "דוח מקדמות" }
    ]
  },
  title: 'דוח מקדמות',
  alertText: 'דוח זה מאפשר חישוב מקדמות לתשלום עיפ המחזור העסקי שלכם, שיעור המקדמות וניכויים שנוכו במקור.',
  alertPercentageLink: 'להגדרת אחוז מקדמות מס הכנסה',
  alertPaymentsLink: 'לתשלום מקדמות באתר רשות המיסים',
  dateFrom: "מתאריך",
  dateTo: "עד תאריך",
  advancePayment: 'תשלום מקדמות',
  transactions: 'עסקאות',
  businessTurnover: 'המחזור העסקי בש"ח',
  advanceTaxPercent: '% מקדמות מס הכנסה',
  advanceTaxResult: 'מקדמה ע"פ ה-% מהמחזור העסקי',
  deductions: 'ניכויים',
  totalWithholdingTax: 'סה"כ ניכוי במקור',
  totalAdvancePaid: 'סה"כ מקדמות לתשלום',
  buttons: {
    report: "הפקת דוח"
  }
}
