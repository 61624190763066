import { isArray } from "@apollo/client/utilities";
import merge from "deepmerge";

export class AutoMapper {
  static map(source: any, destination: any, useSourceKeys?: boolean, exclude?: string[]) {
    let keys: string[];
    if (useSourceKeys) {
      keys = Object.keys(source as object);
    } else {
      keys = Object.keys(destination as object);
    }
    keys.forEach(key => {
      if (exclude && exclude.includes(key)) {
        return;
      }
      else if (isArray(source[key])) {
        destination[key] = source[key].slice();
      } else {
        destination[key] = source[key];
      }
    });
    return destination;
  }

  static clear(source: any) {
    Object.entries(source).forEach(([key, value], index) => {
      if (typeof value === "string" && !value) {
        source[key] = null;
      }
    });
  }

  static merge<T = any>(target: object, ...sources: any[]) {
    // https://www.npmjs.com/package/deepmerge
    return merge.all([target].concat(sources)) as T;
  }
}
