export default {
  head: {
    title: "Prescription template",
    meta: [
      { name: "description", content: "Prescription template" }
    ]
  },

  createTitle: "Create prescription template",
  updateTitle: "Update prescription template number {0}",

  systemName: "Prescription name",
  drugName: "Drug",
  submission: "Submission",
  dose: "Dose",
  timesADay: "Times a day",
  numberOfDays: "Number of days",
  note: "Note",
  instruction: "Instruction of use",
  active: "Active",

  nameUsed: "Prescription name is already in use"
}
