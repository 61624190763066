import type { DateTimeString } from "@/models/interfaces";
import type { ExpensePaymentType, DocumentType } from "@/models/enums"

export interface ExpenseSchema {
  id: number;
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
  deletedAt: DateTimeString | null;
  documentType: DocumentType;
  documentNumber: string;
  documentDate: DateTimeString;
  reportingDate: DateTimeString;
  amount: number;
  vat: number;
  supplierId: number | null;
  supplierTaxId: string;
  expensePaymentType: ExpensePaymentType;
  expenseTypeId: number;
  description: string;
  notes: string;

  patientId: number | null;
  supplierNotRegisteredName: string;
  fileName: string;
  fileExtension: string;
  supplierName: string;
  patientName: string;
  expenseTypeDescription: string;
}

export const expenseSchemaAllFields = [
  "id",
  "createdAt",
  "updatedAt",
  "deletedAt",
  "documentType",
  "documentNumber",
  "documentDate",
  "reportingDate",
  "amount",
  "vat",
  "supplierId",
  "supplierTaxId",
  "expensePaymentType",
  "expenseTypeId",
  "description",
  "notes",

  "patientId",
  "supplierNotRegisteredName",
  "fileName",
  "fileExtension",
  "supplierName",
  "patientName",
  "expenseTypeDescription"
];
