import navbar from "./navbar";
import menuitems from "./menuitems";

import primeDatepicker from "./components/primeDatepicker";
import primeSignaturePad from "./components/primeSignaturePad";

import enums from "./utils/enums";

import page404 from "./views/pages/404";
import page500 from "./views/pages/500";

import databases from "./views/databases";

import accountLogin from "./views/account/login";
import accountPasswordReset from "./views/account/passwordReset";
import accountPasswordChange from "./views/account/passwordChange";
import attendance from "./views/attendance/attendance";

import calendar from "./views/calendar/calendar";
import calendarAppointment from "./views/calendar/appointment";
import calendarWaitlist from "./views/calendar/waitlist";
import calendarReminder from "./views/calendar/appointmentReminder";

import chat from "./views/chat/index";
import chatSettings from "./views/chat/settings";

import dashboard from "./views/dashboard";
import depositCash from "./views/deposit/cash";
import depositChecks from "./views/deposit/checks";

import forecastList from "./views/forecast";

import income from "./views/incomes/income";
import incomesList from "./views/incomes/list";
import incomesPreview from "./views/incomes/preview";
import incomeSaveBeforePopup from "./views/incomes/popups/save-before-popup";
import incomeSaveAfterPopup from "./views/incomes/popups/save-after-popup";
import incomePreviewPopup from "./views/incomes/popups/preview-popup";
import incomeAddDocumentPopup from "./views/incomes/popups/add-document-popup";
import incomeSendPopup from "./views/incomes/popups/send-popup";
import incomeRelatedDocsPopup from "./views/incomes/popups/related-docs-popup";

import email from "./views/email/email"

import expensesList from "./views/expenses/expenses";
import expenseSuppliers from "./views/expenses/suppliers";
import expenseTypes from "./views/expenses/types";

import laboratoryWork from "./views/lab-works/lab-work";
import laboratoryWorksList from "./views/lab-works/list";
import laboratoryWorkLaboratories from "./views/lab-works/laboratories";
import laboratoryWorkLaboratory from "./views/lab-works/laboratory";

import lead from "./views/leads/lead";
import leadList from "./views/leads/list";
import leadChannels from "./views/leads/channels";
import leadChannel from "./views/leads/channel";

import patient from "./views/patients/patient";
import patientsList from "./views/patients/list";
import patientPrescriptions from "./views/patients/prescriptions";
import patientDocuments from "./views/patients/documents";
import patientMedicalForms from "./views/patients/medical-forms";
import patientNotes from "./views/patients/patient-notes";
import patientFiles from "./views/patients/files";
import patientTasks from "./views/patients/tasks";
import patientTreatments from "./views/patients/treatments";
import patientTreatmentPlans from "./views/patients/treatment-plans";
import patientLaboratoryWorks from "./views/patients/laboratory-works";
import patientFamily from "./views/patients/family";
import patientPayments from "./views/patients/payments";

import recall from "./views/recall/recall"

import treatment from "./views/patients/treatments/treatment";

import reportsAccounting from "./views/reports/accounting";
import reportsAdvanceTax from "./views/reports/advance-tax";
import reportsCollection from "./views/reports/collection";
import reportsExpenses from "./views/reports/expenses";
import reportsIncome from "./views/reports/income";
import reportsOverview from "./views/reports/overview";
import reportsProfitAndLoss from "./views/reports/profit-and-loss";
import reportsVat from "./views/reports/vat";

import settingsBusiness from "./views/settings/business";
import settingsPricelist from "./views/settings/pricelist/pricelist";
import settingsPricelistGroups from "./views/settings/pricelist/groups";
import settingsPricelistGroup from "./views/settings/pricelist/group";
import settingsUsers from "./views/settings/users/users";
import settingsUser from "./views/settings/users/user";
import settingsServices from "./views/settings/services/services";
import settingsService from "./views/settings/services/service";
import settingsServiceCategories from "./views/settings/services/categories";
import settingsServiceCategory from "./views/settings/services/category";

import settingsTemplatesDocuments from "./views/settings/templates/documents";
import settingsTemplatesDocument from "./views/settings/templates/document";
import settingsTemplatesMessages from "./views/settings/templates/messages";
import settingsTemplatesMessage from "./views/settings/templates/message";
import settingsTemplatesPrescriptions from "./views/settings/templates/prescriptions";
import settingsTemplatesPrescription from "./views/settings/templates/prescription";
import settingsRestore from "./views/settings/restore/restore";

import task from "./views/tasks/task";
import tasksList from "./views/tasks/list";
import externalCalendars from "./views/settings/externalCalendars/externalCalendars";

export default {
  "default": {
    view: "צפיה",
    download: "הורדת מסמך",
    print: "הדפסה",
    link: "לינק",
    yes: "כן",
    no: "לא",
    create: "הוספה",
    update: "עדכון",
    delete: "מחיקה",
    change: "החלפה",
    cancel: "ביטול",
    cancelAction: "ביטול פעולה",
    error: "שגיאה",
    edit: "עריכה",
    save: "שמירה",
    saved: "נתונים נשמרו בהצלחה!",
    reallyRemove: "האם למחוק את {0}?",
    notFound: "לא נמצאו תוצאות",
    send: "שליחה",
    sended: "הודעה נשלחה בהצלחה",
    all: "הכל",
    new: "חדש",
    clear: "נקה",
    ok: "המשך",
    select: "בחר",
    selectAll: "סמן הכל",
    selectOption: "בחר אפשרות",
    search: "חיפוש",
    filters: "סינון",
    close: "סגירה",
    columnSelection: "בחירת עמודה",
    actions: "פעולות",
    connect: "צור חיבור",
    disconnect: "נתק",
    fileDownloadMessage: "ההורדה תחל תוך מספר שניות",
    unloadMessage: "לצאת ללא שמירה?",
    addVariable: "הוספת משתנה",
    title: "כותרת",
    treatment: "טיפול",
    treatmentPlan: "תוכנית טיפול",
    validation: {
      selectDateRange: "בבקשה לבחור תאריך התחלה וסיום",
      dateRange: "עד תאריך לא יכול להיות גדול מתאריך",
      checkEmails: 'בבקשה לקליד דוא"ל תקין'
    },
    systemVariables: {
      AppointmentDayAndDateTime: "תור יום תאריך ושעה",
      AppointmentDateAndHour: "תור תאריך ושעה",
      AppointmentDate: "תור תאריך",
      AppointmentHour: "תור שעה",
      AppointmentWeekDay: "תור יום בשבוע",
      AppointmentNote: "תור הערה",
      AppointmentDuration: "תור משך",
      DoctorName: "שם הרופא",
      PatientFullName: "מטופל שם מלא",
      PatientFirstName: "מטופל שם פרטי",
      PatientLastName: "מטופל שם משפחה",
      CompanyName: "שם חברה",
      CompanyAddress: "כתובת העסק",
      CompanyPhoneNumber: "טלפון העסק",
      PrescriptionInstruction: "הוראות טיפול",
      ServiceName: "שירות",
    }
  },
  "t-menu": "תַפרִיט",
  chat: chat,
  chatSettings: chatSettings,
  navbar,
  menuitems,
  components: {
    primeDatepicker,
    primeSignaturePad,
  },
  utils: {
    enums
  },
  pages: {
    404: page404,
    500: page500
  },
  databases,
  account: {
    login: accountLogin,
    passwordReset: accountPasswordReset,
    passwordChange: accountPasswordChange,
  },
  attendance,
  calendar: Object.assign(calendar, {
    appointment: calendarAppointment,
    waitlist: calendarWaitlist,
    appointmentReminder: calendarReminder
  }),
  dashboard,
  forecast: {
    list: forecastList
  },
  email,
  incomes: {
    income: income,
    list: incomesList,
    preview: incomesPreview,
    saveBeforePopup: incomeSaveBeforePopup,
    saveAfterPopup: incomeSaveAfterPopup,
    previewPopup: incomePreviewPopup,
    addDocumentPopup: incomeAddDocumentPopup,
    sendPopup: incomeSendPopup,
    relatedDocsPopup: incomeRelatedDocsPopup
  },
  deposit: {
    cash: depositCash,
    checks: depositChecks
  },
  expenses: {
    list: expensesList,
    suppliers: expenseSuppliers,
    types: expenseTypes
  },
  laboratoryWorks: {
    laboratoryWork: laboratoryWork,
    list: laboratoryWorksList,
    laboratories: laboratoryWorkLaboratories,
    laboratory: laboratoryWorkLaboratory
  },
  leads: {
    list: leadList,
    lead: lead,
    channels: leadChannels,
    channel: leadChannel,
  },
  patients: {
    patient: patient,
    list: patientsList,
    prescriptions: patientPrescriptions,
    documents: patientDocuments,
    medicalForms: patientMedicalForms,
    notes: patientNotes,
    files: patientFiles,
    tasks: patientTasks,
    treatments: patientTreatments,
    treatmentPlans: patientTreatmentPlans,
    laboratoryWorks: patientLaboratoryWorks,
    family: patientFamily,
    payments: patientPayments,
  },
  treatments: {
    treatment: treatment,
  },
  recall,
  reports: {
    accounting: reportsAccounting,
    advanceTax: reportsAdvanceTax,
    collection: reportsCollection,
    expenses: reportsExpenses,
    income: reportsIncome,
    overview: reportsOverview,
    profitAndLoss: reportsProfitAndLoss,
    vat: reportsVat
  },
  settings: {
    business: settingsBusiness,
    pricelist: settingsPricelist,
    pricelistGroups: settingsPricelistGroups,
    pricelistGroup: settingsPricelistGroup,
    users: settingsUsers,
    user: settingsUser,
    externalCalendars: externalCalendars,
    services: settingsServices,
    service: settingsService,
    serviceCategories: settingsServiceCategories,
    serviceCategory: settingsServiceCategory,
    templates: {
      documents: settingsTemplatesDocuments,
      document: settingsTemplatesDocument,
      messages: settingsTemplatesMessages,
      message: settingsTemplatesMessage,
      prescriptions: settingsTemplatesPrescriptions,
      prescription: settingsTemplatesPrescription
    },
    restore: settingsRestore
  },
  tasks: {
    task: task,
    list: tasksList
  }
}
