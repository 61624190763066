import { useServices } from "@/services";
import ConfigService from "@/services/ConfigService";
import { ApolloClient, HttpLink, InMemoryCache, split } from "@apollo/client/core";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { onError } from "@apollo/client/link/error";
import { DefaultApolloClient, provideApolloClient } from "@vue/apollo-composable";
import { createApolloProvider } from "@vue/apollo-option";
import { createClient } from "graphql-ws";
import type { App, Plugin } from "vue";
import { useStores } from "@/stores";
import { i18n } from "@/locales/i18n";
import { DateInput, Popup } from "@/utils";

export const GraphQLPlugin: Plugin = {
  install(app: App) {
    // Authorization

    function getAuthorizationToken() {
      const token = useServices().apiAuth.token.access;
      return token || null;
    }

    function getHeaders() {
      const headers: HeadersInit = {
        "content-type": "application/json",
        "X-TimezoneOffsetHours": DateInput.getTimezoneOffsetHours().toString(),
        "X-Locale": i18n.global.locale.value,
      };

      const token = getAuthorizationToken();
      if (token) {
        headers["Authorization"] = `Bearer ${token}`;
      }

      return headers;
    }

    // https://v4.apollo.vuejs.org/guide-composable/subscription.html#the-new-library-graphql-ws

    const httpLink = new HttpLink({
      uri: ConfigService.graphql,
      fetch: (uri: RequestInfo, options: RequestInit) => {
        options.headers = Object.assign(options.headers ?? {}, getHeaders());
        return fetch(uri, options);
      },
      preserveHeaderCase: true // https://github.com/apollographql/apollo-client/issues/6741 https://github.com/apollographql/apollo-client/pull/9891
    });

    const wsLink = new GraphQLWsLink(
      createClient({
        url: ConfigService.graphqlWss,
        connectionParams() {
          const token = getAuthorizationToken();
          return {
            authorization: token ? `Bearer ${token}` : null
          };
        }
      })
    );

    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === "OperationDefinition" && definition.operation === "subscription";
      },
      wsLink,
      httpLink
    );

    // Handle errors https://v4.apollo.vuejs.org/guide-composable/error-handling.html#network-errors
    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (process.env.NODE_ENV === "development") {
        if (graphQLErrors) graphQLErrors.forEach((error) => console.log(error));
        if (networkError) console.log(networkError);
      }
      graphQLErrors?.filter(x => x.extensions?.code == "AUTH_NOT_AUTHORIZED").forEach(error => {
        if (process.env.NODE_ENV !== "development") console.log("AUTH_NOT_AUTHORIZED", error);
        Popup.notify({ title: <any>error.path, text: error.message, type: "error" });
      });
      if (networkError && networkError.message != "Socket closed") {
        let message = `${networkError.name || i18n.global.t("default.error")}: ${networkError.message}`;
        let description = '';
        graphQLErrors?.forEach(error => {
          description += `<hr>`;
          for (const [key, value] of Object.entries(error)) {
            description += `<b>${key}</b><br>${JSON.stringify(value, null, 2)}<br>`;
          }
        });
        useStores().layout.changeErrorCode(500, message, description);
      }
    });

    // https://shopify.engineering/apollo-cache
    // https://v4.apollo.vuejs.org/guide-composable/query.html#fetch-policy
    const apolloClient = new ApolloClient({
      cache: new InMemoryCache(),
      link: errorLink.concat(splitLink),
      defaultOptions: {
        query: {
          errorPolicy: "all",
          fetchPolicy: "network-only"
        },
        mutate: {
          errorPolicy: "all",
          fetchPolicy: "no-cache"
        }
      }
    });

    // https://v4.apollo.vuejs.org/guide-option/setup.html

    const apolloProvider = createApolloProvider({
      defaultClient: apolloClient
    });

    app.use(apolloProvider);

    // https://v4.apollo.vuejs.org/guide-composable/setup.html#vue-3

    app.provide(DefaultApolloClient, apolloClient);

    // https://v4.apollo.vuejs.org/guide-composable/setup.html#usage-outside-of-setup
    provideApolloClient(apolloClient);
  }
};
