export default {
  head: {
    title: "MedForm - שנה סיסמא",
    meta: [
      { name: "description", content: "MedForm שנה סיסמא" }
    ]
  },
  title: "שנה סיסמא",
  desc: "הכנס סיסמא חדשה",
  input: {
    email: "דוא\"ל",
    passwordNew: "סיסמה חדשה",
    passwordRepeat: "חזור על הסיסמה"
  },
  save: "שמור",
  error: "התרחשה שגיאה, בבקשה נסה שוב",
  success: "הסיסמא שונתה בהצלחה!"
}
