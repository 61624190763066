export default {
  head: {
    title: "Expense types",
    meta: [
      { name: "description", content: "Expense types description" }
    ]
  },
  validation: {
    preventDelete: "This expense type cannot be deleted, it has associated expenses"
  },
  classifications: {
    // 1st group:
    group1: "Cost of sales and services",
    group1_l1: "1306",
    group1_l2: "1307",
    group1_l3: "1310",
    group1_l4: "1320",
    group1_l5: "1330",
    group1_l6: "1340",
    group1_l7: "1390",
    // 2nd group:
    group2: "Selling expenses",
    group2_l1: "3011",
    group2_l2: "3012",
    group2_l3: "3015",
    group2_l4: "3055",
    group2_l5: "3060",
    group2_l6: "3066",
    group2_l7: "3067",
    group2_l8: "3068",
    group2_l9: "3070",
    group2_l10: "3075",
    group2_l11: "3080",
    group2_l12: "3085",
    group2_l13: "3090",
    group2_l14: "3100",
    group2_l15: "3120",
    // 3rd group:
    group3: "Administrative and general expenses",
    group3_l1: "3511",
    group3_l2: "3512",
    group3_l3: "3515",
    group3_l4: "3520",
    group3_l5: "3535",
    group3_l6: "3540",
    group3_l7: "3545",
    group3_l8: "3550",
    group3_l9: "3555",
    group3_l10: "3560",
    group3_l11: "3566",
    group3_l12: "3567",
    group3_l13: "3568",
    group3_l14: "3570",
    group3_l15: "3575",
    group3_l16: "3580",
    group3_l17: "3590",
    group3_l18: "3595",
    group3_l19: "3600",
    group3_l20: "3620",
    group3_l21: "3625",
    group3_l22: "3640",
    group3_l23: "3650",
    group3_l24: "3660",
    group3_l25: "3665",
    group3_l26: "3680",
    group3_l27: "3685",
    // 4th group:
    group4: "Financial expenses",
    group4_l1: "5010",
    group4_l2: "5090",
    // 5th group:
    group5: "Fixed assets",
    group5_l1: "8040",
    group5_l2: "8050",
    group5_l3: "8060",
    group5_l4: "8080",
    group5_l5: "8090"
  },
  buttons: {
    add: "Add expense type",
    download: "Download",
    edit: "Edit"
  },
  form: {
    edit: "Edit expense type",
    create: "Create expense type",
    name: "Name",
    classification: "Classification",
    nds: "Allowed for nds",
    expenses: "Allowed for expenses",
    // todo: add question tooltip #163
    nds_q: "Will be calculated from the amount of the business expense. To define the recognized percentage, it is recommended to consult with a CPA",
    expenses_q: "Will be calculated from the amount of the business expense. To define the recognized percentage, it is recommended to consult with a CPA",
    // todo: add question tooltip #163
    sortCode: "External sort code",
    accountKey: "External account key",
    submit: "Save"
  },
  table: {
    columns: {
      description: "Name",
      externalAccountKey: "Account id",
      allowedForNds: "% Allowed for nds",
      allowedForExpenses: "% Allowed for expenses"
    }
  }
}