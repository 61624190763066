export default {
  title: "Incomes and Expenses",
  period: "Period",
  incomesTitle: "Incomes",
  expensesTitle: "Expenses",
  head: {
    title: "Overview report",
    meta: [
      { name: "description", content: "Overview report description" }
    ]
  },
  filter: {
    dateFrom: 'From date',
    dateTo: 'To date',
    vat: "Amounts include VAT"
  }
}
