export default {
  title: "Patient notes",
  table: {
    columns: {
      id: "ID",
      createdAt: "Date",
      doctorName: "Doctor",
      description: "Description"
    }
  },
  popup: {
    titles: {
      edit: "Edit patient note",
      create: "Create patient note"
    },
    doctor: "Doctor",
    description: "Description",
    createdAt: "Created",
    noteOnAppointmentCreation: "Appointment alert",
    noteOnIncomeCreation: "Income alert",
    noteOnPatientOpen: "Patient alert",
    submit: "Add note screen"
  }
}
