import type { PatientWaitlistSchema } from "@/models/schemas";
import type { SavePatientWaitlistInput, } from "@/models/api/mutations/PatientModels";
import type { FilterPatientWaitlistInput } from "@/models/api/queries/PatientModels";
import moment from "@/plugins/VueMomentPlugin";
import type { TabulatorParams } from "@/models/interfaces";
import GraphqlService from "../GraphqlService";

const fieldsWaitlist = `
  id
  createdAt
  updatedAt
  deletedAt

  patientId
  appointmentId
  userIds

  fromDate
  toDate

  monday1
  tuesday1
  wednesday1
  thursday1
  friday1
  saturday1
  sunday1
  fromHour1
  toHour1

  monday2
  tuesday2
  wednesday2
  thursday2
  friday2
  saturday2
  sunday2
  fromHour2
  toHour2

  patientPhone
  patientName
  doctorNames
  appointmentStartTime
  serviceName
  serviceDescription
  appointmentDuration
`;

export default class PatientWaitlistService {
  async getPatientWaitlistsOnDashboard(limit: number, page: number, userIds: number[], fields?: string[]) {
    const startTime = moment.utc().format("YYYY-MM-DD") + "Z";
    const params: TabulatorParams = {
      size: limit,
      page: page,
      filter: [{ field: "appointmentStartTime", type: ">=", value: startTime, valueType: "DateTime!" }],
      sort: [{ field: "appointmentStartTime", dir: "asc" }]
    };
    return await this.getPatientWaitlists(params, { userIds }, fields);
  }

  async getPatientWaitlists(params: TabulatorParams, input?: FilterPatientWaitlistInput, fields?: string[]) {
    return await GraphqlService.getItems<PatientWaitlistSchema>("patientWaitlists", fields ?? fieldsWaitlist, params, {
      variables: [{ field: "input", value: input, valueType: "FilterPatientWaitlistInput" }]
    });
  }

  async hasPatientWaitlists(input: FilterPatientWaitlistInput) {
    const params: TabulatorParams = { size: 1, page: 1, filter: [], sort: [] };
    const result = await this.getPatientWaitlists(params, input, ["id"]);
    return result.totalCount > 0;
  }

  async getPatientWaitlistsAll(patientId: number, fields?: string[]) {
    const { data } = await GraphqlService.queryGql<PatientWaitlistSchema[]>({
      method: "patientWaitlistsAll",
      fields: fields ?? fieldsWaitlist,
      variables: [{ field: "patientId", value: patientId, valueType: "Int!" }]
    })
    return data;
  }

  async getPatientWaitlist(patientId: number, appointmentId: number, fields?: string[]) {
    const { data } = await GraphqlService.queryGql<PatientWaitlistSchema>({
      method: "patientWaitlist",
      fields: fields ?? fieldsWaitlist,
      variables: [
        { field: "patientId", value: patientId, valueType: "ID!" },
        { field: "appointmentId", value: appointmentId, valueType: "ID!" }
      ]
    })
    return data;
  }
  async getWaitlist(id: number, fields?: string[]) {
    return await GraphqlService.getItem<PatientWaitlistSchema>("waitlist", fields ?? fieldsWaitlist, id);
  }

  async savePatientWaitlist(input: SavePatientWaitlistInput) {
    return await GraphqlService.setItem<PatientWaitlistSchema>("savePatientWaitlist", "patientWaitlist", ["id"], input);
  }

  async changePatientWaitlistAppointment(waitlistId: number, appointmentId: number) {
    return await GraphqlService.deleteItem("changePatientWaitlistAppointment", waitlistId, {
      variables: [{ field: "appointmentId", value: appointmentId, valueType: "ID!" }]
    });
  }

  async deletePatientWaitlist(waitlistId: number) {
    return await GraphqlService.deleteItem("deletePatientWaitlist", waitlistId);
  }
}
