import type { DateTimeString } from "@/models/interfaces";
import type { CreditBrand, CreditDealType, ReceiptPaymentType } from "@/models/enums";

export interface ReceiptLineSchema {
  id: number;
  createdAt: DateTimeString;
  incomeId: number;
  paymentType: ReceiptPaymentType;
  receiptDate: DateTimeString;
  amount: number;
  chequeBankName: string;
  chequeBankBranch: string;
  chequeBankAccount: string;
  chequeNumber: string;
  chequeDate: DateTimeString | null;
  chequeDepositDone: boolean;
  transferBankName: string;
  transferBankBranch: string;
  transferBankAccount: string;
  creditNumber4Digits: string;
  creditBrand: CreditBrand | null;
  creditDealType: CreditDealType | null;
  creditPaymentsNumber: number | null;
  description: string | null;

  client: string;
}

export const receiptLineSchemaAllFields = [
  "id",
  "createdAt",
  "incomeId",
  "paymentType",
  "receiptDate",
  "amount",
  "chequeBankName",
  "chequeBankBranch",
  "chequeBankAccount",
  "chequeNumber",
  "chequeDate",
  "chequeDepositDone",
  "transferBankName",
  "transferBankBranch",
  "transferBankAccount",
  "creditNumber4Digits",
  "creditBrand",
  "creditDealType",
  "creditPaymentsNumber",
  "description",

  "client"
]
