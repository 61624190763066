export default {
  head: {
    title: "Pricelist group",
    meta: [
      { name: "description", content: "Pricelist group" }
    ]
  },

  createTitle: "Create pricelist group",
  updateTitle: "Update pricelist group {0}",

  nameUsed: "Group name is already in use",

  title: "Group name",
  active: "Group is active",
  displayOrder: "Display order"
}
