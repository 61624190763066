export default {
  title: "Prescriptions",
  table: {
    columns: {
      id: "ID",
      createdAt: "Create date",
      doctorName: "Doctor",
      systemName: "System name",
      drugName: "Drug",
      submission: "Submission",
      dose: "Dose",
      timesADay: "Times a day",
      numberOfDays: "Number of days",
      note: "Notes",
    }
  },
  print: {
    title: "Print prescriptions",
    input: {
      prescriptions: "Print prescriptions",
      instructions: "Print Instructions for use",
    },
    print: "Print"
  },
  edit: {
    title: "Prescription",
    doctor: "Doctor",
    template: "Template",
    drugName: "Drug",
    submission: "Submission",
    dose: "Dose",
    timesADay: "Times a day",
    numberOfDays: "Number of days",
    note: "Note",
    add: "Add drug",
    saveAndPrint: "Save and print"
  }
}
