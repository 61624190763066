import moment from "@/plugins/VueMomentPlugin";
import GraphqlService from "../GraphqlService";
import {
  laboratoryWorkSchemaAllFields, type LaboratoryWorkSchema,
  laboratoryWorkNoteSchemaAllFields, type LaboratoryWorkNoteSchema
} from "@/models/schemas";
import type { FilterLaboratoryWorkInput } from "@/models/api/queries/LaboratoryWorkModels";
import type { FilterLaboratoryWorkNoteInput } from "@/models/api/queries/LaboratoryWorkNoteModels";
import type { LaboratoryWorkStatus } from "@/models/enums";
import type { SaveLaboratoryWorkInput } from "@/models/api/mutations/LaboratoryWorkModels";
import type { SaveLaboratoryWorkNoteInput } from "@/models/api/mutations/LaboratoryWorkNoteModels";
import type { TabulatorParams } from "@/models/interfaces";

export default class LaboratoryWorkService {
  //#region LaboratoryWork
  async getLaboratoryWorksTabulator(params: TabulatorParams, input?: FilterLaboratoryWorkInput, fields?: string[]) {
    return await GraphqlService.getItems<LaboratoryWorkSchema>("laboratoryWorks", fields ?? laboratoryWorkSchemaAllFields, params, {
      variables: [{ field: "input", value: input, valueType: "FilterLaboratoryWorkInput" }]
    });
  }

  async getLaboratoryWork(id: number, fields?: string[]) {
    return await GraphqlService.getItem<LaboratoryWorkSchema>("laboratoryWork", fields ?? laboratoryWorkSchemaAllFields, id);
  }

  async getCurrentLaboratoryWorks() {
    const params: TabulatorParams = {
      size: 100,
      page: 1,
      filter: [
        { field: "status", type: "!=", value: "CLOSED" },
        { field: "followUp", type: "<=", value: moment().format('YYYY-MM-DDT23:59:59'), valueType: "DateTime" }
      ],
      sort: [{ field: "followUp", dir: "desc" }]
    };
    return await GraphqlService.getItems<LaboratoryWorkSchema>("laboratoryWorks", laboratoryWorkSchemaAllFields, params);
  }

  async saveLaboratoryWork(input: SaveLaboratoryWorkInput, fields: string[]) {
    if (!input.id) {
      input.id = 0;
    }
    if (!input.note) {
      input.note = "";
    }

    return await GraphqlService.setItem<LaboratoryWorkSchema>("saveLaboratoryWork", "laboratoryWork", fields, input);
  }

  async changeLaboratoryWorkStatus(id: number, status: LaboratoryWorkStatus) {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "changeLaboratoryWorkStatus",
      variables: [
        { field: "id", value: id, valueType: "ID!" },
        { field: "status", value: status, valueType: "LaboratoryWorkStatus!" },
      ]
    });
    return data ?? false;
  }
  //#endregion

  //#region LaboratoryWorkNote
  async getLaboratoryWorkNotesTabulator(params: TabulatorParams, laboratoryWorkId?: number, fields?: string[] | null, input?: FilterLaboratoryWorkNoteInput) {
    if (fields == null || fields.length === 0) {
      fields = laboratoryWorkNoteSchemaAllFields;
    }

    if (laboratoryWorkId) {
      params.filter.push({ field: "laboratoryWorkId", type: "=", value: laboratoryWorkId });
    }

    return await GraphqlService.getItems<LaboratoryWorkNoteSchema>("laboratoryWorkNotes", fields, params, {
      variables: [{ field: "input", value: input, valueType: "FilterLaboratoryWorkNoteInput" }]
    });
  }

  async saveLaboratoryWorkNote(input: SaveLaboratoryWorkNoteInput, fields: string[]) {
    return await GraphqlService.setItem<LaboratoryWorkNoteSchema>("saveLaboratoryWorkNote", "laboratoryWorkNote", fields, input);
  }
  //#endregion
}
