export default {
  head: {
    title: "דוח התחשבנות",
    meta: [
      { name: "description", content: "דוח התחשבנות" }
    ]
  },
  title: "דוח התחשבנות",
  actions: {
    download: "הורדת דוח",
    addPayment: "הוספת תשלום",
    viewHistory: "היסטוריית תשלומים"
  },
  myPayments: {
    title: "היסטוריית התשלומים",
    table: {
      columns: {
        createdAt: "תאריך",
        amount: "סכום",
        transactionDetails: "פרטי תשלום"
      }
    }
  },
  myTreatments: {
    title: "קבלות ששולמו ולא בוצע עבורתם תשלום לרופא",
    table: {
      columns: {
        treatmentDate: "תאריך טיפול / תוכנית טיפול",
        treatmentDescription: "תיאור",
        patientName: "מטופל",
        incomeId: "קבלה",
        amount: "סכום"
      }
    }
  },
  paymentsToDoctors: {
    title: "דוח תשלומים לרופאים",
    summary: "סיכום",
    table: {
      columns: {
        doctorName: "רופא",
        amountOwed: "סכום חוב"
      }
    }
  },
  openTreatments: {
    title: "קבלות ששולמו ולא בוצע עבורתם תשלום לרופא",
    table: {
      columns: {
        treatmentDate: "תאריך טיפול / תוכנית טיפול",
        treatmentDescription: "תיאור",
        doctorName: "רופא",
        patientName: "מטופל",
        incomeId: "מסמך",
        amount: "סכום"
      }
    }
  },
  paymentsHistoryPopup: {
    titlePart: "תשלומים שבוצעו לרופא",
    removePaymentWarn: "האם למחוק את התשלום?",
    table: {
      columns: {
        createdAt: "תאריך",
        amount: "סכום",
        transactionDetails: "פרטי תשלום"
      }
    }
  },
  addPaymentPopup: {
    titlePart: "הוספת תשלום לרופא",
    amountLabel: "סכום",
    detailsLabel: "פרטי תשלום",
    table: {
      columns: {
        treatmentDate: "תאריך טיפול / תוכנית טיפול",
        incomeId: "קבלה",
        calculatedAmountForDoctor: "סכום"
      }
    }
  }
}
