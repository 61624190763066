import { HttpService } from "@/services/HttpService";
import IncomeService from "@/services/IncomeService";
import { AutoMapper } from "@/utils";
import { DocumentType, ExpensePaymentType, ReceiptPaymentType } from "@/models/enums";
import type { FilterTaskInput } from "@/models/api/queries/TaskModels";
import type { FilterLeadInput } from "@/models/api/queries/LeadModels";
import type { FilterLaboratoryWorkInput } from "@/models/api/queries/LaboratoryWorkModels";
import type { FilterPatientInput } from "@/models/api/queries/PatientModels";
import type { FilterSupplierInput } from "@/models/api/queries/SupplierModels";
import type { FilterAttendanceReportInput } from "@/models/api/queries/AttendanceModels";
import type { FilterExpenseInput, FilterExpenseReportsInput } from "@/models/api/queries/ExpensesModels";
import type { FilterIncomeInput, FilterIncomeReportsInput } from "@/models/api/queries/IncomeModels";
import type { CollectionReportInput, AccountingReportInput } from "@/models/api/queries/FinancialReportModels";

export default class ExportService {
  private async createObjectURL(apiPath: string, formData: FormData | object, defaultFilename: string) {
    const response = await HttpService.postRequest<Blob, FormData | object>(apiPath, {
      data: formData,
      responseType: 'blob',
      validateStatus: (s) => s <= 500,
      useAuth: true
    })
    if (response.status === 200) {
      const contentDisposition = response.headers["content-disposition"];
      const filenameMatch = contentDisposition?.match(/filename=(.+);/);
      const filename = filenameMatch ? decodeURIComponent(filenameMatch[1]) : defaultFilename; // https://stackoverflow.com/a/56999952/22548940
      const blob = response.data;
      const link = document.createElement("a");
      link.download = filename;
      link.href = window.URL.createObjectURL(blob);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      // https://github.com/axios/axios/issues/3779#issuecomment-1002739087
      console.log(await response.data.text());
      const errorMessage = response.statusText || 'Unknown error';
      throw new Error(`Error: ${response.status} - ${errorMessage}`);
    }
  }

  public async exportTasks(filter: FilterTaskInput) {
    await this.createObjectURL("export/tasks", filter, "TasksExport.xlsx");
  }

  public async exportLeads(filter: FilterLeadInput) {
    await this.createObjectURL("export/leads", filter, "LeadsExport.xlsx");
  }

  public async exportChannels() {
    await this.createObjectURL("export/channels", {}, "ChannelsExport.xlsx");
  }

  public async exportLaboratoryWorks(filter: FilterLaboratoryWorkInput) {
    await this.createObjectURL("export/laboratory-works", filter, "LaboratoryWorksExport.xlsx");
  }

  public async exportPatients(filter: FilterPatientInput) {
    await this.createObjectURL("export/patients", filter, "PatientsExport.xlsx");
  }

  public async exportExpenseTypes() {
    await this.createObjectURL("export/expense-types", {}, "ExpenseTypesExport.xlsx");
  }

  public async exportDepositCash() {
    await this.createObjectURL("export/deposit-cash", {}, "DepositCashExport.xlsx");
  }

  public async exportSuppliers(filter?: FilterSupplierInput) {
    await this.createObjectURL("export/suppliers", filter ?? {}, "SuppliersExport.xlsx");
  }

  public async exportAttendances(filter: FilterAttendanceReportInput) {
    await this.createObjectURL("export/attendances", filter, "AttendancesExport.xlsx");
  }

  public async exportExpenses(filter?: FilterExpenseInput) {
    function mapDocument(document: DocumentType) {
      switch (document) {
        case DocumentType.PaymentConfirmation: return 1;
        case DocumentType.Invoice: return 2;
        case DocumentType.InvoiceReceipt: return 3;
        case DocumentType.CreditInvoice: return 4;
        case DocumentType.Receipt: return 5;
        case DocumentType.DonationReceipt: return 6;
        default: throw new Error("something went wrong");
      }
    }
    function mapPayment(payment: ExpensePaymentType) {
      switch (payment) {
        case ExpensePaymentType.Check: return 1;
        case ExpensePaymentType.BankTransfer: return 2;
        case ExpensePaymentType.CreditCard: return 3;
        case ExpensePaymentType.Paypal: return 4;
        case ExpensePaymentType.Cash: return 5;
        case ExpensePaymentType.PaymentApp: return 6;
        case ExpensePaymentType.Other: return 7;
        default: throw new Error("something went wrong");
      }
    }
    const data: any = Object.assign({}, filter ?? {});
    if (filter) {
      data.documents = filter.documents?.map(doc => mapDocument(doc)) ?? [];
      data.payments = filter.payments?.map(pay => mapPayment(pay)) ?? [];
    }
    await this.createObjectURL("export/expenses", data, "ExpensesExport.xlsx");
  }

  public async exportExpenseReports(filter: FilterExpenseReportsInput) {
    await this.createObjectURL("export/expense-reports", filter, "ExpenseReportsExport.zip");
  }

  public async exportIncomes(filter: FilterIncomeInput) {
    function mapPayment(payment: ReceiptPaymentType) {
      switch (payment) {
        case ReceiptPaymentType.Cheque: return 1;
        case ReceiptPaymentType.BankTransfer: return 2;
        case ReceiptPaymentType.CreditCard: return 3;
        case ReceiptPaymentType.Cash: return 4;
        case ReceiptPaymentType.WithHoldingTax: return 5;
        default: throw new Error("something went wrong");
      }
    }
    const data: any = AutoMapper.map(filter, {}, true);
    if (filter) {
      data.incomeTypes = filter.incomeTypes?.map(type => IncomeService.mapIncomeType(type)) ?? [];
      data.incomeStatuses = filter.incomeStatuses?.map(status => IncomeService.mapIncomeStatus(status)) ?? [];
      data.paymentTypes = filter.paymentTypes?.map(pay => mapPayment(pay)) ?? [];
    }
    await this.createObjectURL("export/incomes", data, "IncomesExport.xlsx");
  }

  public async exportIncomeReports(filter: FilterIncomeReportsInput) {
    const data: any = Object.assign({}, filter, { incomeTypes: filter.incomeTypes?.map(type => IncomeService.mapIncomeType(type)) ?? [] });
    await this.createObjectURL("export/income-reports", data, "IncomeReportsExport.zip");
  }
  public async exportIncomeReportsTax(filter: FilterIncomeReportsInput) {
    const data: any = Object.assign({}, filter, { incomeTypes: filter.incomeTypes?.map(type => IncomeService.mapIncomeType(type)) ?? [] });
    await this.createObjectURL("export/income-reports-tax", data, "IncomeReportsExportTax.zip");
  }
  public async exportIncomeReportsForms(filter: FilterIncomeReportsInput) {
    const data: any = Object.assign({}, filter, { incomeTypes: filter.incomeTypes?.map(type => IncomeService.mapIncomeType(type)) ?? [] });
    await this.createObjectURL("export/income-reports-forms", data, "IncomeReportsExportForms.zip");
  }

  public async exportCollectionReports(filter: CollectionReportInput) {
    await this.createObjectURL("export/collection-reports", filter, "CollectionExport.xlsx");
  }

  public async exportAccountingReports(filter: AccountingReportInput) {
    await this.createObjectURL("export/accounting-reports", filter, "AccountingReports.xlsx");
  }
}
