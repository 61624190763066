export const Keys = {
  Document: {
    ProformaInvoiceText: "DocumentContentProformaInvoiceText",
    InvoiceText: "DocumentContentInvoiceText",
    InvoiceReceiptText: "DocumentContentInvoiceReceiptText",
    ReceiptText: "DocumentContentReceiptText",
    EstimateText: "DocumentContentEstimateText",
    ReceiptRefoundText: "DocumentContentReceiptRefoundText",
    InvoiceRefoundText: "DocumentContentInvoiceRefoundText"
  }
} as const;