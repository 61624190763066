export default {
  head: {
    title: "ספקים",
    meta: [
      { name: "description", content: "ספקים" }
    ]
  },
  validation: {
    preventDeleteSupplier: "לספק זה קיימות הוצאות. לא ניתן למחוק ספק עם הוצאות."
  },
  buttons: {
    add: "הוספת ספק",
    download: "הורדת Excel",
    edit: "עריכה"
  },
  options: {
    all: "הכל",
    active: "פעילים",
    notActive: "לא פעילים"
  },
  search: {
    placeholder: "חיפוש",
    tooltip: "חפשו לפי שם או ח.פ."
  },
  form: {
    edit: "עריכת ספק",
    create: "הוספת ספק",
    name: "שם",
    taxId: "ח.פ.",
    department: "מחלקה",
    active: "פעיל",
    contacts: "אנשי קשר",
    contactPerson: "שם איש קשר",
    phone1: "טלפון",
    phone2: "נייד",
    email1: 'דוא"ל 1',
    email2: 'דוא"ל 2',
    email3: `דוא"ל 3`,
    fax: "פקס",
    address: "כתובת",
    city: "עיר",
    zip: "מיקוד",
    country: "מדינה",
    account: "חשבון",
    paymentTerms: "תנאי תשלום",
    supplierKey: "מספר ספק",
    bankName: "שם בנק",
    bankBranch: "סניף",
    bankAccount: "מספר חשבון",
    notes: "הערות",
    submit: "לשמור"
  },
  paymentTerms: {
    immediate: "מיידי",
    currentMonth: "שותף",
    currentMonth10: "שותף + 10",
    currentMonth30: "שותף + 30",
    currentMonth60: "שותף + 60",
    currentMonth90: "שותף + 90"
  },
  table: {
    columns: {
      title: "שם",
      taxId: "ח.פ.",
      contactPerson: "איש קשר",
      phone: "טלפון",
      email1: "דוא\"ל",
      active: "פעיל"
    }
  }
}
