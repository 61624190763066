export default {
  edit: {
    followUp: "Follow up",
    status: "Status",
    firstName: "First Name",
    lastName: "Last Name",
    phone: "Phone",
    email: "Email",
    service: "Service",
    channel: "Channel",
    otherData: "Other Data",
    note: "Notes",
  },
  notesTable: {
    columns: {
      id: "ID",
      createdAt: "Created",
      text: "Note"
    }
  },
  title: "Potential patient",
  addFile: "Add File",
  addNote: "Add Note",
  addNotes: "Add Notes",
  addedFile: "Added file",
  assignee: "Assignee",
  attachmentSaved: "Attachment saved",
  created: "Created",
  followUp: "Follow up",
  history: "History",
  noteSaved: "Note saved",
  notes: "Notes",
  patient: "Patient",
  saveLeadBeforeUpload: "Please save Lead before uploading attachments",
  status: "Status",
  save: "Save Lead",
  leadSaved: "Lead saved",
  emailOrPhoneValidation: "Email or phone is required"
};
