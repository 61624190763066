import type { DateTimeString } from "@/models/interfaces";

export interface DoctorPaymentSchema {
  id: number;
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
  deletedAt?: DateTimeString;
  amount: number;
  transactionDetails?: string;
  doctorId: number;
  doctorName: string;
}

export const doctorPaymentSchemaAllFields = [
  "id",
  "createdAt",
  "updatedAt",
  "deletedAt",
  "amount",
  "transactionDetails",
  "doctorId",
  "doctorName"
];