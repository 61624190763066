import type { Result } from "@/models/interfaces";
import GraphqlService from "../GraphqlService";

export default class PaymentService {
  async createTranzilaIframeUrl(patientId: number, incomeId: number, sum: number, maxPay: number) {
    const { data } = await GraphqlService.mutateGql<string>({
      method: "createTranzilaIframeUrl",
      variables: [
        { field: "patientId", value: patientId, valueType: "ID!" },
        { field: "incomeId", value: incomeId, valueType: "ID!" },
        { field: "sum", value: sum, valueType: "Decimal!" },
        { field: "maxPay", value: maxPay, valueType: "Int!" },
      ]
    })
    return data;
  }

  async createTranzilaEmvPayment(patientId: number, incomeId: number, sum: number, maxPay: number) {
    const { data } = await GraphqlService.mutateGql<Result>({
      method: "createTranzilaEmvPayment",
      fields: "success message exception",
      variables: [
        { field: "patientId", value: patientId, valueType: "ID!" },
        { field: "incomeId", value: incomeId, valueType: "ID!" },
        { field: "sum", value: sum, valueType: "Decimal!" },
        { field: "maxPay", value: maxPay, valueType: "Int!" },
      ]
    })
    return data;
  }
}
