import { defineStore } from "pinia";
import { ref } from "vue";
import { useServices } from "@/services";

const subscription = ref<any>(null);

export const useMessagesStore = defineStore({
  id: "messages",
  state: () => ({
    unreadMessages: 0 as number
  }),
  actions: {
    async fetchCurrentUnreadMessagesCount() {
      try {
        this.$state.unreadMessages = await useServices().message.getTotalUnreadMessagesCount();
      } catch (error) {
        console.log(error);
      }
    },
    async subscribe() {
      let messagesStore = this;
      if (!subscription.value) {
        subscription.value = useServices().message.subscribeUnreadMessages({
          onResult(data) {
            messagesStore.unreadMessages = data.unreadMessages;
          },
          onError() {
            subscription.value = null;
            setTimeout(() => messagesStore.subscribe(), 3000);
          }
        });
      }
    }
  }
});
