export default {
  head: {
    title: "Prescription templates",
    meta: [
      { name: "description", content: "Prescription templates description" }
    ]
  },
  table: {
    columns: {
      id: "ID",
      systemName: "Prescription name",
      drugName: "Drug",
      submission: "Submission",
      dose: "Dose",
      timesADay: "Times a day",
      numberOfDays: "Number of days",
      active: "Active"
    },
  },
  add: "Add prescription template",
  searchByDrugName: "Search by drug name"
}
