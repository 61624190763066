export default {
  title: "משפחה",
  message: ["מטופל זה קשור למשפחה של", "כדי לנתק שיוך,", "לחץ כאן"],
  table: {
    columns: {
      id: "ID",
      createdAt: "תאריך",
      type: "סוג קשר",
      familyFirstName: "שם פרטי",
      familyLastName: "שם משפחה",
      note: "הערות",
    }
  },
  edit: {
    title: "הוספת מטופל לכרטיס",
    type: "סוג קשר",
    patient: "מטופל",
    note: "הערות",
    types: {
      NONE: "None",
      FATHER: "אב",
      MOTHER: "אם",
      BROTHER_OR_SISTER: "אח/אחות",
      PARTNER: "בת זוג",
      CHILD: "ילד",
      GRANDFATHER: "סבא",
      GRANDMOTHER: "סבתא",
      OTHER: "אחר"
    },
  }
}
