<template>
  <div class="p-formkit">
    <BDropdown
      v-bind="context.attrs"
      :class="styleClass"
      auto-close="outside"
      variant="outline"
      toggle-class="btn-outline-light"
      class="dropdown--select-style"
      :text="props.context.label">
      <template #button-content>
        {{ props.context._value }}
      </template>
      <BDropdownText class="teeth-grid-w">
        <div class="teeth-grid">
          <div v-for="(teeth, i1) in teethOptions" :class="`teeth-quadrant teeth-quadrant--${i1 + 1}`">
            <template v-for="(tooth, i2) in teeth.fixed">
              <div class="tooth-col" :key="tooth.id" v-if="denture != TreatmentDenture.Removable || teeth.removable[i2]">
                <template v-if="[TreatmentDenture.Fixed, TreatmentDenture.Mixed].includes(denture)">
                  <div :class="{ box: true, 'is-selected': tooth.value }" @click="toggleBox(tooth.id)">{{ tooth.id }}</div>
                </template>
                <template v-if="[TreatmentDenture.Removable, TreatmentDenture.Mixed].includes(denture)">
                  <div v-if="teeth.removable[i2]" :class="{ box: true, 'is-selected': teeth.removable[i2]?.value }" @click="toggleBox(teeth.removable[i2]?.id)">
                    {{ teeth.removable[i2]?.id }}
                  </div>
                </template>
              </div>
            </template>
          </div>
        </div>
      </BDropdownText>
    </BDropdown>
  </div>
</template>

<script setup lang="ts">
  import { ref, computed, watch, onMounted, type PropType } from "vue";
  import type { FormKitFrameworkContext } from "@formkit/core";
  import { TreatmentDenture } from "@/models/enums";

  const props = defineProps({
    context: {
      type: Object as PropType<FormKitFrameworkContext>,
      required: true
    }
  });

  function setValue(value: string | null) {
    const teeth = value?.split(",").map((x) => parseInt(x)) ?? [];
    teethOptionsArray.value.forEach((x) => (x.value = teeth.includes(x.id)));
  }

  watch(
    () => props.context._value,
    (v1: string) => setValue(v1)
  );

  onMounted(() => {
    setValue(props.context._value);
  });

  const denture = computed<TreatmentDenture>(() => props.context.attrs.denture ?? TreatmentDenture.Mixed);
  const dentureView = {
    FIXED: [
      [[18], [17], [16], [15], [14], [13], [12], [11]],
      [[21], [22], [23], [24], [25], [26], [27], [28]],
      [[48], [47], [46], [45], [44], [43], [42], [41]],
      [[31], [32], [33], [34], [35], [36], [37], [38]]
    ],
    REMOVABLE: [
      [[55], [54], [53], [52], [51]],
      [[61], [62], [63], [64], [65]],
      [[85], [84], [83], [82], [81]],
      [[71], [72], [73], [74], [75]]
    ],
    MIXED: [
      [[18], [17], [16], [15, 55], [14, 54], [13, 53], [12, 52], [11, 51]],
      [[21, 61], [22, 62], [23, 63], [24, 64], [25, 65], [26], [27], [28]],
      [[48], [47], [46], [45, 85], [44, 84], [43, 83], [42, 82], [41, 81]],
      [[31, 71], [32, 72], [33, 73], [34, 74], [35, 75], [36], [37], [38]]
    ]
  };

  const teethOptions = ref(
    [0, 1, 2, 3].map((index) => ({
      fixed: dentureView.MIXED[index].map((x) => ({ id: x[0], value: false })),
      removable: dentureView.MIXED[index].map((x) => (x[1] ? { id: x[1], value: false } : null))
    }))
  );
  const teethOptionsArray = computed(() => teethOptions.value.flatMap((x) => x.fixed.concat(x.removable.filter((y) => !!y) as any)));

  const teethSelectedOptions = computed(() => {
    return teethOptionsArray.value
      .filter((tooth) => tooth.value)
      .map((tooth) => tooth.id)
      .join(",");
  });

  const toggleBox = (id?: number) => {
    const boxValue = teethOptionsArray.value.find((tooth) => tooth.id === id);
    if (boxValue) {
      boxValue.value = !boxValue.value;
      props.context.node.input(teethSelectedOptions.value);
    }
  };

  const styleClass = computed(() =>
    props.context.state.validationVisible && !props.context.state.valid ? `${props.context.attrs?.class} p-invalid` : props.context.attrs?.class
  );
</script>

<style lang="scss" scoped>
  .teeth-grid-w {
    :deep(p) {
      padding: 4px 12px !important;
    }
  }
  .teeth-grid {
    direction: ltr;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    .teeth-quadrant {
      display: flex;
      gap: 6px;
      &.teeth-quadrant--1 {
        border-width: 0 1px 1px 0;
        border-style: solid;
        border-color: $primary;
        padding: 0 4px 4px 0;
        @media (max-width: 600px) {
          padding: 0 1px 1px 0;
        }
        .tooth-col {
          gap: 4px;
          display: flex;
          flex-direction: column;
        }
      }
      &.teeth-quadrant--2 {
        border-width: 0 0 1px 0;
        border-style: solid;
        border-color: $primary;
        padding: 0 0 4px 4px;
        @media (max-width: 600px) {
          padding: 0 0 1px 1px;
        }
        .tooth-col {
          gap: 4px;
          display: flex;
          flex-direction: column;
        }
      }
      &.teeth-quadrant--3 {
        border-width: 0 1px 0 0;
        border-style: solid;
        border-color: $primary;
        padding: 4px 4px 0 0;
        @media (max-width: 600px) {
          padding: 1px 1px 0 0;
        }
        .tooth-col {
          gap: 4px;
          display: flex;
          flex-direction: column-reverse;
        }
      }
      &.teeth-quadrant--4 {
        padding: 4px 0 0 4px;
        @media (max-width: 600px) {
          padding: 1px 0 0 1px;
        }
        .tooth-col {
          gap: 4px;
          display: flex;
          flex-direction: column-reverse;
        }
      }
    }
    .box {
      width: 21px;
      height: 21px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 12px;
      background: #cde0fc;
      cursor: pointer;
      transition: all 0.2s;
      &:hover {
        background: darken(#cde0fc, 5%);
      }
      &.is-selected {
        background: $primary;
        color: #fff;
      }
    }
  }
</style>
