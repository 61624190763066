export default {
  title: "Payments",
  buttons: {
    add: "Document production",
    addCopy: "Add copy",
    addRelated: "Add related",
    addCardPayment: "Add card payment",
    send: "Send",
    view: "View",
    reopen: "Reopen the document",
    download: "Download"
  },
  form: {
    title: "Add document",
    documentType: "Document type",
    connect: "Connect to treatment or treatment plan",
    submit: "Create",
    typeError: "Not supported income type",
    receiptError: "Receipt type not support invoice lines",
    total: "Total",
    treatmentPlans: {
      title: "Treatment plans",
      table: {
        columns: {
          createdAt: "Create date",
          status: "Status",
          total: "Total",
          due: "Due"
        }
      }
    },
    treatments: {
      title: "Treatments",
      table: {
        columns: {
          createdAt: "Create date",
          description: "Treatment",
          statusTitle: "Status",
          due: "Due"
        }
      }
    }
  },
  formCard: {
    title: "Add card payment",
    subTotal: "Subtotal",
    vat: "Vat",
    rounding: "Rounding",
    total: "Total",
    submit: "Proceed to payment",
    payment: {
      amount: "Amount to be paid",
      vatType: "VAT Type",
      vat: "VAT",
      reason: "Payment reason",
      method: "Payment via",
      terminal: "Terminal",
      form: "Manual form",
      link: {
        btn: "Send link",
        select: "How to send a link for payment",
        options: {
          email: "Email",
          whatsapp: "Whatsapp",
          sms: "SMS",
        },
        input: "To whom to send a payment link",
      },
      submit: "Proceed to payment",
    }
  },
  table: {
    columns: {
      id: "ID",
      createdAt: "Created at",
      financialNumber: "Number",
      incomeType: "Type",
      incomeStatus: "Status",
      description: "Description",
      paymentTypes: "Paid by",
      amount: "Amount",
      debit: "Debit",
      credit: "Credit",
      balance: "Balance",

      documentDate: "Document date",
      patientName: "For",
      doctorName: "Doctor",
      payAmount: "Amount",
      paid: "Payed",
      treatmentIds: "Treatments",
      treatmentPlanIds: "Treatment plans",
    }
  }
}
