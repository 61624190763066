export default {
  head: {
    title: "דוח חייבים",
    meta: [
      { name: "description", content: "דוח חייבים" }
    ]
  },
  title: "דוח חייבים",
  period: 'סה"כ לתקופה',
  chargesTitle: 'חיובים פתוחים שעדיין לא שולמו',
  clientsTitle: 'לקוחות עיקריים',
  barChartLabel: "הכנסות",
  dueDateTo: "תאריך תשלום",
  clients: {
    title: "רשימת חייבים",
    table: {
      columns: {
        fullName: 'שם הלקוח',
        incomeIds: 'מסמכים פתוחים',
        total: 'יתרת חובה'
      }
    }
  },
  charges: {
    title: 'חיובים פתוחים',
    treatment: "טיפול",
    treatmentPlan: "תוכנית טיפול",
    table: {
      columns: {
        financialNumber: "מס'",
        documentDate: "תאריך",
        dueDate: "לתשלום עד",
        incomeType: "סוג המסמך",
        patientName: "הלקוח",
        description: "תיאור",
        payAmount: "סכום",
      }
    }
  },
  actions: {
    notify: 'הודע ללקוח',
    downloadExcel: "הורדת אקסל",
    downloadPdf: "הורד PDF",
    view: "צפיה",
    add: "הפקת מסמך חדש מתוך מסמך זה",
    send: "שליחת מסמך"
  }
}
