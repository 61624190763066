export default {
  head: {
    title: "ערוץ",
    meta: [{ name: "description", content: "ערוץ פרסום" }]
  },
  newChannel: "הוספת ערוץ",
  name: "שם ערוץ",
  active: "פעיל",
  connection: {
    api: "חיבור API",
    zapier: "חיבור Zapier",
    iframe: "חיבור IFrame",
    email: "שלחו למתכנת כדי לחבר ערוץ",
  },
  email: "דוא\"ל של מתכנת",
  emailSent: "הודעה נשלחה למתכנת",
  tabs: {
    api: {
      swaggerLink: "לינק ל Swagger בו רשמנו הוראות התחברות",
    },
    zapier: {
      zapLink: "השתמש בלינק זה ליצירת Zap",
    },
    iframe: {
      useLink: "השתמש בלינק זה כדי להטמיע Iframe",
    }
  }
};
