<template>
  <div class="p-formkit">
    <Ckeditor
      ref="wrapper"
      tag-name="textarea"
      :editor="ClassicEditor"
      :config="config"
      :class="styleClass"
      :disabled="attrs._disabled ?? context.disabled ?? false"
      :modelValue="context._value ?? ''"
      @update:modelValue="handleInput($event)" />
  </div>
</template>

<script setup lang="ts">
  import "ckeditor5/ckeditor5.css";
  import { Ckeditor } from "@ckeditor/ckeditor5-vue";
  import { ClassicEditor, Bold, Essentials, Italic, Mention, Paragraph, Undo } from "ckeditor5";
  import { ref, computed, onMounted } from "vue";
  import { useI18n } from "vue-i18n";
  import { Popup } from "@/utils";

  const { locale } = useI18n();
  const props = defineProps<{ context: any }>();

  const context = props.context;
  const config = {
    plugins: [Bold, Essentials, Italic, Mention, Paragraph, Undo],
    toolbar: ["undo", "redo", "|", "bold", "italic"],
    language: locale.value
  };

  const wrapper = ref();

  const editor = computed(() => <ClassicEditor>wrapper.value.instance);
  const attrs = computed(() => context?.attrs);
  const styleClass = computed(() => (context?.state.validationVisible && !context?.state.valid ? `${attrs.value?.class} p-invalid` : attrs.value?.class)); // todo: make work later

  function handleInput(e: any) {
    context?.node.input(e);
  }

  function insertText(text: string) {
    try {
      const model = editor.value?.model;
      if (model) {
        model.change((writer) => {
          // todo: always append to the end
          const position = model.document.selection.getFirstPosition();
          if (position) writer.insertText(text, position);
        });
      }
    } catch (error) {
      Popup.error(error);
      console.log(error);
    }
  }

  onMounted(() => {
    try {
      if (wrapper.value) {
        context.insertText = insertText;
      } else {
        console.log("[CkMessageEditor] current value:");
        console.log(wrapper.value);
      }
    } catch (error) {
      Popup.error(error);
      console.log(error);
    }
  });
</script>
