export default {
  title: "Open tasks",
  table: {
    columns: {
      id: "ID",
      createdAt: "Create date",
      followUp: "Follow up date",
      assigneeName: "Assignee",
      title: "Title",
    }
  }
}
