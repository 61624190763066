import type { App, Plugin } from "vue";
import PrimeVue from "primevue/config";

export const PrimeVuePlugin: Plugin = {
  install(app: App) {
    // https://primevue.org/installation/
    // https://stackoverflow.com/a/74245350/22548940
    app.use(PrimeVue);

    // https://github.com/primefaces/primevue/issues/1450#issuecomment-900157032
    //app.use(primevue.config.default)
  }
}

// https://primevue.org/theming/#themes
import "primevue/resources/themes/bootstrap4-light-blue/theme.css";
