import type { DateTimeString } from "@/models/interfaces";
import type { MessageProvider } from "@/models/enums";

export interface AppointmentReminderStatusModel {
  time: DateTimeString;
  type: MessageProvider;
  data: string;
}

export interface AppointmentReminderSchema {
  id: number;
  createdAt: DateTimeString;
  type: MessageProvider;
  data: string;

  firstName?: string;
  lastName?: string;
  surName?: string;
  photo?: string;

  userId: number;
  serviceId?: number;
  patientId?: number;

  appointmentId: number;
  appointmentTime?: DateTimeString;

  fullName?: string;
  defaultDoctorId: number;
  defaultDoctorName?: string;
  defaultDoctorImage?: string;

  approved?: boolean;
  hasUnreadChatMessage: boolean;
  lastUnreadChatMessage: string;

  appointmentReminders?: AppointmentReminderStatusModel[];
}

export const appointmentReminderSchemaAllFields = `
  id
  serviceName
  appointmentTime

  appointmentId
  patientId
  serviceId
  userId

  fullName
  defaultDoctorName
  defaultDoctorImage
  approved
  hasUnreadChatMessage
  lastUnreadChatMessage

  appointmentReminders {
    time
    type
    data
  }
`;