export default {
  head: {
    title: "שגיאה במערכת. בבקשה לפנות לתמיכה",
    meta: [
      { name: "description", content: "MedForm - שגיאה במערכת. בבקשה לפנות לתמיכה" }
    ]
  },
  error: "שגיאת שרת",
  back: "חזרה לדשבורד"
}
