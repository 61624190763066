import GraphqlService from "../GraphqlService";

export default class RecallArchiveService {
    async saveRecallArchive(appointmentId: number) {
        const { data } = await GraphqlService.mutateGql<boolean>({
            method: "saveRecallArchive",
            variables: [
                { field: "appointmentId", value: appointmentId, valueType: "Int!" },
            ]
        });
        return data ?? false;
    }
}