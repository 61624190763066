import GraphqlService from "../GraphqlService";
import { appointmentReminderSchemaAllFields as fields, type AppointmentReminderSchema } from "@/models/schemas";
import type { TabulatorParams } from "@/models/interfaces";
import type { FilterAppointmentReminderInput } from "@/models/api/queries/AppointmentReminderModels";
import type { MessageProvider, ReminderLinkType } from "@/models/enums";

export default class AppointmentReminderService {
  async getAppointmentRemindersAll(params: TabulatorParams, input?: FilterAppointmentReminderInput) {
    return await GraphqlService.getItems<AppointmentReminderSchema>("appointmentRemindersAll", fields, params, {
      variables: [{ field: "input", value: input, valueType: "FilterAppointmentReminderInput" }]
    });
  }

  async getAppointmentReminder(appointmentId: number) {
    const { data } = await GraphqlService.queryGql<AppointmentReminderSchema>({
      method: "appointmentReminder",
      fields: fields,
      variables: [{ field: "appointmentId", value: appointmentId, valueType: "Int!" }]
    });
    return data ?? null;
  }

  async sendAppointmentReminders(appointmentIds: number[], platform: MessageProvider, messageTemplateId: number | null, messageText: string, subject: string, reminderLinkType: ReminderLinkType, input?: FilterAppointmentReminderInput): Promise<boolean> {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "sendAppointmentReminders",
      variables: [
        { field: "appointmentIds", value: appointmentIds, valueType: "[Int!]" },
        { field: "platform", value: platform, valueType: "MessageProvider!" },
        { field: "text", value: messageText, valueType: "String" },
        { field: "subject", value: subject, valueType: "String" },
        { field: "messageTemplateId", value: messageTemplateId, valueType: "Int" },
        { field: "input", value: input, valueType: "FilterAppointmentReminderInput" },
        { field: "reminderLinkType", value: reminderLinkType, valueType: "ReminderLinkType!" },
      ]
    });
    return data ?? false;
  }

  async sendReminder(appointmentId: number, platform: MessageProvider, destination: string, messageText: string, reminderLinkType: ReminderLinkType, subject?: string, attachmentIds?: number[]): Promise<boolean> {
    const { data } = await GraphqlService.mutateGql<boolean>({
      method: "sendReminder",
      variables: [
        { field: "appointmentId", value: appointmentId, valueType: "Int!" },
        { field: "platform", value: platform, valueType: "MessageProvider!" },
        { field: "destination", value: destination, valueType: "String" },
        { field: "text", value: messageText, valueType: "String" },
        { field: "subject", value: subject, valueType: "String" },
        { field: "attachmentIds", value: attachmentIds || [], valueType: "[Int!]" },
        { field: "reminderLinkType", value: reminderLinkType, valueType: "ReminderLinkType!" },
      ]
    });
    return data ?? false;
  }
}
