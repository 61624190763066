import GraphqlService from "@/services/GraphqlService";
import { ref } from "vue";
import { useServices } from "@/services";
import { defineStore } from "pinia";
import { AutoMapper } from "@/utils";
import type { NotificationSchema } from "@/models/schemas";

const subscription = ref<any>(null);

export const useNotificationsStore = defineStore({
  id: 'notifications',
  state: () => ({
    bottomNotifications: [] as NotificationSchema[],
    currentNotifications: [] as NotificationSchema[],
    currentNotificationsCount: 0 as number
    // todo: [nav-bar-notifications.vue].loadMore().
    // For now this [currentNotificationsCount] property not used,
    // and if more then 100 [currentNotifications] items,
    // it's not appear in [nav-bar-notifications.vue].
  }),
  actions: {
    removeBottomNotification(id: number) {
      const notification = this.bottomNotifications.find((x) => x.id === id);
      if (notification) this.bottomNotifications = this.bottomNotifications.filter((x) => x.id !== id);
    },
    async fetchCurrentNotifications() {
      try {
        const services = useServices();
        const notifications = await services.notification.getCurrentNotifications();
        const notificationItems: NotificationSchema[] = [];
        notifications.items.forEach(async (notification: NotificationSchema) => {
          const newNotification = {} as NotificationSchema;
          AutoMapper.map(notification, newNotification, true);
          notificationItems.push(newNotification);

          if (notification.showPopup) {
            this.bottomNotifications.push(newNotification);
            await services.notification.hideNotificationPopup(newNotification.id);
          }
        });
        this.currentNotifications = notificationItems;
        this.currentNotificationsCount = notifications.totalCount;
      } catch (error) {
        console.log(error);
      }
    },
    async subscribe() {
      const notificationStore = this;
      if (!subscription.value) {
        subscription.value = await GraphqlService.subscribeSchema("NotificationSchema", {
          onResult(data) {
            notificationStore.fetchCurrentNotifications();
          },
          onError(error) {
            console.log("[notifications] subscribe", error);
            subscription.value = null;
            setTimeout(() => notificationStore.subscribe(), 3000);
          }
        });
      }
    },
  }
})
