import GraphqlService from "../GraphqlService";
import { userPermissionsSchemaAllFields as fields, type UserPermissionsSchema } from "@/models/schemas";
import type { UpdateUserPermissionsInput } from "@/models/api/mutations/UserModels";

export default class UserPermissionsService {
  async getMyPermissions() {
    const { data } = await GraphqlService.queryGql<UserPermissionsSchema>({ method: "myPermissions", fields: fields })
    return data;
  }

  async getUserPermissions(id: number) {
    return await GraphqlService.getItem<UserPermissionsSchema>("userPermissions", fields, id);
  }

  async updateUserPermissions(input: UpdateUserPermissionsInput) {
    return await GraphqlService.setItem<UserPermissionsSchema>("updateUserPermissions", "userPermissions", fields, input);
  }
}
