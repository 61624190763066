export default {
  head: {
    title: "תבנית מסמך",
    meta: [
      { name: "description", content: "תבנית מסמך" }
    ]
  },

  createTitle: "יצירת תבנית מסמך חדשה",
  updateTitle: "עדכן תבנית מסמך מספר {0}",

  title: "כותרת",
  text: "טקסט",
  active: "פעיל",

  nameUsed: "שם תבנית המסמך כבר בשימוש",
  updateAndPreview: "עידכון וצפיה בתצוגה מקדימה",
}
