import type { WhatsappSessionLogoutModel, WhatsappSessionQrModel, WhatsappSessionStatusModel, WhatsappUserInfoModel, WhatsappUserCheckModel } from "@/models/api/queries/WhatsappModels";
import GraphqlService from "../GraphqlService";

export default class WhatsappService {
  async getWhatsappUserCheck(phone: string) {
    const { data } = await GraphqlService.queryGql<WhatsappUserCheckModel>({
      method: "whatsappUserCheck",
      fields: ["code", "success", "error", "isInWhatsapp", "verifiedName"],
      variables: [{ field: "phone", value: phone, valueType: "String!" }],
    })
    return data as WhatsappUserCheckModel;
  }

  /*
  async getWhatsappUserInfo(phone: string) {
    const { data } = await GraphqlService.queryGql<WhatsappUserInfoModel>({
      method: "whatsappUserInfo",
      fields: ["code", "success", "error", "devices", "verifiedName"],
      variables: [{ field: "phone", value: phone, valueType: "String!" }],
    })
    return data as WhatsappUserInfoModel;
  }
  */

  async getWhatsappSessionStatus() {
    const { data } = await GraphqlService.queryGql<WhatsappSessionStatusModel>({
      method: "whatsappSessionStatus",
      fields: ["connected", "loggedIn", "error"],
    })
    return data as WhatsappSessionStatusModel;
  }

  async getWhatsappSessionQr() {
    const { data } = await GraphqlService.queryGql<WhatsappSessionQrModel>({
      method: "whatsappSessionQr",
      fields: ["code", "success", "error", "qrCode"],
    })
    return data as WhatsappSessionQrModel;
  }

  async getWhatsappSessionLogout() {
    const { data } = await GraphqlService.queryGql<WhatsappSessionLogoutModel>({
      method: "whatsappSessionLogout",
      fields: ["code", "success", "error"],
    })
    return data as WhatsappSessionLogoutModel;
  }
}
