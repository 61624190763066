export default {
  head: {
    title: "דוח רווח והפסד",
    meta: [
      { name: "description", content: "דוח רווח והפסד" }
    ]
  },
  title: "דוח רווח והפסד",
  alertText: "הדוח מסכם את כל ההכנסות וההוצאות בתקופה שהגדרת, ומחשב את הרווח (או ההפסד) של העסק. לפני שימוש בדוח לצרכי מס הכנסה, כדאי להתייעץ עם רו״ח",
  dateFrom: "מחודש",
  dateTo: "עד חודש",
  email: {
    label: 'שליחה לדוא"ל',
    help: "הדוח ישלח למייל שתגדירו כאן (לא חובה)",
  },
  buttons: {
    report: "הפקת דוח"
  }
}
