export default {
  head: {
    title: "תבניות מרשם",
    meta: [
      { name: "description", content: "תיאור תבניות מרשם" }
    ]
  },
  table: {
    columns: {
      id: "ID",
      systemName: "שם מרשם",
      drugName: "שם תרופה",
      submission: "א. הגשה",
      dose: "מינון",
      timesADay: "פעמים ביום",
      numberOfDays: "מספר ימים",
      active: "פעיל"
    },
  },
  add: "הוספת תבנית מרשם",
  searchByDrugName: "חיפוש לפי שם תרופה"
}
