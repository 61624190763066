export default {
  head: {
    title: "בסיסי נתונים",
    meta: [
      { name: "description", content: "בסיסי נתונים" }
    ]
  },
  login: {
    enterPassword: "הזן את הסיסמה"
  },
  table: {
    columns: {
      id: "ID",
      createdAt: "נוצר",
      uniqueName: "שם בסיס נתונים"
    }
  },
  createDatabase: "הוסף בסיס נתונים",
  updateDatabase: "עדכן בסיס נתונים מס' {0}",
  input: {
    name: "בסיס נתונים",
    password: "סיסמת מנהל",
    license: "License",
  },
  exists: "בסיס נתונים כבר קיים.",
  saved: "בסיס נתונים נשמר!",
  restartAlert: "Please restart site service",
}
