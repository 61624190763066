export default {
  head: {
    title: "דוח הכנסות",
    meta: [
      { name: "description", content: "דוח הכנסות" }
    ]
  },
  title: "דוח הכנסות",
  dateFrom: "מתאריך",
  dateTo: "עד תאריך",
  patientLabel: "הקלידו את שם הלקוח או השאירו ריק לכל הלקוחות.",
  incomeTypes: {
    label: "סוגי מסמכים",
    invoice: "חשבונית מס",
    invoiceReceipt: "חשבונית מס / קבלה",
    receipt: "קבלה",
    invoiceRefound: "חשבונית זיכוי",
    receiptRefound: "קבלה זיכוי"
  },
  files: {
    label: "סוג קובץ דוח",
    uniform: "קובץ במבנה אחיד",
    forms: "טופס 2.6, 5.4",
  },
  email: {
    label: "שליחה למייל",
    help: "הדוח ישלח למייל שתגדירו כאן (לא חובה)",
    copyText: "לבדיקת הדוח בסימולטור רשות המיסים",
    copyLink: "לחצו כאו"
  },
  buttons: {
    report: "הפקת דוח"
  },
  validation: {
    selectIncomeType: "עליך לבחור לפחות סוג אחד של הכנסה",
    selectReportFile: "בבקשה לבחור סוג קובץ"
  }
}
