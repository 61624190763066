import GraphqlService from "../GraphqlService";
import { supplierSchemaAllFields, type SupplierSchema } from "@/models/schemas/SupplierSchema";
import type { TabulatorParams } from "@/models/interfaces";
import type { SaveSupplierInput } from "@/models/api/mutations/SupplierModels";
import type { FilterSupplierInput } from "@/models/api/queries/SupplierModels";

export default class SupplierService {
  async getSuppliersAll(fields?: string[]) {
    const { data } = await GraphqlService.queryGql<SupplierSchema[]>({
      method: "suppliersAll",
      fields: fields ?? supplierSchemaAllFields,
      order: [
        { field: "title", value: "ASC" },
        { field: "id", value: "ASC" }
      ]
    });
    return data ?? [];
  }

  async getSuppliers(params: TabulatorParams, input?: FilterSupplierInput) {
    return await GraphqlService.getItems<SupplierSchema>(
      "suppliers", supplierSchemaAllFields, params, input ? { variables: [{ field: "input", value: input, valueType: "FilterSupplierInput" }] } : undefined
    );
  }

  async getSupplier(id: number) {
    return await GraphqlService.getItem<SupplierSchema>("supplier", supplierSchemaAllFields, id);
  }

  async saveSupplier(input: SaveSupplierInput) {
    return await GraphqlService.setItem<SupplierSchema>("saveSupplier", "supplier", supplierSchemaAllFields, input);
  }

  async deleteSupplier(id: number) {
    return await GraphqlService.deleteItem("deleteSupplier", id);
  }

  async supplierCanBeDeleted(id: number) {
    const { data } = await GraphqlService.queryGql<boolean>({
      method: "supplierCanBeDeleted",
      variables: [{ field: "id", value: id, valueType: "ID!" }]
    });
    return data ?? false;
  }
}
