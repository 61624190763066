export default {
  actions: {
    edit: "Edit",
    archive: "Archive",
    unarchive: "Unarchive",
  },
  head: {
    title: "Tasks",
    meta: [
      { name: "description", content: "Tasks description" }
    ]
  },
  table: {
    columns: {
      id: "#",
      createdAt: "Created",
      followUp: "Follow up",
      title: "Task",
      patientName: "Patient",
      assigneeName: "Assignee",
      status: "Status",
    }
  },
  searchPlaceholder: "Search",
  filter: {
    status: "Status",
    all: "All",
    new: "New",
    closed: "Closed",
    created: "Created",
    followUp: "Follow up",
  },
  status: {
    NEW: "New",
    CLOSED: "Closed",
  },
  download: "Download",
  newTask: "New task"
}
