export default {
  head: {
    title: "Expenses report",
    meta: [
      { name: "description", content: "Expenses report description" }
    ]
  },
  title: "Expenses report",
  dateFrom: "From date",
  dateTo: "To date",
  submit: "Generate report",
  email: {
    label: "Send to email",
    help: "The report will be sent to the email you specify here (optional)",
    copyText: "To copy the email addresses from the mailing settings > accounting",
    copyLink: "Click here"
  }
}
