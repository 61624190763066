export default {
  head: {
    title: "500 Error",
    meta: [
      { name: "description", content: "MedForm - 500 Error" }
    ]
  },
  error: "Internal Server Error",
  back: "Back to Dashboard"
}
