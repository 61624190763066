export default {
  actions: {
    edit: "עריכה",
    archive: "העבר לארכיון",
    unarchive: "העבר לפעילים"
  },
  head: {
    title: "עבודות מעבדה",
    meta: [
      { name: "description", content: "תיאור עבודות מעבדה" }
    ]
  },
  table: {
    columns: {
      id: "#",
      createdAt: "נוצרה ב",
      followUp: "תאריך מעקב",
      title: "כותרת",
      patientName: "מטופל",
      laboratoryName: "מעבדה",
      status: "סטטוס"
    }
  },
  searchPlaceholder: "חיפוש",
  filter: {
    status: "סטטוס",
    all: "הכל",
    created: "נוצרה ב",
    followUp: "תאריך מעקב"
  },
  download: "הורדת קובץ",
  newLaboratoryWork: "הוספת עבודת מעבדה",
  labs: "מעבדות"
};
