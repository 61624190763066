import type { DateTimeString } from "@/models/interfaces";
import type { IncomeType, Language, VatType, DiscountType, IncomeStatus } from "@/models/enums";

export interface IncomeSchema {
  id: number;
  createdAt: DateTimeString;
  incomeType: IncomeType;
  incomeStatus: IncomeStatus;

  amountInvoice: number;
  amountInvoiceBeforeVat: number;
  amountReceipt: number;
  amountVat: number;
  amountDiscount: number;
  amountRounding: number;

  imported: boolean;
  externalId: number | null;

  isWaitPayment: boolean;
  isDraft: boolean;
  documentDate: DateTimeString;
  relatedDocumentId: number | null;
  dueDate: DateTimeString;
  language: Language;
  patientId: number;
  emails?: string[];
  description?: string;
  documentFooter?: string;
  emailContent?: string;

  vatPercent: number;
  vatType: VatType;
  rounding: boolean;
  discountType: DiscountType;
  discountValue: number;
  paymentAllow: boolean;
  paymentMaxNumber: number;

  financialNumber: number;
  createdByUserId: number;

  documentYearAndMonth: DateTimeString;
  createdByUserName: string;
  patientPhone: string;
  patientPassport: string;
  patientName?: string;
  payAmount: number;
  paid?: boolean;

  treatmentIds?: number[];
  treatmentPlanIds?: number[];
}

export const incomeSchemaAllFields = [
  "id",
  "createdAt",
  "incomeType",
  "incomeStatus",

  "amountInvoice",
  "amountInvoiceBeforeVat",
  "amountReceipt",
  "amountVat",
  "amountDiscount",
  "amountRounding",

  "imported",
  "externalId",

  "isWaitPayment",
  "isDraft",
  "documentDate",
  "relatedDocumentId",
  "dueDate",
  "language",
  "patientId",
  "emails",
  "description",
  "documentFooter",
  "emailContent",

  "vatPercent",
  "vatType",
  "rounding",
  "discountType",
  "discountValue",
  "paymentAllow",
  "paymentMaxNumber",

  "financialNumber",
  "createdByUserId",

  "documentYearAndMonth",
  "createdByUserName",
  "patientPhone",
  "patientPassport",
  "patientName",
  "payAmount",
  "paid",

  // "treatmentIds",
  // "treatmentPlanIds"
];
