import GraphqlService from "../GraphqlService";
import { serviceSchemaAllFields as serviceFields, serviceCategorySchemaAllFields as categoryFields } from "@/models/schemas";
import type { ServiceSchema, ServiceCategorySchema } from "@/models/schemas";
import type { SaveServiceInput, SaveServiceCategoryInput } from "@/models/api/mutations/ServiceModels";
import type { TabulatorParams } from "@/models/interfaces";

export default class ServiceService {
  //#region Service
  async getServices(params: TabulatorParams, input?: { search?: string }) {
    return await GraphqlService.getItems<ServiceSchema>("services", serviceFields, params, {
      variables: [{ field: "search", value: input?.search, valueType: "String" }]
    });
  }

  async getServicesAll(active?: boolean, fields?: string[]) {
    const { data } = await GraphqlService.queryGql<ServiceSchema[]>({
      method: "servicesAll",
      fields: fields ?? ["id", "name", "code", "active", "appointmentMinutes", "materialPrice"],
      filter: typeof active === "boolean" ? [{ field: "active", type: "=", value: active }] : [],
      order: [{ field: "name", value: "ASC" }]
    })
    return data ?? [];
  }

  async getService(id: number) {
    return await GraphqlService.getItem<ServiceSchema>("service", serviceFields, id);
  }

  async checkServiceName(id: number, name?: string, code?: string) {
    const { data } = await GraphqlService.queryGql<number>({
      method: "checkServiceName",
      variables: [
        { field: "id", value: id, valueType: "ID!" },
        { field: "name", value: name, valueType: "String" },
        { field: "code", value: code, valueType: "String" }
      ]
    });
    return data ?? 0;
  }

  async saveService(input: SaveServiceInput) {
    return await GraphqlService.setItem<ServiceSchema>("saveService", "service", serviceFields, input);
  }

  async deleteService(id: number) {
    return await GraphqlService.deleteItem("deleteService", id);
  }
  //#endregion

  //#region ServiceCategory
  async getServiceCategories(params: TabulatorParams) {
    return await GraphqlService.getItems<ServiceCategorySchema>("serviceCategories", categoryFields, params);
  }

  async getServiceCategoriesAll() {
    const { data } = await GraphqlService.queryGql<ServiceCategorySchema[]>({
      method: "serviceCategoriesAll",
      fields: categoryFields,
      order: [{ field: "displayOrder", value: "ASC" }]
    })
    return data;
  }

  async getServiceCategory(id: number) {
    return await GraphqlService.getItem<ServiceCategorySchema>("serviceCategory", categoryFields, id);
  }

  async saveServiceCategory(input: SaveServiceCategoryInput) {
    return await GraphqlService.setItem<ServiceCategorySchema>("saveServiceCategory", "serviceCategory", categoryFields, input);
  }

  async deleteServiceCategory(id: number) {
    return await GraphqlService.deleteItem("deleteServiceCategory", id);
  }

  async getServiceForRecallAndReminderAsync(appointmentId: number, newAppointmentId?: number) {
    const { data } = await GraphqlService.queryGql<ServiceSchema>({
      method: "serviceForRecallAndReminder",
      fields: serviceFields,
      variables: [
        { field: "appointmentId", value: appointmentId, valueType: "Int!" },
        { field: "newAppointmentId", value: newAppointmentId, valueType: "Int" },
      ],
    });
    return data;
  }
  //#endregion
}
