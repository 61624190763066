import type { DocumentTemplateSchema } from "@/models/schemas";
import type { TabulatorParams } from "@/models/interfaces";

import GraphqlService from "../GraphqlService";
import type { SaveDocumentTemplateInput } from "@/models/api/mutations/DocumentTemplateModels";

const fields = `
  id
  title
  text
  active
`;

export default class DocumentTemplateService {
    async getDocumentTemplates(params: TabulatorParams) {
        return await GraphqlService.getItems<DocumentTemplateSchema>("documentTemplates", fields, params);
    }

    async deleteDocumentTemplate(id: number) {
        return await GraphqlService.deleteItem("deleteDocumentTemplate", id);
    }

    async getDocumentTemplate(id: number) {
        return await GraphqlService.getItem<DocumentTemplateSchema>("documentTemplate", fields, id);
    }

    async saveDocumentTemplate(input: SaveDocumentTemplateInput) {
        return await GraphqlService.setItem<DocumentTemplateSchema>("saveDocumentTemplate", "documentTemplate", fields, input);
    }
}
