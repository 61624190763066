<template>
  <div class="p-formkit">
    <!--image control-->
    <div class="signature-pad-view align-content-center align-items-center" role="presentation" @click="visible = true">
      <img v-if="url" :src="url" />
      <span v-else-if="attrs.placeholder">{{ attrs.placeholder }}</span>
    </div>
    <!--image actions-->
    <div class="signature-pad-actions mt-2">
      <a href="#" @click="visible = true">{{ t("default.change") }}</a>
      <span> | </span>
      <a href="#" @click="clear()">{{ t("default.delete") }}</a>
    </div>
    <!--signature-pad popup-->
    <b-modal v-model="visible" centered no-fade :title="context.label">
      <template #default>
        <div class="text-center">
          <canvas class="border" ref="canvas"></canvas>
        </div>
      </template>
      <template #footer>
        <b-button variant="primary" @click="selectFile">{{ t("components.primeSignaturePad.selectFile") }}</b-button>
        <b-button variant="primary" @click="visible = false">{{ t("default.save") }}</b-button>
        <b-button variant="light" @click="clear">{{ t("default.clear") }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<!-- https://github.com/szimek/signature_pad -->

<script setup lang="ts">
  import SignaturePad, { type PointGroup } from "signature_pad";
  import { ref, computed, onMounted, onBeforeUnmount, nextTick, watch } from "vue";
  import { useI18n } from "vue-i18n";
  import { FileInput } from "@/utils";

  const props = defineProps<{
    context: any;
  }>();

  const context = props.context;
  const attrs = computed(() => context?.attrs);

  const { t } = useI18n();

  const visible = ref(false);
  const canvas = ref<HTMLCanvasElement>();
  const signaturePad = ref<SignaturePad>();
  const url = ref<string>("");
  const data = ref<PointGroup[]>([]);

  function clear() {
    url.value = "";
    data.value = [];
    signaturePad.value?.clear();
    nextTick(() => onInput());
  }

  async function selectFile() {
    const files = await FileInput.openFileDialog(".jpg,.jpeg,.png,.gif");
    if (files.length) {
      if (files[0].size > 1 * 1024 * 1024) {
        // limit size 1 mb
        clear();
      } else {
        url.value = (await FileInput.readAsDataURLAsync(files[0])) as string;
        data.value = [];
        signaturePad.value?.fromDataURL(url.value);
        nextTick(() => onInput());
      }
    }
  }

  function onInput() {
    const json = url.value && url.value.length > 6 ? JSON.stringify({ url: url.value, data: data.value }) : null;
    context?.node.input(json);
  }

  function setValue(json: string) {
    const value = JSON.parse(json);
    url.value = value?.url;
    data.value = value?.data;
    if (data.value?.length > 0) {
      signaturePad.value?.fromData(data.value);
    } else if (url.value?.length > 6) {
      signaturePad.value?.fromDataURL(url.value);
    }
  }

  watch(
    () => context._value,
    (v1, v2) => {
      if (v1 && !v2) {
        setValue(v1);
      }
    }
  );

  onMounted(() => {
    if (canvas.value) {
      // https://szimek.github.io/signature_pad/
      // https://szimek.github.io/signature_pad/js/app.js
      signaturePad.value = new SignaturePad(canvas.value, {
        // https://github.com/szimek/signature_pad#options
        penColor: "mediumblue"
      });
      if (data.value.length) signaturePad.value.fromData(data.value);
      // https://github.com/szimek/signature_pad?tab=readme-ov-file#events
      signaturePad.value.addEventListener("endStroke", () => {
        url.value = signaturePad.value?.toDataURL() ?? "";
        data.value = signaturePad.value?.toData() ?? [];
        nextTick(() => onInput());
      });
    }
    if (context._value) {
      setValue(context._value);
    }
  });

  onBeforeUnmount(() => {
    signaturePad.value?.off();
  });
</script>

<style scoped lang="scss">
  .signature-pad-view {
    width: 240px;
    height: 240px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    margin-inline-start: 0;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }
  }

  .signature-pad-actions {
    width: 240px;
    margin-inline-start: 0;
    position: relative;
  }

  button.btn-outline-light {
    position: absolute;
    top: 1px;
    right: 1px;
    border: none;
    padding-bottom: 5px;
  }
</style>
