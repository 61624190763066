export default {
  head: {
    title: "Profit and loss report",
    meta: [
      { name: "description", content: "Profit and loss report description" }
    ]
  },
  title: "Profit and loss report",
  alertText: "The report summarizes all income and expenses in the period you defined, and calculates the profit (or loss) of the business. Before using the report for income tax purposes, you should consult with a CPA",
  dateFrom: "From date",
  dateTo: "To date",
  email: {
    label: "Send to email",
    help: "The report will be sent to the email you specify here (optional)",
  },
  buttons: {
    report: "Generate report"
  }
}
