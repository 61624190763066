import { useServices } from "@/services";
import axios, { type AxiosRequestConfig, type AxiosResponse } from "axios";
import ConfigService from "@/services/ConfigService";
import { DateInput } from "@/utils";
import { i18n } from "@/locales/i18n";

export type ApiMethodRequestOptions<TData = any> = AxiosRequestConfig<TData> & {
  useAuth?: boolean;
};

export class Http {
  public static async request<TResult = any, TData = any>(url: string, options: AxiosRequestConfig<TData>) {
    try {
      return await axios.request<TResult, AxiosResponse<TResult, TData>, TData>({ url, ...options });
    } catch (ex) {
      console.error(ex); // eslint-disable-line no-console
      throw ex;
    }
  }

  public static async get<TResult = any, TData = any>(url: string, options: AxiosRequestConfig<TData> = {}) {
    return await this.request<TResult, TData>(url, { method: "get", ...options });
  }

  public static async post<TResult = any, TData = any>(url: string, options: AxiosRequestConfig<TData> = {}) {
    return await this.request<TResult, TData>(url, { method: "post", ...options });
  }

  public static async delete<TResult = any, TData = any>(url: string, options: AxiosRequestConfig<TData> = {}) {
    return await this.request<TResult, TData>(url, { method: "delete", ...options });
  }
}

export class HttpService {
  private static transformOptions<D>(options?: ApiMethodRequestOptions<D>): ApiMethodRequestOptions<D> {
    options ??= {};
    options.headers ??= {};
    options.headers["X-TimezoneOffsetHours"] = DateInput.getTimezoneOffsetHours().toString();
    options.headers["X-Locale"] = i18n.global.locale.value;

    if (options.useAuth) {
      options.headers["Authorization"] = `Bearer ${useServices().apiAuth.token?.access}`;
      delete options.useAuth;
    }

    return options;
  }

  public static async get<TResult = any, TData = any>(endpoint: string, options?: ApiMethodRequestOptions<TData>) {
    const response = await Http.get<TResult, TData>(ConfigService.api(endpoint), this.transformOptions(options));
    if (response?.status === 200) {
      return response.data;
    }
  }

  public static postRequest<TResult = any, TData = any>(endpoint: string, options?: ApiMethodRequestOptions<TData>) {
    return Http.post<TResult, TData>(ConfigService.api(endpoint), this.transformOptions(options));
  }
  public static async post<TResult = any, TData = any>(endpoint: string, options?: ApiMethodRequestOptions<TData>) {
    const response = await this.postRequest<TResult, TData>(endpoint, options);
    if (response?.status === 200) {
      return response.data;
    }
  }

  public static async delete<TResult = any, TData = any>(endpoint: string, options?: ApiMethodRequestOptions<TData>) {
    const response = await Http.delete<TResult, TData>(ConfigService.api(endpoint), this.transformOptions(options));
    if (response?.status === 200) {
      return response.data;
    }
  }
}
