import type { DatabaseInfoSchema, UserSchema } from "@/models/schemas";
import gql from "graphql-tag";
import type { TabulatorParams } from "@/models/interfaces";
import GraphqlService from "../GraphqlService";
import { useStores } from "@/stores";
import type { CreateDatabaseInput, UpdateDatabaseInput } from "@/models/api/mutations/DatabaseModels";

const fields = `
  id
  createdAt
  updatedAt
  deletedAt

  uniqueName
  license
`;

export default class DatabaseInfoService {
  async getDatabaseNames() {
    const data = await GraphqlService.query<{ databaseNames: string[] }>({
      query: gql`
        query databaseNames {
          databaseNames
        }
      `
    });
    return data.databaseNames;
  }

  async getMyDatabaseInfo() {
    const { data } = await GraphqlService.queryGql<DatabaseInfoSchema>({ method: "myDatabaseInfo", fields: fields });
    return data;
  }

  async checkMasterPassword(password: string) {
    const data = await GraphqlService.query<{ checkMasterPassword: boolean }>({
      query: gql`
        query checkMasterPassword($password: String!) {
          checkMasterPassword(password: $password)
        }
      `,
      variables: {
        password: password
      }
    });
    return data.checkMasterPassword;
  }

  private getAuthContext() {
    return {
      headers: {
        "X-MASTERPASSWORD": useStores().database.password,
      }
    }
  }

  async getDatabaseInfos(params: TabulatorParams) {
    return await GraphqlService.getItems<DatabaseInfoSchema>("databaseInfos", fields, params, { context: this.getAuthContext() });
  }

  async getDatabaseInfo(id: number) {
    return await GraphqlService.getItem<DatabaseInfoSchema>("databaseInfo", fields, id, { context: this.getAuthContext() });
  }

  async createDatabase(input: CreateDatabaseInput) {
    return await GraphqlService.setItem<DatabaseInfoSchema>("createDatabase", "databaseInfo", fields, input, { context: this.getAuthContext() });
  }

  async updateDatabase(input: UpdateDatabaseInput) {
    return await GraphqlService.setItem<DatabaseInfoSchema>("updateDatabase", "databaseInfo", fields, input, { context: this.getAuthContext() });
  }

  async deleteDatabase(id: number, name: string) {
    const data = await GraphqlService.deleteItem("deleteDatabase", id, {
      variables: [{ field: "name", value: name, valueType: "String!" }],
      context: this.getAuthContext()
    });
    return data ?? false;
  }
}
