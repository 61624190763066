export default {
  head: {
    title: "Forecast",
    meta: [
      { name: "description", content: "Forecast description" }
    ]
  },
  title: "Forecast",
  when: "When",
  formatMonthDay: "{0} of the month",
  form: {
    editExpense: "Edit expense",
    addExpense: "Add expense",
    editIncome: "Edit income",
    addIncome: "Add income",
    frequency: "Type",
    amount: "Amount",
    oneTimeDate: "Date",
    weekDay: "Week day",
    monthDay: "Day of the month",
    evenOrOddBiWeekly: "Odd or even (details)",
    evenOrOddBiMonthly: "Odd or even month",
    description: "Description"
  },
  buttons: {
    edit: "Edit",
    newIncome: "New income",
    newExpense: "New expense"
  },
  filters: {
    balance: "Enter balance now",
    display: {
      label: "Display",
      options: {
        _1_Month: "Month",
        _3_Month: "3 month",
        _6_Month: "6 month",
      }
    }
  },
  incomes: {
    title: "Incomes",
    table: {
      columns: {
        frequency: "Frequency",
        description: "Description",
        amount: "Amount"
      }
    }
  },
  expenses: {
    title: "Expenses",
    table: {
      columns: {
        frequency: "Frequency",
        description: "Description",
        amount: "Amount"
      }
    }
  },
  balances: {
    title: "Balances",
    table: {
      columns: {
        date: "Date",
        description: "Description",
        amount: "Amount",
        balance: "Balance"
      }
    }
  }
}
