<template>
  <div>
    <VueDatePicker
      ref="datePicker"
      :input-class-name="props.classInput ?? 'form-control'"
      :class="`dp_mode_${props.mode}`"
      :model-value="props.modelValue"
      @update:modelValue="onUpdate"
      :auto-apply="props.autoApply"
      :config="{ closeOnAutoApply: false }"
      :clearable="props.clearable"
      :min-date="props.minDate"
      :max-date="props.maxDate"
      :placeholder="props.placeholder"
      :readonly="props.readonly"
      :disabled="props.disabled"
      :teleport="true"
      :hide-input-icon="true"
      :time-picker-inline="timePickerInline"
      :enable-time-picker="enableTimePicker"
      :minutes-grid-increment="minutesGridIncrement"
      :min-time="props.minTime"
      :max-time="props.maxTime"
      :range="range"
      :multi-calendars="multiCalendars"
      :month-picker="monthPicker"
      :time-picker="timePicker"
      :week-picker="weekPicker"
      :week-start="weekStart ?? defaultWeekStart"
      :text-input="textInput"
      :format="format"
      :format-locale="formatLocale"
      @open="emit('show', $event)"
      @closed="emit('hide', $event)">
      <template #action-buttons>
        <div class="btn btn-sm btn-light border px-2" @click="datePicker?.closeMenu()">{{ t("default.cancel") }}</div>
        <div class="btn btn-sm btn-primary ms-1 px-2" @click="datePicker?.selectDate()">{{ t("default.select") }}</div>
      </template>
      <template #right-sidebar="props" v-if="range">
        <div class="btn btn-sm btn-light" @click="last7Days()">{{ t("components.primeDatepicker.last7Days") }}</div>
        <div class="btn btn-sm btn-light" @click="last30Days()">{{ t("components.primeDatepicker.last30Days") }}</div>
        <div class="btn btn-sm btn-light" @click="last12Months()">{{ t("components.primeDatepicker.last12Months") }}</div>
        <div class="btn btn-sm btn-light" @click="thisYear()">{{ t("components.primeDatepicker.thisYear") }}</div>
        <div class="btn btn-sm btn-light" @click="lastYear()">{{ t("components.primeDatepicker.lastYear") }}</div>
      </template>
    </VueDatePicker>
    <span v-if="props.debug">{{ JSON.stringify(props.modelValue) }}</span>
  </div>
</template>

<!--
  https://v8-5.vue3datepicker.com/props/positioning/#teleport
  https://v8-5.vue3datepicker.com/slots/content/#action-buttons
  https://v8-5.vue3datepicker.com/slots/content/#right-sidebar
  https://v8-5.vue3datepicker.com/methods-and-events/methods
-->

<script setup lang="ts">
  import "@vuepic/vue-datepicker/dist/main.css";
  import VueDatePicker, { type ModelValue, type TimeModel } from "@vuepic/vue-datepicker";
  import moment from "@/plugins/VueMomentPlugin";
  import appConfig from "@/app.config";
  import { ref, computed, onMounted } from "vue";
  import { he } from "date-fns/locale";
  import { useI18n } from "vue-i18n";
  import { useServices } from "@/services";
  import { DateInput } from "@/utils";

  type WeekStart = "0" | "1" | "2" | "3" | "4" | "5" | "6" | 0 | 1 | 2 | 3 | 4 | 5 | 6;

  const services = useServices();
  const { t, locale } = useI18n();

  const props = withDefaults(
    defineProps<{
      mode?: "date" | "datetime" | "range" | "month" | "time" | "week";
      modelValue: ModelValue;
      autoApply?: boolean;
      clearable?: boolean;
      classInput?: string;
      placeholder?: string;
      readonly?: boolean;
      disabled?: boolean;
      debug?: boolean;
      minDate?: Date;
      maxDate?: Date;
      minTime?: TimeModel;
      maxTime?: TimeModel;
      weekStart?: WeekStart;
    }>(),
    {
      mode: "datetime",
      clearable: true
    }
  );

  const emit = defineEmits(["update:modelValue", "input", "change", "show", "hide"]);

  function onUpdate(event: any) {
    emit("update:modelValue", event);
    emit("input", event);
    emit("change", event);
  }

  // https://v8-5.vue3datepicker.com/props/localization/#format-locale
  const formatLocale = computed(() => (locale.value === "he" ? he : undefined));

  const datePicker = ref<InstanceType<typeof VueDatePicker>>();
  // const date = ref<null | Date | Date[]>(null);

  // https://v8-5.vue3datepicker.com/props/time-picker-configuration
  const timePickerInline = ref(false);
  const enableTimePicker = computed(() => ["datetime", "time"].includes(props.mode));
  const minutesGridIncrement = ref(5);

  // https://v8-5.vue3datepicker.com/props/modes https://vue3datepicker.com/props/modes-configuration/#multi-calendars-configuration
  const range = computed(() => props.mode === "range");
  const multiCalendars = computed(() => false /*(props.mode === "range" ? { solo: true, static: false } : false)*/);
  const monthPicker = computed(() => props.mode === "month");
  const timePicker = computed(() => props.mode === "time");
  const weekPicker = computed(() => props.mode === "week");
  const textInput = ref(false);

  // https://v8-5.vue3datepicker.com/props/formatting
  function format(date: Date | Date[]) {
    if (Array.isArray(date)) {
      const dates = date;
      switch (props.mode) {
        case "range":
        case "week":
          return DateInput.formatDate(dates[0]) + (dates[1] ? ` - ${DateInput.formatDate(dates[1])}` : "");
        default:
          return `${dates[0].toISOString()} - ${dates[1]?.toISOString()}`;
      }
    } else {
      switch (props.mode) {
        case "date":
          return DateInput.formatDate(date);
        case "datetime":
          return DateInput.formatDateTime(date);
        case "month":
          return DateInput.formatMonthWithYear(date);
        case "time":
          return DateInput.formatTime(date);
        case "week":
          return `${moment(date).startOf("week").format(appConfig.format.date)} - ${moment(date).endOf("week").format(appConfig.format.date)}`;
        default:
          return date.toISOString();
      }
    }
  }

  function last7Days() {
    const currentDate = moment();
    const startDate = currentDate.clone().subtract(6, "days").startOf("day");
    const endDate = currentDate.endOf("day");
    datePicker.value?.updateInternalModelValue([startDate.toDate(), endDate.toDate()]);
  }

  function last30Days() {
    const currentDate = moment();
    const startDate = currentDate.clone().subtract(29, "days").startOf("day");
    const endDate = currentDate.endOf("day");
    datePicker.value?.updateInternalModelValue([startDate.toDate(), endDate.toDate()]);
  }

  function last12Months() {
    const currentDate = moment();
    const startDate = currentDate.clone().subtract(12, "months").startOf("month");
    const endDate = currentDate.clone().subtract(1, "months").endOf("month");
    datePicker.value?.updateInternalModelValue([startDate.toDate(), endDate.toDate()]);
  }

  function thisYear() {
    const currentDate = moment();
    const startDate = currentDate.clone().startOf("year");
    const endDate = currentDate.endOf("year");
    datePicker.value?.updateInternalModelValue([startDate.toDate(), endDate.toDate()]);
  }

  function lastYear() {
    const currentDate = moment();
    const startDate = currentDate.clone().subtract(1, "year").startOf("year");
    const endDate = currentDate.clone().subtract(1, "year").endOf("year");
    datePicker.value?.updateInternalModelValue([startDate.toDate(), endDate.toDate()]);
  }

  const defaultWeekStart = ref<WeekStart>(1);

  onMounted(async () => {
    const error = -1;
    const monday = 1;
    const validation = [0, 1, 2, 3, 4, 5, 6];
    const result = (await services.setting.getSetting<number>("CallendarWeekStartDay")) ?? error;
    defaultWeekStart.value = <WeekStart>(validation.includes(result) ? result : monday);
  });

  defineExpose({
    datePicker
  });
</script>

<style lang="scss">
  .dp_mode_range {
    min-width: 220px;
  }

  .dp__input_wrap {
    .dp__clear_icon {
      padding: 0;
      margin: 0 5px;
      fill: var(--text-dark-text);
    }
  }

  .dp__sidebar_right {
    min-width: 150px;

    .btn {
      width: 100%;
      margin-bottom: 4px;
    }
  }

  [dir="rtl"] {
    .dp__outer_menu_wrap {
      direction: ltr;

      .dp__menu {
        direction: rtl;

        .dp__arrow_top {
          direction: ltr;
        }

        .dp__time_input {
          direction: ltr;

          .dp__overlay {
            direction: rtl;
          }
        }
      }
    }
  }
</style>
