import type { DateTimeString } from "@/models/interfaces";

export interface LeadNoteSchema {
  id: number;
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
  deletedAt?: DateTimeString;

  leadId: number;
  text: string;
}

export const leadNoteSchemaAllFields = `
  id
  createdAt
  updatedAt
  deletedAt

  leadId
  text
`;