export default {
  head: {
    title: "תבנית מרשם",
    meta: [
      { name: "description", content: "תבנית מרשם" }
    ]
  },

  createTitle: "צור תבנית מרשם",
  updateTitle: "עדכון תבנית מרשם מספר {0}",

  systemName: "שם מרשם",
  drugName: "שם תרופה",
  submission: "א. הגשה",
  dose: "מינון",
  timesADay: "פעמים ביום",
  numberOfDays: "מספר ימים",
  note: "הערה לתרופה",
  active: "פעיל",
  instruction: "הדרכה לשימוש",

  nameUsed: "שם מרשם בשימוש"
}
