import GraphqlService from "../GraphqlService";
import type { NotificationSchema } from "@/models/schemas";
import type { TabulatorParams } from "@/models/interfaces";

export default class NotificationService {
  async getCurrentNotifications() {
    const params: TabulatorParams = {
      size: 100,
      page: 1,
      filter: [],
      sort: []
    };
    return await GraphqlService.getItems<NotificationSchema>("notifications", "id createdAt titleJson contentJson type link showPopup", params);
  }

  async closeNotification(id: number) {
    return await GraphqlService.deleteItem("closeNotification", id);
  }

  async hideNotificationPopup(id: number) {
    return await GraphqlService.deleteItem("hideNotificationPopup", id);
  }
}
