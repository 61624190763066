export default {
  head: {
    title: "Patients",
    meta: [
      { name: "description", content: "Patients description" }
    ]
  },
  table: {
    columns: {
      id: "#",
      fileNumber: "Case number",
      passportNumber: "Passport",
      firstName: "Full name",
      phone1: "Phone",
      email1: "Email",
      // isMainFamilyPatient: "Family",
      isMainFamilyPatient: "",
      defaultDoctorName: "Attending Physician",
      lastAppointmentTime: "Last Appointment",
      nextAppointmentTime: "Next Appointment",
      balance: "Balance",
      lastVisitDate: "Last visit date"
    }
  },
  actions: {
    send: "Send message",
    sendEmail: "Send email",
    sendAppointment: "Send appointment link",
    edit: "Edit",
    medicalEdit: "Medical edit",
    archive: "Move to archive",
    unarchive: "Move to active",
    download: "Download",
    mobileReception: "Mobile reception",
    newPatient: "New patient"
  },
  searchPlaceholder: "Search",
  filter: {
    all: "All",
    active: "Active",
    inactive: "Archives",
    lastAppointment: "Last appointment",
    nextAppointment: "Next appointment",
    doctor: "Doctor",
    haveDebt: "Have debt",
  },
  family: "Family",
  reallyDeactivate: "Do you really want to deactivate {0}?",
  deactivate: "Deactivate"
}
