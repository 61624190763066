export default {
  title: "Laboratory works",
  table: {
    columns: {
      id: "ID",
      createdAt: "Created",
      followUp: "Follow up",
      title: "Title",
      patientName: "Patient",
      laboratoryName: "Laboratory",
      status: "Status",
    }
  }
}
