import type { App, Plugin } from "vue";
import moment from "moment";
import momentWithLocales from "moment-with-locales-es6";
import momentDurationFormatSetup from "moment-duration-format";

// https://www.npmjs.com/package/moment-duration-format
momentDurationFormatSetup(momentWithLocales);

export const VueMomentPlugin: Plugin = {
  install(app: App) {
    // https://stackoverflow.com/a/65184375
    app.config.globalProperties.$moment = momentWithLocales;
    (<any>app.config.globalProperties.$moment).relativeTimeThreshold('m', 60);
  }
}

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $moment(inp?: moment.MomentInput, strict?: boolean): moment.Moment,
    $moment(inp?: moment.MomentInput, format?: moment.MomentFormatSpecification, strict?: boolean): moment.Moment,
    $moment(inp?: moment.MomentInput, format?: moment.MomentFormatSpecification, language?: string, strict?: boolean): moment.Moment,
  }
}

export default momentWithLocales as typeof moment; // https://stackoverflow.com/a/42816077
