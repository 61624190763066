export default {
  head: {
    title: "Deposit cash",
    meta: [
      { name: "description", content: "Deposit cash description" }
    ]
  },
  example: "example",
  title: "Deposit cash history",
  buttons: {
    print: "Print",
    add: "Add deposit",
    download: "Download"
  },
  form: {
    title: "Deposit cash",
    quantity010: "0.1",
    quantity050: "0.5",
    quantity1: "1",
    quantity2: "2",
    quantity5: "5",
    quantity10: "10",
    quantity20: "20",
    quantity50: "50",
    quantity100: "100",
    quantity200: "200",
    total: "Total:",
    submit: "Continue"
  },
  popup: {
    delete: "Entry with amount",
    create: "Are you sure? Once a deposit is created, the cash position cannot be edited.",
    ok: "Ok"
  },
  table: {
    columns: {
      createdAt: "Date",
      total: "Amount"
    }
  }
}
