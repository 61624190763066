import type { DateTimeString } from "@/models/interfaces";
import type { ForecastType, Frequency, DayOfWeek, EvenOrOdd } from "@/models/enums";

export interface ForecastSchema {
  id: number;
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
  deletedAt: DateTimeString | null;
  type: ForecastType;
  frequency: Frequency;
  oneTimeDate: DateTimeString | null;
  weekDay: DayOfWeek | null;
  monthDay: number | null;
  evenOrOdd: EvenOrOdd | null;
  description: string;
  amount: number;
}

export const forecastSchemaAllFields = [
  "id",
  "createdAt",
  "updatedAt",
  "deletedAt",
  "type",
  "frequency",
  "oneTimeDate",
  "weekDay",
  "monthDay",
  "evenOrOdd",
  "description",
  "amount"
];