export default {
  head: {
    title: "הפקדת מזומן",
    meta: [
      { name: "description", content: "הפקדת מזומן" }
    ]
  },
  example: "example",
  title: "היסטוריית הפקדות מזומן",
  buttons: {
    print: "הדפסה",
    add: "הוספת הפקדה",
    download: "הורדת Excel"
  },
  form: {
    title: "הפקדת מזומן",
    quantity010: "10 אגורות",
    quantity050: "50 אגורות",
    quantity1: "שקל אחד",
    quantity2: "2 שקלים",
    quantity5: "5 שקלים",
    quantity10: "10 שקלים",
    quantity20: "20 שקלים",
    quantity50: "50 שקלים",
    quantity100: "100 שקלים",
    quantity200: "200 שקלים",
    total: "סה\"כ:",
    submit: "המשך"
  },
  popup: {
    delete: "האם אתה בטוח?",
    create: 'האם להמשיך? לאחר הפקת הפקדה, לא יהיה ניתן לבצע שינוי הפקה.',
    ok: "הפקה"
  },
  table: {
    columns: {
      createdAt: "תאריך",
      total: "סכום"
    }
  }
}
