import GraphqlService from "../GraphqlService";
import {
  treatmentPlanSchemaAllFields, type TreatmentPlanSchema,
  treatmentSchemaAllFields, type TreatmentSchema
} from "@/models/schemas";

export default class TreatmentService {
  //#region Treatments
  async getSelectedTreatments(ids: number[], fields?: string[]) {
    const { data } = await GraphqlService.queryGql<TreatmentSchema[]>({
      method: "selectedTreatments",
      fields: fields ?? treatmentSchemaAllFields,
      variables: [{ field: "ids", value: ids, valueType: "[Int!]" }]
    });
    return data ?? [];
  }
  //#endregion

  //#endregion Treatment plans
  async getSelectedTreatmentPlans(ids: number[], fields?: string[]) {
    const { data } = await GraphqlService.queryGql<TreatmentPlanSchema[]>({
      method: "selectedTreatmentPlans",
      fields: fields ?? treatmentPlanSchemaAllFields,
      variables: [{ field: "ids", value: ids, valueType: "[Int!]" }]
    });
    return data ?? [];
  }
  //#endregion
}
