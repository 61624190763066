export default {
  head: {
    title: "MedForm - שחכת סיסמה",
    meta: [
      { name: "description", content: "MedForm שחכת סיסמה" }
    ]
  },
  title: "שחכת סיסמה",
  alert: "רשום דוא\"ל שלך ולינק לאיפוס סיסמה ישלח אליך",
  input: {
    email: "דוא\"ל"
  },
  reset: "שלח",
  userNotFound: "המשתמש לא נמצא.",
  instructionsSent: "הוראות לאיפוס סיסמה נשלחו לאימייל שלך!",
  remember: "תזכור את זה?",
  signIn: "להתחבר"
}
