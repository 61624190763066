export default {
  title: "הפניות",
  table: {
    columns: {
      id: "ID",
      createdAt: "תאריך יצירה",
      doctorName: "רופא",
      title: "כותרת",
    }
  },
  edit: {
    titleNew: "מסמך",
    title: "מסמך מספר {0}",
    doctor: "רופא",
    template: "תבנית",
    subject: "כותרת",
    text: "תוכן"
  }
}
