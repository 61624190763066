import GraphqlService from "../GraphqlService";
import gql from "graphql-tag";
import type { MessageOverviewSchema } from "@/models/schemas/MessageOverviewSchema";
import { useApolloClient } from "@vue/apollo-composable";
import type { FilterMessageInput } from "@/models/api/queries/MessageModels";
import type { MessageSchema } from "@/models/schemas/MessageSchema";
import type { SendMessageInput } from "@/models/api/mutations/MessageModels";
import type { Result, TabulatorParams } from "@/models/interfaces";

const messageOverviewFields = `
  id
  createdAt
  provider
  patientId
  defaultDoctorId
  phone
  photo
  fullname
  text
  fileName
  filePath
  isSent
  sendError
  isReceived
  receiveError
  isRead
  unreadCount
  messagesIsMuted
`

const messageFields = `
  id
  createdAt
  updatedAt
  deletedAt
  provider
  patientId
  sender
  phone
  direction
  text
  fileName
  filePath
  isSent
  sendError
  isReceived
  receiveError
  isRead
`;

export default class MessageService {
  async getUnreadMessagesCount(userIds: number[]) {
    const { data } = await GraphqlService.queryGql<number>({
      method: "unreadMessagesCount",
      variables: [{ field: "userIds", valueType: "[Int!]", value: userIds }]
    });
    return data ?? 0;
  }

  async getTotalUnreadMessagesCount() {
    const { data } = await GraphqlService.queryGql<number>({
      method: "totalUnreadMessagesCount"
    });
    return data ?? 0;
  }

  async getMessageOverview(input?: FilterMessageInput) {
    const params: TabulatorParams = {
      size: 100,
      page: 1,
      filter: [],
      sort: []
    };
    return await GraphqlService.getItems<MessageOverviewSchema>("messageOverview", messageOverviewFields, params, {
      variables: [{ field: "input", value: input, valueType: "FilterMessageInput" }]
    });
  }

  async subscribeMessageOverview(observer: {
    onResult: (data: any) => void,
    onError?: (error: any) => void
  }) {
    const method = "messageOverviewSchemaStream";

    const { client } = useApolloClient();
    const observable = await client.subscribe({
      query: gql`
        subscription ${method} {
          ${method} {
            ${messageOverviewFields}
          }
        }
      `
    });

    const subscription = observable.subscribe({
      next(value) {
        observer.onResult(value.data[method]);
      },
      error(errorValue) {
        if (observer.onError) {
          observer.onError(errorValue);
        }
      },
    });
    return subscription;
  }

  async subscribeUnreadMessages(observer: {
    onResult: (data: any) => void,
    onError?: (error: any) => void
  }) {
    const method = "unreadMessagesSchemaStream";

    const { client } = useApolloClient();
    const observable = await client.subscribe({
      query: gql`
        subscription ${method} {
          ${method} {
            unreadMessages
          }
        }
      `
    });

    const subscription = observable.subscribe({
      next(value) {
        observer.onResult(value.data[method]);
      },
      error(errorValue) {
        if (observer.onError) {
          observer.onError(errorValue);
        }
      },
    });
    return subscription;
  }

  async getMessages(input: FilterMessageInput, take: number = 50) {
    const params: TabulatorParams = {
      size: take,
      page: 1,
      filter: [],
      sort: []
    };
    return await GraphqlService.getItems<MessageSchema>("messages", messageFields, params, {
      variables: [{ field: "input", value: input, valueType: "FilterMessageInput" }]
    });
  }

  async subscribeMessage(observer: {
    onResult: (data: any) => void,
    onError?: (error: any) => void
  }) {
    const method = "messageSchemaStream";

    const { client } = useApolloClient();
    const observable = await client.subscribe({
      query: gql`
        subscription ${method} {
          ${method} {
            ${messageFields}
          }
        }
      `
    });

    const subscription = observable.subscribe({
      next(value) {
        observer.onResult(value.data[method]);
      },
      error(errorValue) {
        if (observer.onError) {
          observer.onError(errorValue);
        }
      },
    });
    return subscription;
  }

  async saveFileInPatientFolder(messageId: number, patientId: number) {
    const { client } = useApolloClient();
    const { data } = await client.mutate<{ saveFileInPatientFolder: boolean }>({
      mutation: gql`
        mutation saveFileInPatientFolder($messageId: Int!, $patientId: Int!) {
          saveFileInPatientFolder(messageId: $messageId, patientId: $patientId)
        }
      `,
      variables: {
        messageId: messageId,
        patientId: patientId
      }
    });

    return data;
  }

  async deleteMessage(messageId: number) {
    const { client } = useApolloClient();
    const { data } = await client.mutate<{ deleteMessage: boolean }>({
      mutation: gql`
        mutation deleteMessage($messageId: Int!) {
          deleteMessage(messageId: $messageId)
        }
      `,
      variables: {
        messageId: messageId
      }
    });

    return data;
  }

  async markMessagesAsRead(phone: string) {
    const { client } = useApolloClient();
    const { data } = await client.mutate<{ markMessagesAsRead: boolean }>({
      mutation: gql`
        mutation markMessagesAsRead($phone: String) {
          markMessagesAsRead(phone: $phone)
        }
      `,
      variables: {
        phone: phone
      }
    });

    return data;
  }

  async clearChat(phone: string) {
    const { client } = useApolloClient();
    const { data } = await client.mutate<{ clearChat: boolean }>({
      mutation: gql`
        mutation clearChat($phone: String) {
          clearChat(phone: $phone)
        }
      `,
      variables: {
        phone: phone
      }
    });

    return data;
  }

  async muteChat(phone: string, mute: boolean) {
    const { client } = useApolloClient();
    const { data } = await client.mutate<{ muteChat: boolean }>({
      mutation: gql`
        mutation muteChat($phone: String, $mute: Boolean!) {
          muteChat(phone: $phone, mute: $mute)
        }
      `,
      variables: {
        phone: phone,
        mute: mute
      }
    });

    return data;
  }

  async sendMessage(input: SendMessageInput, fields?: string[]) {
    return await GraphqlService.setItem<MessageSchema>("sendMessage", "message", fields ?? messageFields, input);
  }

  //#region SMS
  async getSmsBalance() {
    const { data } = await GraphqlService.queryGql<Result<number>>({
      method: "smsBalance",
      fields: "success message exception data",
    });
    return data;
  }
  //#endregion
}
