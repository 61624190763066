export default {
  head: {
    title: "ערוצים",
    meta: [{ name: "description", content: "ערוצי פרסום" }]
  },
  table: {
    columns: {
      id: "#",
      name: "שם ערוץ",
      active: "פעיל",
    }
  },
  actions: {
    download: "הורדת Excel",
    newChannel: "הוספת ערוץ",
    edit: "עריכה"
  }
}
