export default {
  search: {
    text: "חיפוש לקוח ע״י טלפון, שם משפחה..."
  },
  supportMessage: {
    add: {
      title: "טופס תמיכה",
      text: "אנא מלאו פרטים וצוות תמיכה יצור איתכם קשר בהקדם",
      submit: "שלחו הודעה",
      content: "הודעה",
      contentPlaceholder: "הודעה לצוות התמיכה",
      success: "הודעך נשלחה בהצלחה",
      choosetext: "דברו איתנו",
      whatsapp: "בווטסאפ",
      emailForm: 'בדוא"ל',
      chat: "צ'ט עם נציג תמיכה",
      email: "לאיזה מייל לחזור אליכם?"
    }
  },
  taskNotification: {
    laboratoryWorkTitle: "עבודת מעבדה - {0}"
  },
  notifications: {
    NEW_WEB_APPOINTMENTS_EXISTING_PATIENT: {
      title: "מטופל קבע תור חדש",
      content: "{FullName} קבע תור חדש ל {NewDateAndWeekDay} בשעה {Time} - ד''ר {Doctor}",
    },
    NEW_WEB_APPOINTMENTS_NEW: {
      title: "תור חדש נקבע למטופל חדש",
      content: "{FullName} {Phone} {Email} פרטי התור {Doctor} {ServiceName} {NewDateAndWeekDay}",
    },
    PATIENT_CANCELED_APPOINTMENT: {
      title: "מטופל ביטל תור",
      content: "{FullName} - ביטל את התור ב-{AppointmentDate}, טלפון {Phone}, רופא מטפל - {Doctor}"
    },
    PATIENT_CHANGED_APPOINTMENT: {
      title: "מטופל שינה מועד תור",
      content: "{PatientFullName} שינה את מועד התור מיום {OldDateAndWeekDay} בשעה {OldHour} ליום {NewDateAndWeekDay} בשעה {NewHour} - ד''ר {Doctor}",
    },
    PATIENT_APPROVED_APPOINTMENT: {
      title: "מטופל אישר הגעה",
      content: "{FullName} אישר הגעה לתור ביום: {AppointmentDateAndWeekDay} בשעה {Time}, פרטי התור: {ServiceName}."
    },
    PATIENT_NOT_APPROVED_APPOINTMENT: {
      title: "מטופל טרם אישר הגעה",
      content: "{PatientFullName} טרם אישר הגעה לתור היום בשעה {Time} - ד''ר {DoctorFullName}",
    },
    PATIENT_FILLED_OUT_MEDICAL_FORM: {
      title: "מטופל חתם על מסמך",
      content: "{FullName} חתם על המסמך {DocumentTitle}"
    },
    MOBILE_REGISTRATION: {
      title: "מטופל חדש נרשם",
      content: "{FullName} נרשם כמטופל",
    },
    NEW_LEAD: {
      title: "מטופל פוטנציאלי חדש",
      content: "{LeadFullName} נרשם כמטופל פוטנציאלי חדש.{Separator}{ServiceName}",
    }
  },
  quickActions: {
    title: "פעולות מהירות",
    create: {
      income: "מסמך פיננסי חדש",
      patient: "הוספת מטופל חדש",
      reception: "קליטה מהנייד",
      task: "משימה חדשה"
    }
  },
  dropdown: {
    tasks: {
      title: "משימות",
      viewAll: "צפיה בהכל",
      completed: "הושלם"
    },
    henry: {
      text: "סיגל כהן",
      list: {
        profile: "עדכון פרופיל",
        logout: "התנתק"
      }
    }
  },
  version: "גרסה",
  notificationsTitle: "התראות"
}
