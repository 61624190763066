export default {
  head: {
    title: "משתמשים",
    meta: [
      { name: "description", content: "משתמשים" }
    ]
  },
  table: {
    columns: {
      id: "ID",
      fullName: "שם מלא",
      email: "דוא\"ל",
      phoneNumber: "טלפון",
      roles: "תפקיד",
      active: "פעיל",
    },
  },
  title: "ניהול משתמשים",
  create: "יצירת משתמש חדש",
  reallyRemove: "אתה באמת רוצה מחק משתמש {0}?",
  saved: "משתמש שמור!"
}
