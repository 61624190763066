import type { DateTimeString } from "@/models/interfaces";

export interface PatientFileSchema {
  createdAt: DateTimeString;
  updatedAt: DateTimeString;

  isDirectory: boolean;

  name: string;
  relativePath: string;
  directoryName: string;
  size: number;
}

export const patientFileSchemaAllFields = [
  "createdAt",
  "updatedAt",
  "isDirectory",
  "name",
  "relativePath",
  "directoryName",
  "size",
];
