import GraphqlService from "../GraphqlService";

export interface FillVariablesOptions {
  appointmentId?: number;
  patientId?: number;
  doctorId?: number;
  prescriptionTemplateId?: number;
  serviceId?: number;
}

export default class VariablesService {
  async fillVariables(text: string, options: FillVariablesOptions) {
    const { data } = await GraphqlService.queryGql<string>({
      method: "fillVariables",
      variables: [
        { field: "text", value: text, valueType: "String!" },
        { field: "appointmentId", value: options.appointmentId, valueType: "Int" },
        { field: "patientId", value: options.patientId, valueType: "Int" },
        { field: "doctorId", value: options.doctorId, valueType: "Int" },
        { field: "prescriptionTemplateId", value: options.prescriptionTemplateId, valueType: "Int" },
        { field: "serviceId", value: options.serviceId, valueType: "Int" },
      ]
    });
    return data ?? text;
  }
}
