export default {
  title: "מרשמים",
  table: {
    columns: {
      id: "ID",
      createdAt: "נוצר ב",
      doctorName: "רופא",
      systemName: "שם מרשם",
      drugName: "שם תרופה",
      submission: "א. הגשה",
      dose: "מינון",
      timesADay: "פעמים ביום",
      numberOfDays: "מספר ימים",
      note: "הערה לתרופה",
    }
  },
  print: {
    title: "הדפסת מרשמים",
    input: {
      prescriptions: "הדפס מרשמים",
      instructions: "הדפס הוראות שימוש",
    },
    print: "הדפסה"
  },
  edit: {
    title: "מרשם",
    doctor: "רופא",
    template: "תבנית מרשם",
    drugName: "שם תרופה",
    submission: "א. הגשה",
    dose: "מינון",
    timesADay: "פעמים ביום",
    numberOfDays: "מספר ימים",
    note: "הערה לתרופה",
    add: "הוסיפו תרופה",
    saveAndPrint: "לשמור ולהדפיס"
  }
}
